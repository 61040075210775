import bindAllMethods from '../../../utils/bindAllMethods';
import * as T from './types';

export default class Layout {
  private layoutList: T.FallbackConstructorOptionsType['layoutList'];
  defaultLayout: T.AssetPropertyType;

  constructor(options: T.FallbackConstructorOptionsType) {
    bindAllMethods(this);
    this.layoutList = options?.layoutList;

    if (options?.defaultLayoutKey) {
      this.defaultLayout = this.getLayoutByKey(options?.defaultLayoutKey);
    }
  }

  getLayoutByKey(key: string) {
    return this.layoutList?.find?.(
      (fallbackDescription) => fallbackDescription?.key === key
    );
  }
}
