import { TokenType } from '../../interface/types';
import { getCookie } from '../../utils/cookies';
import ISupportSessionService from './ISupportSessionService';

type SupportSessionServiceParametersType = {
  initialEnablementStatus?: boolean;
};

export const SUPPORT_SESSION_ENABLED = 'enabled';

export default class SupportSessionService implements ISupportSessionService {
  private _initialEnablementStatus: boolean;

  constructor({
    initialEnablementStatus
  }: SupportSessionServiceParametersType) {
    this._initialEnablementStatus = initialEnablementStatus;
  }

  public isSupportSession(): boolean {
    return (
      this._initialEnablementStatus &&
      this.isSupportSessionStartedBySessionAPI()
    );
  }

  public isSupportSessionStartedBySessionAPIAndInitialStatusNotEnabled(): boolean {
    return (
      !this._initialEnablementStatus &&
      this.isSupportSessionStartedBySessionAPI()
    );
  }

  public isSupportSessionStartedBySessionAPIOrInitialStatusEnabled(): boolean {
    return (
      this._initialEnablementStatus ||
      this.isSupportSessionStartedBySessionAPI()
    );
  }

  public isSupportSessionStartedBySessionAPI(): boolean {
    return getCookie(TokenType.supportSession, false) === 'true';
  }
}
