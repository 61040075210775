import TenantHandlerService from '../../../services/tenantHandler/TenantHandlerService';
import { ISessionService } from '../../../services/session';
import SessionInterfaceV1, {
  SessionInterfaceV1Type
} from './SessionInterfaceV1';

type CreateSessionInterfaceV1SingletonPropsType = {
  tenantHandlerService: TenantHandlerService;
  sessionService: ISessionService;
};

let sessionInterfaceV1: SessionInterfaceV1;

function _createSessionInterfaceV1(
  options: CreateSessionInterfaceV1SingletonPropsType
) {
  const { tenantHandlerService: tenantHandler, sessionService } = options;

  return new SessionInterfaceV1({
    sessionService,
    tenantHandler
  });
}

export function getSessionInterfaceV1(): SessionInterfaceV1Type {
  return sessionInterfaceV1.getInterface();
}

export default async function initializeSessionInterfaceV1(
  options: CreateSessionInterfaceV1SingletonPropsType
): Promise<SessionInterfaceV1Type> {
  if (sessionInterfaceV1) return sessionInterfaceV1.getInterface();
  sessionInterfaceV1 = _createSessionInterfaceV1(options);

  return sessionInterfaceV1.getInterface();
}
