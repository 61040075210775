import {
  PutKeyValueOption,
  ReservedValueStoreKeys,
  WhenJWebReady
} from '@jarvis/jweb-core';

export { ServiceRoutingErrorType } from '@jarvis/jweb-core';
import { JWebType } from './types';

const valueStorePromise = getJWeb().then(async (jweb) => {
  if (!jweb.isNative) {
    await import('@jarvis/web-value-store');
  }
});

export function JWebErrorHandler<T>(object: Record<string, any>) {
  if (object?.errorType) {
    throw new Error(`JWeb: ${object?.errorType} - ${object?.reason}`);
  }
  return object as T;
}

export async function getJWeb(): Promise<JWebType> {
  if (!(window as any).JWeb) {
    await WhenJWebReady;
  }
  return (window as any).JWeb;
}

export async function isNative() {
  return (await getJWeb())?.isNative;
}

export async function writeDataToValueStore(
  entriesValue: Array<PutKeyValueOption>
) {
  if (!(await isNative())) {
    const valueStore = await _getValueStore();
    valueStore.put({
      entries: entriesValue
    });
  }
}

async function _getValueStore() {
  await valueStorePromise;
  const JWeb = await getJWeb();
  return JWeb.Plugins.ValueStore;
}

async function _writeStratusUserIdToValueStore(entryValue: string) {
  const valueStore = await _getValueStore();
  valueStore.put({
    entries: [{ key: ReservedValueStoreKeys.stratusUserId, value: entryValue }]
  });
}

async function _writeTenantIdToValueStore(entryValue: string) {
  const valueStore = await _getValueStore();
  valueStore.put({
    entries: [{ key: ReservedValueStoreKeys.tenantId, value: entryValue }]
  });
}

async function _writeWpidToValueStore(entryValue: string) {
  const valueStore = await _getValueStore();
  valueStore.put({
    entries: [{ key: ReservedValueStoreKeys.wpIdUserId, value: entryValue }]
  });
}

async function _writeHpIdUserIdValueStore(entryValue: string) {
  const valueStore = await _getValueStore();
  valueStore.put({
    entries: [{ key: ReservedValueStoreKeys.hpIdUserId, value: entryValue }]
  });
}

export async function writeStratusCookieDataToValueStore(
  stratusId: any,
  tenantId: any,
  wpIdUserId: any,
  hpIdUserId: any
) {
  if (!(await isNative())) {
    await _writeStratusUserIdToValueStore(stratusId);
    await _writeTenantIdToValueStore(tenantId);
    await _writeWpidToValueStore(wpIdUserId);
    await _writeHpIdUserIdValueStore(hpIdUserId);
  }
}
