import 'intl-pluralrules';
import './infra/initializer';
import commons from './commons';

export const interfaces = commons.interfaces;
export const services = commons.services;
export const utils = commons.utils;
export const behavior = commons.behaviors;

export default commons;
