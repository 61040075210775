import { IShellDAO } from '../../dao/IShellDAO';
import { IdTokenStorageType } from './types';

export class IdTokenRepository {
  private shellDAO: IShellDAO;
  private prefixKey = 'jshellIdToken';

  constructor({ shellDAO }) {
    this.shellDAO = shellDAO;
  }

  save(idTokenStorage: IdTokenStorageType): void {
    this.shellDAO.save(this.prefixKey, idTokenStorage);
  }

  findOne(): IdTokenStorageType {
    return this.shellDAO.findOne(this.prefixKey);
  }

  delete(): boolean {
    return this.shellDAO.delete(this.prefixKey);
  }

  clear(): void {
    this.delete();
  }
}
