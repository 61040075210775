import { Stack } from '../../../types/stratus';
import { getAuthzBaseUrl } from '../../stackUtils';
import IAuthzClient from './IAuthzClient';

export default class AuthzClient implements IAuthzClient {
  configureLogoutUrl(
    stack: Stack,
    stratusId: string,
    postLogoutRedirectUri: string,
    state?: string
  ): string {
    const authzBaseUrl = getAuthzBaseUrl(stack);
    let stateQueryParameter = '';
    if (state) {
      stateQueryParameter = `&state=${state}`;
    }
    return `${authzBaseUrl}/openid/v1/logout?id_token_hint=${stratusId}&post_logout_redirect_uri=${postLogoutRedirectUri}${stateQueryParameter}`;
  }
}
