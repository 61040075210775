import { Stack } from '../../../types/stratus';
import bindAllMethods from '../../../utils/bindAllMethods';

export type AppInterfaceV1Type = {
  getClientId(): string;
  getAppName(): string;
  getStack(): Stack;
};

export type AppInterfaceV1Param = {
  clientId: string;
  appName: string;
  stack: Stack;
};

class AppInterfaceV1 {
  private _clientId: string;
  private _appName: string;
  private _stack: Stack;

  constructor({ clientId, appName, stack }: AppInterfaceV1Param) {
    this._clientId = clientId;
    this._appName = appName;
    this._stack = stack;
    bindAllMethods(this);
  }

  public getInterface(): AppInterfaceV1Type {
    return {
      getClientId: () => {
        return this._clientId;
      },
      getAppName: () => {
        return this._appName;
      },
      getStack: () => {
        return this._stack;
      }
    };
  }
}

export default AppInterfaceV1;
