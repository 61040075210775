import { IShellDAO } from '../../dao/IShellDAO';

type LastTenantIdsStorageType = {
  level: number;
  id: string;
}[];

export class LastTenantIdsRepository {
  private shellDAO: IShellDAO;
  private prefixKey = 'jshellLastTenant';

  constructor({ shellDAO }) {
    this.shellDAO = shellDAO;
  }

  save(lastTenantIds: LastTenantIdsStorageType): void {
    this.shellDAO.save(this.prefixKey, lastTenantIds);
  }

  find(): LastTenantIdsStorageType {
    return this.shellDAO.findOne(this.prefixKey) || undefined;
  }

  clear(): boolean {
    return this.shellDAO.delete(this.prefixKey);
  }
}
