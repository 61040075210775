import { JarvisAuthProvider } from './jarvis-auth-provider'

export class JarvisClientCredentialsAuthProvider implements JarvisAuthProvider {
    accessToken: string = ''
    clientId: string
    clientSecret: string
    endpoint: string
    scopes: string[]

    constructor(clientId: string, clientSecret:string, tokenEndpoint: string, scopes: string[]) {
        this.clientId = clientId
        this.clientSecret = clientSecret
        this.endpoint = tokenEndpoint
        this.scopes = scopes
    }

    async getAccessToken(forceRefresh?: boolean) {
        // this.accessToken = this.accessToken || await // Make client_credentials grant type call to endpoint to get access token for scopes here
        return this.accessToken
    }
}
