import createScript from './createScript';
import { InterfacesInitializerStatePropsType } from '../interface/types';

const oneTrustScriptProdUrl =
  'https://www.hp.com/cma/ng/lib/exceptions/privacy-banner.js';
const oneTrustScriptNonProdUrl =
  'https://www.hp.com/cma/ng/lib/exceptions/privacy-banner-test.js';

export default function injectOneTrust(
  initializerState: InterfacesInitializerStatePropsType
) {
  const scriptId = 'one-trust-script';
  const oneTrustScript = document.getElementById(scriptId);

  if (!oneTrustScript) {
    const { stack } = initializerState?.manifest?.portal || {};
    const nonProdStacks = ['dev', 'pie', 'stage'];

    const scriptUrl = nonProdStacks.includes(stack)
      ? oneTrustScriptNonProdUrl
      : oneTrustScriptProdUrl;

    const script = createScript(scriptId, scriptUrl);

    if (document.head.prepend) {
      document.head.prepend(script);
    } else {
      document.head.appendChild(script);
    }
  }
}
