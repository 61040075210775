import { ISessionService } from '../../../services/session';
import { IProviderClient } from '../../../clients/shell/provider';
import {
  ILoginService,
  LoginServiceWeb,
  LoginServiceNative
} from '../../../services/session/loginService';

type CreateLoginServiceSingletonPropsType = {
  providerClient: IProviderClient;
  isNative: boolean;
  sessionService: ISessionService;
};

let loginServiceSingleton: ILoginService;

async function _createLoginService(
  options: CreateLoginServiceSingletonPropsType
): Promise<ILoginService> {
  const { isNative, providerClient } = options;

  if (isNative) {
    return new LoginServiceNative({ providerClient });
  } else {
    return new LoginServiceWeb({ providerClient });
  }
}

export function getLoginServiceSingleton(): ILoginService {
  return loginServiceSingleton;
}

export default async function initializeLoginServiceSingleton(
  options: CreateLoginServiceSingletonPropsType
): Promise<ILoginService> {
  if (loginServiceSingleton) return loginServiceSingleton;

  loginServiceSingleton = await _createLoginService(options);

  return loginServiceSingleton;
}
