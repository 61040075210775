import { createBrowserHistory, History } from 'history';
import { InterfacesInitializerStatePropsType } from '../../../interface/types';
import urlPathHandler from '../../../utils/urlPathHandler';
import eventService from '../../../services/eventService';
import eventNames from '../../../config/eventNames';
import { OptionalType } from '../../../types/typeHandlers';

let navigation: History;

/**
 * The Navigation Interface is an instance of the history package object that
 *  abstracts the differences in various environments and provides a minimal API
 *  to manage the history stack, navigate, and persist state between sessions.
 *  @returns {Promise<History>} an instance of the history object
 */
export default async (
  initializerState?: OptionalType<InterfacesInitializerStatePropsType>
): Promise<History> => {
  const { portal, services } = initializerState?.manifest || {};

  const { relativePath, getServicePath } = urlPathHandler({
    manifestBasePath: portal?.basePath,
    localization: services?.localization
  });

  navigation = createBrowserHistory({ basename: relativePath });

  const servicePath = getServicePath() || '';

  navigation.replace(servicePath);

  return navigation;
};

eventService.subscribe(
  eventNames?.shellCallInterfaceNavigationPush,
  (event) => {
    const path: string = event?.eventData?.path;

    if (navigation && path?.startsWith?.('/')) {
      navigation?.push?.(path);
    }
  }
);
