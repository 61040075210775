export enum SessionEvents {
  EXCHANGE_TENANT_TOKEN = 'exchangeTenantToken'
}

export default class SessionObserver {
  private static _listeners: Record<string, Function[]> = {};

  public static subscribe = (event: SessionEvents, cb: Function): void => {
    if (!this._listeners?.[`${event}`]) this._listeners[`${event}`] = [];
    !this._listeners?.[`${event}`]?.includes(cb) &&
      this._listeners?.[`${event}`]?.push(cb);
  };

  public static unsubscribe = (event: SessionEvents, cb: Function): void => {
    this._listeners[`${event}`] = this._listeners?.[`${event}`]?.filter(
      (c) => c != cb
    );
  };

  public static notify = async (
    event: SessionEvents,
    authToken: string
  ): Promise<void> => {
    const notifyEvent = this._listeners?.[`${event}`];
    if (!notifyEvent) return;
    for (const callback of notifyEvent) {
      await callback(authToken);
    }
  };
}
