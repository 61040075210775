import { OneCloudOrgDataType } from '../../clients/oneCloud/types';
import { TenantNodeType, TenantVisualizationType } from './strategy/types';

export default class TenantUtils {
  public static _convertStratusTenantNodeToTenantVisualizationType(
    tenantNode: TenantNodeType
  ): TenantVisualizationType {
    return {
      id: tenantNode.nodeId,
      name: tenantNode.accountName || tenantNode.description,
      type: tenantNode.type
    };
  }

  public static _convertOnecloudOrgToTenantVisualizationType(
    organization: OneCloudOrgDataType
  ): TenantVisualizationType {
    return {
      id: organization.id,
      name: organization.name,
      type: organization.type
    };
  }
}
