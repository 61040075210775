import matchPath from './matchPath';

export type GetMicrofrontendFromPathPropsType = {
  list: {
    path?: string | string[];
    exact?: boolean;
  }[];
  path: string;
};

export default function getMicrofrontendFromPath(
  options: GetMicrofrontendFromPathPropsType
) {
  if (!options?.path) return;

  return options?.list?.find?.(({ path, exact }) => {
    return matchPath(path, { pathToCompare: options?.path, exact });
  });
}
