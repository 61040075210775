import FallbackOperation from './operations/FallbackOperation';
import LoginOperation from './operations/LoginOperation';
import OnboardingAgentOperation from './operations/OnboardingAgentOperation';
import PathRouteOperation from './operations/PathRouteOperation';
import MicrofrontendRouter from '.';
import { EventsType } from '../events/types';
import Store from '../store';
import { NavigationType } from '../orgSelector/types';
import { ManifestType } from '../../../types/manifest';
import Fallback from '../Fallback';
import Criterion from '../Criterion';
import Layout from '../Layout';
import { UserSessionType } from '../userSession/types';
import webServiceRouting from '../../../services/webServiceRouting';
import { Stack } from '../../../types/stratus';
import localization from '../localization';
import tenantHandler from '../TenantHandler';
import authProvider from '../auth';
import { PromiseReturnType } from '../../../types/typeHandlers';
import { ISessionService } from '../../../services/session';

let microfrontendRouter: MicrofrontendRouter;

export default function createMicrofrontendRouterSingleton(options: {
  interfaces: {
    events: EventsType;
    store: Store;
    navigation: NavigationType;
    fallback: Fallback;
    layout: Layout;
    criterion: Criterion<any>;
    userSession: UserSessionType;
    authProvider: PromiseReturnType<typeof authProvider>;
    localization: PromiseReturnType<typeof localization>;
    sessionService: ISessionService;
    tenantHandler: PromiseReturnType<typeof tenantHandler>;
  };
  manifest: ManifestType;
  stack: Stack;
}): MicrofrontendRouter {
  if (microfrontendRouter) return microfrontendRouter;

  const eventInterface = options.interfaces.events;
  const storeInterface = options.interfaces.store;
  const navigationInterface = options.interfaces.navigation;
  const fallbackInterface = options.interfaces.fallback;
  const layoutInterface = options.interfaces.layout;
  const criterionInterface = options.interfaces.criterion;
  const userSessionInterface = options.interfaces.userSession;
  const authProviderInterface = options.interfaces.authProvider;
  const localizationInterface = options.interfaces.localization;
  const tenantHandlerInterface = options.interfaces.tenantHandler;
  const sessionService = options.interfaces.sessionService;

  const eventName = 'shell-global-microfrontend-router';

  const fallbackEvent = fallbackInterface.eventName;
  const criterionEvent = criterionInterface.eventName;

  // TODO: create this event inside onboarding code
  const onboardingEvent = eventName + '-onboarding';
  // TODO: create this event inside navigation code
  const urlEvent = eventName + '-url';

  storeInterface.addObserver(
    storeInterface.generateId(),
    (state, previousStage) => {
      if (
        JSON.stringify(state?.onboarding) !==
        JSON.stringify(previousStage?.onboarding)
      ) {
        eventInterface.triggerEvent(onboardingEvent, undefined);
      }
    }
  );

  navigationInterface.listen(() => {
    eventInterface.triggerEvent(urlEvent, undefined);
  });

  microfrontendRouter = new MicrofrontendRouter({
    event: {
      eventInterface,
      eventList: [
        fallbackEvent,
        criterionEvent,
        onboardingEvent,
        urlEvent,
        'onboardingAgentFailedToLaunch',
        webServiceRouting.Events.ServiceInstanceClosed,
        webServiceRouting.Events.ServiceInstanceLaunching
      ],
      eventName
    },
    criterionInterface,
    fallbackInterface,
    layoutInterface,
    navigationInterface,
    operations: [
      new FallbackOperation({
        fallbackInterface
      }),
      new LoginOperation({
        navigationInterface
      }),
      new OnboardingAgentOperation({
        resumeOnTriggers:
          !!options?.manifest?.services?.onboarding?.resumeOnTriggers,
        authProviderInterface,
        navigationInterface,
        stack: options.stack,
        storeInterface,
        onboardingTriggerList:
          options?.manifest?.services?.onboarding?.triggers,
        userSessionInterface,
        onboardingDirectorClientId:
          options?.manifest?.services?.onboarding?.clientId,
        localizationInterface: localizationInterface,
        fallbackInterface,
        sessionService,
        tenantHandlerInterface: tenantHandlerInterface
      }),
      new PathRouteOperation({
        sessionService,
        navigationInterface,
        userSessionInterface
      })
    ]
  });

  return microfrontendRouter;
}
