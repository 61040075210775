import { TenantHandlerService } from '../services/tenantHandler';
import AppContext from '../services/appContext/AppContext';
import TenantObserver, {
  TenantEvents
} from '../services/tenantHandler/TenantObserver';
import { HandledTenant } from '../services/tenantHandler/types';

export default class InitializeContextServices {
  private _appContext: AppContext;
  private _tenantHandler: TenantHandlerService;

  constructor({ appContext, tenantHandler }) {
    this._appContext = appContext;
    this._tenantHandler = tenantHandler;
  }

  public init = async (): Promise<void> => {
    TenantObserver.subscribe(
      TenantEvents.SET_TENANT_HANDLER_KEY,
      this._initializeContextServices
    );

    await this._tenantHandler?.setTenantHandlerKey();
  };

  public _initializeContextServices = async (
    tenants: HandledTenant[]
  ): Promise<void> => {
    await this._appContext?.initializeContextServices();
  };
}
