// TODO: This session should be removed from the application ASAP

import { ISessionService } from '../services/session';
import { initDeprecatedSessionSvc } from '../clients/shell/session';
import { InterfacesType } from '../interface';

export type InitializeDeprecatedServicesType = {
  sessionService: ISessionService;
  orgSelector: InterfacesType['v1']['orgSelector'];
};

export default class InitializeDeprecatedServices {
  public static async init({
    sessionService,
    orgSelector
  }: InitializeDeprecatedServicesType): Promise<void> {
    await initDeprecatedSessionSvc({ sessionService });
    if (sessionService?.isLoggedIn()) await orgSelector.updateDomainList();
  }
}
