import OnecloudOrganizationTenantHandler from './OnecloudOrganizationTenantHandler';
import CustomerTenantHandler from './CustomerTenantHandler';
import OrganizationTenantHandler from './OrganizationTenantHandler';
import TenantStrategy from './TenantStrategy';
import { TenantHandlerStrategyType } from './types';

export enum TenantStrategyEnum {
  stratusOrganization = 'stratusOrganization',
  stratusCustomer = 'stratusCustomer',
  onecloudOrganization = 'onecloudOrganization'
}

const getTenantStrategy = (
  strategy: TenantStrategyEnum,
  attributes: Omit<TenantHandlerStrategyType, 'parentAuthProvider'>
): TenantStrategy => {
  let tenantStrategy: TenantStrategy;

  if (strategy == TenantStrategyEnum.stratusOrganization) {
    const tenantAuthProvider = attributes?.authProvider;
    const parentAuthProvider =
      attributes?.authProvider?.createOrglessAuthProvider();

    tenantStrategy = new OrganizationTenantHandler({
      ...attributes,
      authProvider: tenantAuthProvider,
      parentAuthProvider
    });
  } else if (strategy === TenantStrategyEnum.stratusCustomer) {
    const tenantAuthProvider = attributes?.authProvider;
    const parentAuthProvider =
      attributes?.authProvider?.createOrgedAuthProvider();

    tenantStrategy = new CustomerTenantHandler({
      ...attributes,
      authProvider: tenantAuthProvider,
      parentAuthProvider
    });
  } else if (strategy == TenantStrategyEnum.onecloudOrganization) {
    const tenantAuthProvider = attributes?.authProvider;

    tenantStrategy = new OnecloudOrganizationTenantHandler({
      ...attributes,
      authProvider: tenantAuthProvider
    });
  } else {
    throw new Error("Tenant strategy doesn't exists.");
  }
  return tenantStrategy;
};

export default getTenantStrategy;
