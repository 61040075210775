import { NavigationRoutesType } from '../../../../../types/manifest';
import MicrofrontendRouterOperationStateBuilder from '../../MicrofrontendRouterOperationStateBuilder';
import getMicrofrontendFromPath from '../../../../../utils/getMicrofrontendFromPath';
import getMicrofrontendsFlatMap from '../../../../../utils/getMicrofrontendsFlatMap';
// import matchPath from '../../utils/matchPath';
import routesService from '../../../../../services/RoutesService';
import * as T from './types';

export default class PathRouteOperation
  implements T.IMicrofrontendRouterOperation
{
  private navigationInterface: T.PathRouteOperationDependenciesType['navigationInterface'];
  private userSessionInterface: T.PathRouteOperationDependenciesType['userSessionInterface'];
  private sessionService: T.PathRouteOperationDependenciesType['sessionService'];
  private routesFlatMap: NavigationRoutesType[];

  constructor(dependencies: T.PathRouteOperationDependenciesType) {
    this.navigationInterface = dependencies.navigationInterface;
    this.userSessionInterface = dependencies.userSessionInterface;
    this.sessionService = dependencies.sessionService;

    this.routesFlatMap =
      getMicrofrontendsFlatMap({
        list: routesService.getRoutes()
      }) || [];
  }

  private getRouteByCurrentPath() {
    return getMicrofrontendFromPath({
      list: this.routesFlatMap,
      path: this?.navigationInterface.location.pathname
    }) as NavigationRoutesType;
  }

  private async isLoginRequired(route: NavigationRoutesType) {
    if (route?.assetReference && !route?.public) {
      return !this.sessionService?.isLoggedIn?.();
    }
    return false;
  }

  async process(
    stateBuilder: MicrofrontendRouterOperationStateBuilder
  ): Promise<void> {
    const route = this.getRouteByCurrentPath();

    if (route?.assetReference) {
      const isLoginRequired = await this.isLoginRequired(route);
      if (isLoginRequired) {
        const loginPath = this.userSessionInterface.getLoginPath();

        stateBuilder.setRedirectTo(loginPath);
        stateBuilder.setEndProcessChain(true);
      } else {
        stateBuilder.setContent({
          enable: !!route?.assetReference,
          assetReference: route?.assetReference,
          criterionKey: route?.criterionKey,
          properties: route?.properties,
          key: route?.key,
          path: route?.path,
          // TODO: remove this properties when possible
          isPathRoute: true,
          label: route?.label,
          public: !!route?.public
        });

        if (
          typeof route?.layoutKey === 'string' ||
          route?.layoutKey === false
        ) {
          stateBuilder.setLayoutByKey(route?.layoutKey);
        } else if (route?.public || route?.hidden) {
          stateBuilder.setLayoutByKey(false);
        } else {
          stateBuilder.setLayout({
            enable: true,
            useDefaultRouteLayout: true
          });
        }
      }
    }

    const redirectToDefaultFallback = !stateBuilder.getState()?.content?.enable;

    if (redirectToDefaultFallback) {
      // TODO: investigate if we are going to send it to the generic fallback, get fallback from routes or a global fallback redirect
      stateBuilder.setRedirectTo('/');
    }
  }
}
