import { JarvisAuthProvider, JarvisWebHttpClient } from '@jarvis/web-http';
import { Stack } from '../../../../types/stratus';
import * as T from './types';

export default async function getNode(options: {
  stack: Stack;
  authProvider: JarvisAuthProvider;
  tenantId: string;
}) {
  const { authProvider, stack, tenantId } = options || {};
  const urlEnv = (() => {
    switch (stack) {
      case Stack.prod:
        return '';
      case Stack.stage:
        return 'stage-';
      default:
        return 'pie-';
    }
  })();

  const baseUrl = `https://${urlEnv}us1.api.ws-hp.com/tenancy-registry/v1/node/${tenantId}`;
  const httpClient = new JarvisWebHttpClient({
    defaultAuthProvider: authProvider,
    defaultBaseURL: baseUrl
  });

  const tenants = await httpClient
    .get({})
    .then((r) => r.data as T.TenantNodeType);

  return tenants;
}
