export type OnboardingDirectorContextType = {
  experience: string;
  entry: string;
  bizModelHint: string;
  entryUrl: string;
};

export type OnboardingDirectorAppType = {
  clientId: string;
  appVersion?: string;
  osType: string;
  osVersion: string;
  countryRegionIso?: string;
  locale?: string;
  container: string;
};

export type OnboardingDirectorDeviceType = {
  modelName?: string;
  deviceName?: string;
  identity: {
    type: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resource: Record<string, any>;
  };
  shadow: {
    bizModel: string;
  };
};

export type OnboardingDirectorSessionResponseType = {
  sessionContext: {
    sessionId: string;
    onboardingContext: OnboardingDirectorContextType;
    app: OnboardingDirectorAppType;
    device?: OnboardingDirectorDeviceType;
    xCorrelationId?: string;
    createdAt?: string;
    updatedAt?: string;
  };
  nextService: {
    serviceId?: string;
    resultUrl?: string;
    exitUrl?: string;
    resultIndex?: number;
  };
};

export enum SortByEnum {
  createdAtAsc = 'createdAtAsc',
  createdAtDesc = 'createdAtDesc',
  updatedAtAsc = 'updatedAtAsc',
  updatedAtDesc = 'updatedAtDesc'
}

export type GetRequestOptionsType = {
  data: {
    onboardingContext: OnboardingDirectorContextType;
    app: OnboardingDirectorAppType;
    limit?: number;
    sortBy?: SortByEnum;
  };
};

export type PostRequestOptionsType = {
  data: {
    onboardingContext: OnboardingDirectorContextType;
    app: OnboardingDirectorAppType;
    device?: OnboardingDirectorDeviceType;
  };
};

export type PutRequestOptionsType = {
  baseURL: string;
  data: {
    result: 'success' | 'failed' | 'cancelled';
    output?: Record<string, any>;
    xCorrelationId: string;
  };
};
