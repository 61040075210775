type SpanPropertiesType = {
  attributes: { 'workflow.name': string; [key: string]: any };
};

export type SpanType = {
  end: () => void;
};

let tracer: {
  startSpan: (name: string, properties: SpanPropertiesType) => SpanType;
};

const tracerName = 'Onboarding-Agent';

function startSpan(
  name: string,
  attributes: SpanPropertiesType['attributes']
): SpanType {
  if (!tracer) {
    const splunkRum = (window as any)?.SplunkRum;
    tracer = splunkRum?.provider?.getTracer?.(tracerName);
  }

  const span = tracer?.startSpan?.(name, {
    attributes
  });

  return {
    end: () => {
      span?.end?.();
    }
  };
}

const splunkRum = { startSpan };

export default splunkRum;
