import operationAND from './operationAND';
import operationOR from './operationOR';
import { CriterionOperationFunctionType } from './types';

const operationFunctions = {
  AND: operationAND,
  OR: operationOR
};

export type CriterionOperationFunctionsObjectType = {
  [key in keyof typeof operationFunctions]: CriterionOperationFunctionType;
};

export default operationFunctions as CriterionOperationFunctionsObjectType;
