import webServiceRouting from '../../../../../../services/webServiceRouting';
import eventService from '../../../../../../services/eventService';
import getOnboardingSessionAppDetails from './utils/getOnboardingSessionAppDetails';
import { isNative } from '../../../../../../services/JWeb';
import matchPath from '../../../../../../utils/matchPath';
import bindAllMethods from '../../../../../../utils/bindAllMethods';
import {
  CloseServiceInstanceOptions,
  Service,
  ServiceInstanceState
} from '../../../../../../services/webServiceRouting/types';
import OnboardingDirector from './clients/OnboardingDirector';
import { PutRequestOptionsType } from './clients/OnboardingDirector/types';
import {
  getNativeServiceLaunchOptions,
  closeNativeOnboardingInstance
} from '../../../../../../services/JWeb/JWebServiceRouting';
import { Action } from 'history';
import * as T from './types';
import splunkRum, { SpanType } from '../../../../../../services/splunkRum';
import { internalLogger } from '../../../../logger';

const serviceLaunchOptionsCacheKey = 'shell-onboarding-agent-session';

export default class OnboardingAgent {
  private _storeInterface: T.OnboardingAgentConstructorDependencies['storeInterface'];
  private _localizationInterface: T.OnboardingAgentConstructorDependencies['localizationInterface'];
  private _navigationInterface: T.OnboardingAgentConstructorDependencies['navigationInterface'];
  private _tenantHandler: T.OnboardingAgentConstructorDependencies['tenantHandlerInterface'];
  private _onboardingTriggerList: T.OnboardingTrigger[] = [];
  private _onboardingDirector: OnboardingDirector;
  private _onboardingDirectorClientId: string;
  private _onboardingInstance: T.OnboardingInstanceType = {
    state: ServiceInstanceState.closed
  };
  OnboardingInstanceState = ServiceInstanceState;

  constructor(dependencies: T.OnboardingAgentConstructorDependencies) {
    bindAllMethods(this);
    this._startWebServiceRoutingCloseStateListener();
    this._onboardingDirector = new OnboardingDirector(
      dependencies.stack,
      dependencies.authProviderInterface
    );
    this._localizationInterface = dependencies.localizationInterface;
    this._navigationInterface = dependencies.navigationInterface;
    this._onboardingDirectorClientId = dependencies.onboardingDirectorClientId;
    this._storeInterface = dependencies.storeInterface;
    this._tenantHandler = dependencies.tenantHandlerInterface;

    if (Array.isArray(dependencies?.onboardingTriggerList)) {
      this._onboardingTriggerList = dependencies?.onboardingTriggerList;
    }
  }

  async getOnboardingInstance(): Promise<T.OnboardingInstanceType> {
    return this._onboardingInstance;
  }

  private _closeOnboardingInstacePromise: Promise<void>;
  async closeOnboardingInstace(options: {
    result: CloseServiceInstanceOptions['resultData']['result'];
  }): Promise<void> {
    if (this._onboardingInstance.state === this.OnboardingInstanceState.closed)
      return undefined;
    else if (this._closeOnboardingInstacePromise)
      return this._closeOnboardingInstacePromise;
    // TODO: Enable this promise to finish, when remove navigation using href
    // let finishPromise: () => void;
    this._closeOnboardingInstacePromise = new Promise(() => {
      // finishPromise = () => {
      //   resolve();
      //   this._closeOnboardingInstacePromise = undefined;
      // };
    });

    const webServiceRoutingInstance = webServiceRouting.separateErrorObject(
      await webServiceRouting.getServiceInstance()
    )?.data;
    const isNativeEnvironment = await isNative();

    if (
      webServiceRoutingInstance?.state !==
      webServiceRouting.ServiceInstanceState?.closed
    ) {
      webServiceRouting.closeServiceInstance({
        resultData: { result: options.result }
      });
    }

    const session = this._onboardingInstance.session;
    // this._setOnboardingInstanceState(this.OnboardingInstanceState.closed);
    // this._onboardingInstance.retryUpdateOnboardingSession = undefined;
    // this._onboardingInstance.session = undefined;
    // this._onboardingInstance.authentication = undefined;

    window.sessionStorage.removeItem(serviceLaunchOptionsCacheKey);
    this._stopConfirmBeforeLeaveListener();

    if (isNativeEnvironment) {
      await closeNativeOnboardingInstance({
        resultData: {
          appSessionId: session?.appSessionId,
          result: {
            result: 'success',
            xCorrelationId: session?.context?.sessionContext?.xCorrelationId
          },
          serviceId: session?.context?.nextService?.serviceId
        }
      });
    } else {
      const defaultRedirect = this._navigationInterface.createHref({
        pathname: '/'
      });
      const exitUrl = session?.context?.nextService?.exitUrl;

      window.location.href = exitUrl || defaultRedirect;
    }

    // TODO: Enable this promise to finish, when remove navigation using href
    // finishPromise();
  }

  private async _legacyUpdateStore() {
    this._storeInterface.setState({
      onboarding: {
        sessionContext:
          this._onboardingInstance?.session?.context?.sessionContext,
        setSidebarVisibility: (enable: boolean) => {
          this._storeInterface.setState((prev) => ({
            ...prev,
            onboarding: {
              ...prev?.onboarding,
              sideBar: {
                ...prev?.onboarding?.sideBar,
                enabled: !!enable
              }
            }
          }));
        },
        updateStageContext: (context: any) => {
          this._storeInterface.setState((prev) => ({
            ...prev,
            onboarding: { ...prev?.onboarding, stageContext: context }
          }));
        },
        nextStage: (resultData, options) => {
          webServiceRouting.closeServiceInstance({
            ...options,
            resultData
          });
        }
      }
    });
  }

  async launchOnboardingInstance(trigger: T.OnboardingTrigger): Promise<void> {
    const app = await this._getAppDetails();

    await this._setOnboardingInstanceSession(async () => {
      const nativeServiceLaunchOptions =
        await this.getServiceLaunchOptionsFromNative();

      let context =
        nativeServiceLaunchOptions?.serviceOptions?.onboardingContext;

      if (!context) {
        context = await this._onboardingDirector.post({
          data: {
            onboardingContext: {
              ...trigger?.onboardingContext,
              entryUrl: window.location.href
            },
            app
          }
        });
      }

      return {
        context,
        trigger,
        appSessionId: nativeServiceLaunchOptions?.serviceOptions?.appSessionId
      };
    });
  }

  async resumeOnboardingInstance(
    onboardingInstanceSession: T.OnboardingInstanceSession
  ): Promise<void> {
    await this._setOnboardingInstanceSession(
      async () => onboardingInstanceSession
    );
  }

  getLocalCachedOnboardingAgentSession(): T.OnboardingInstanceSession {
    try {
      const stringfiedServiceLaunchOptions = window.sessionStorage.getItem(
        serviceLaunchOptionsCacheKey
      );

      if (stringfiedServiceLaunchOptions) {
        return JSON.parse(stringfiedServiceLaunchOptions);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private _getRemoteCachedOnboardingAgentSessionPromise: Map<
    string,
    Promise<T.OnboardingInstanceSession>
  > = new Map();
  async getRemoteCachedOnboardingAgentSession(
    trigger: T.OnboardingTrigger
  ): Promise<T.OnboardingInstanceSession> {
    const stringfiedTrigger = JSON.stringify(trigger);
    const cachedPromise =
      this._getRemoteCachedOnboardingAgentSessionPromise.get(stringfiedTrigger);
    if (cachedPromise) return cachedPromise;

    try {
      const getSession = async () => {
        const app = await this._getAppDetails();

        const haveTenant = !!this._tenantHandler.getTenantId(1);

        if (!haveTenant) return undefined;

        const onboardingContext = {
          ...(trigger?.onboardingContext || ({} as any)),
          entryUrl: window.location.href
        };
        const limit = 1;

        const contextList = await this._onboardingDirector.get({
          data: {
            onboardingContext,
            app,
            limit
          }
        });

        const context = contextList?.[0];

        if (context?.nextService?.serviceId) {
          const appSessionId = (await this.getServiceLaunchOptionsFromNative())
            ?.serviceOptions?.appSessionId;

          return {
            trigger,
            appSessionId,
            context
          };
        }
      };

      const promise = getSession()?.catch((error) => {
        console.error(error);

        return undefined as T.OnboardingInstanceSession;
      });

      this._getRemoteCachedOnboardingAgentSessionPromise.set(
        stringfiedTrigger,
        promise
      );

      const result = await promise;

      return result;
    } finally {
      /**
       * keep cache for 10 seconds for same trigger
       */
      setTimeout(() => {
        this._getRemoteCachedOnboardingAgentSessionPromise.delete(
          stringfiedTrigger
        );
      }, 10000);
    }
  }

  getOnboardingTriggerFromPath(path: string): T.OnboardingTrigger {
    return this._onboardingTriggerList.find((trigger) => {
      return matchPath(trigger.path, { exact: true, pathToCompare: path });
    });
  }

  async findService(callback: (service: Service) => boolean): Promise<Service> {
    const services = webServiceRouting.separateErrorObject(
      await webServiceRouting.getServices()
    )?.data?.services;

    return services?.find((service) => !!callback(service));
  }

  async getServiceFromServiceId(serviceId: string): Promise<Service> {
    if (serviceId) {
      return this.findService((service) => service.id === serviceId);
    }
  }

  async getServiceFromPath(path: string): Promise<Service> {
    return this.findService((service) => {
      if (service.path) {
        return matchPath(service.path, { exact: true, pathToCompare: path });
      }
      return false;
    });
  }

  // private _setOnboardingInstanceState(state: ServiceInstanceState) {}

  private _getServiceLaunchOptionsFromNativePromise?: Promise<T.OnboardingLaunchServiceOptions>;
  async getServiceLaunchOptionsFromNative(): Promise<T.OnboardingLaunchServiceOptions> {
    if (!this._getServiceLaunchOptionsFromNativePromise) {
      this._getServiceLaunchOptionsFromNativePromise = (async () => {
        if (!(await isNative())) return undefined;
        const nativeServiceLaunchOptions =
          (await getNativeServiceLaunchOptions()) as T.OnboardingLaunchServiceOptions;

        const serviceOptions = nativeServiceLaunchOptions?.serviceOptions;
        const serviceId =
          serviceOptions?.onboardingContext?.nextService?.serviceId;

        if (serviceId) {
          return {
            serviceId,
            serviceOptions
          };
        }
      })();
    }

    return this._getServiceLaunchOptionsFromNativePromise;
  }

  private async _getAppDetails() {
    return getOnboardingSessionAppDetails({
      browserLocale: {
        country: this._localizationInterface.country?.toUpperCase() || 'US',
        language: this._localizationInterface.language?.toUpperCase() || 'EN'
      },
      clientId: this._onboardingDirectorClientId
    });
  }

  private _splunkRumSpan: SpanType;
  private _setOnboardingInstanceState(state: ServiceInstanceState) {
    const previousState = this._onboardingInstance.state;
    if (previousState !== state) {
      this._onboardingInstance.state = state;
      const newState = this._onboardingInstance?.state;
      const { exitUrl, resultUrl, serviceId } =
        this._onboardingInstance?.session?.context?.nextService || {};
      const appSessionId = this._onboardingInstance?.session?.appSessionId;
      const { sessionId, xCorrelationId } =
        this._onboardingInstance?.session?.context?.sessionContext || {};

      const endSpan = () => {
        if (this._splunkRumSpan) {
          this._splunkRumSpan?.end?.();
          this._splunkRumSpan = undefined as any;
        }
      };

      const startOnboardingSpan = (): void => {
        endSpan();

        const eventName = (() => {
          if (!serviceId) {
            if (state === 'launching') return `onboarding-instance-starting`;
            else if (state === 'closed') return `onboarding-instance-closing`;
            else return `onboarding-instance-failed`;
          } else if (state === 'running') {
            return `launching-${serviceId}`;
          } else if (state === 'failedToLaunch') {
            return `failed-to-finalize-${serviceId}`;
          } else {
            return `finalizing-${serviceId}`;
          }
        })();

        this._splunkRumSpan = splunkRum?.startSpan?.('onboarding-agent', {
          'onboarding.serviceId': serviceId,
          'onboarding.state': newState,
          'onboarding.exitUrl': exitUrl,
          'onboarding.resultUrl': resultUrl,
          'onboarding.sessionId': sessionId,
          'workflow.name': eventName
        });

        if (newState === this.OnboardingInstanceState.closed) {
          endSpan();
        }
      };

      const triggerEvents = (): void => {
        const onboardingWasClosed =
          !previousState ||
          previousState === this.OnboardingInstanceState.closed;

        if (previousState === this.OnboardingInstanceState.running) {
          eventService.publish(
            eventService.eventNames.webOnboardingStageFinished,
            {
              appSessionId,
              serviceId
            }
          );
        }

        if (
          onboardingWasClosed &&
          newState !== this.OnboardingInstanceState.closed
        ) {
          eventService.publish(eventService.eventNames.webOnboardingStarted, {
            appSessionId: appSessionId
          });
        }

        if (
          newState === this.OnboardingInstanceState.running ||
          newState === this.OnboardingInstanceState.failedToLaunch
        ) {
          eventService.publish(
            eventService.eventNames.webOnboardingStageStarted,
            {
              appSessionId,
              serviceId,
              result: {
                result:
                  newState === this.OnboardingInstanceState.failedToLaunch
                    ? 'failed'
                    : 'success',
                xCorrelationId
              }
            }
          );
        }

        if (newState === this.OnboardingInstanceState.closed) {
          eventService.publish(
            eventService.eventNames.webOnboardingFinished,
            {}
          );
        }
      };

      startOnboardingSpan();
      triggerEvents();
      internalLogger?.log?.(
        `onboarding-state-changed-to-${newState}`,
        JSON.stringify(this._onboardingInstance.session)
      );
    }
  }

  private _setOnboardingInstanceSessionPromise: Promise<void>;
  private async _setOnboardingInstanceSession(
    getSession: () => Promise<T.OnboardingInstanceType['session']>
  ): Promise<void> {
    if (this._setOnboardingInstanceSessionPromise) {
      internalLogger?.log?.(
        'onboarding-web-_setOnboardingInstanceSession-error: ',
        'pending promise'
      );
      return this._setOnboardingInstanceSessionPromise;
    }
    let finishPromise: () => void;
    this._setOnboardingInstanceSessionPromise = new Promise((resolve) => {
      finishPromise = () => {
        resolve();
        this._setOnboardingInstanceSessionPromise = undefined;
      };
    });

    this._setOnboardingInstanceState(this.OnboardingInstanceState.launching);
    this._onboardingInstance.retryUpdateOnboardingSession = () =>
      this._setOnboardingInstanceSession(getSession);

    this._startConfirmBeforeLeaveListener();
    const newSession = await getSession?.()?.catch((error) => {
      console.error(error);
      return undefined;
    });

    const onboardingSessionId = newSession?.context?.sessionContext?.sessionId;
    const newServiceId = newSession?.context?.nextService?.serviceId;

    if (!onboardingSessionId) {
      this._setOnboardingInstanceState(
        this.OnboardingInstanceState.failedToLaunch
      );
      eventService.publish('onboardingAgentFailedToLaunch', undefined);
    } else {
      this._onboardingInstance.session = newSession;
      window.sessionStorage.setItem(
        serviceLaunchOptionsCacheKey,
        JSON.stringify(newSession)
      );
      this._legacyUpdateStore();

      if (newServiceId) {
        await webServiceRouting.launchService({
          serviceId: newServiceId,
          serviceOptions: {
            serviceId: newServiceId,
            serviceOptions: {
              onboardingContext: newSession?.context
            }
          }
        });
        this._setOnboardingInstanceState(this.OnboardingInstanceState.running);
      } else {
        await this.closeOnboardingInstace({
          result: 'success'
        });
      }
    }

    finishPromise();
  }

  /**
   * Listeners
   */

  private _startedWebServiceRoutingCloseStateListener = false;
  private async _startWebServiceRoutingCloseStateListener() {
    if (this._startedWebServiceRoutingCloseStateListener) return undefined;
    this._startedWebServiceRoutingCloseStateListener = true;
    eventService.subscribe(
      webServiceRouting.Events.ServiceInstanceClosed,
      async (event) => {
        if (this._onboardingInstance?.state !== ServiceInstanceState.closed) {
          const eventData: CloseServiceInstanceOptions = event.eventData;
          const loginType = eventData?.authentication?.loginType;

          if (loginType) {
            this._onboardingInstance.authentication = {
              loginType
            };
          }

          const resultData =
            eventData?.resultData as PutRequestOptionsType['data'];

          if (resultData?.result === 'cancelled') {
            await this.closeOnboardingInstace({
              result: 'cancelled'
            });
          } else {
            const sessionContext = this._onboardingInstance?.session?.context;

            const resultUrl = sessionContext?.nextService?.resultUrl;
            const xCorrelationId =
              sessionContext?.sessionContext?.xCorrelationId;

            await this._setOnboardingInstanceSession(async () => {
              const context = await this._onboardingDirector.put({
                baseURL: resultUrl,
                data: {
                  ...resultData,
                  xCorrelationId
                }
              });

              return {
                context,
                trigger: this._onboardingInstance.session.trigger,
                appSessionId: this._onboardingInstance.session.appSessionId
              };
            });
          }
        }
      }
    );
  }

  private _removeConfirmBeforeLeaveListener?: () => void;
  private async _startConfirmBeforeLeaveListener() {
    if (await isNative()) return;
    let confirmed = false;
    let lastAction: Action;

    const globalTranslate =
      await this._localizationInterface?.getGlobalTranslatorFunction?.();

    function translate(key: string | undefined, defaultValue: string) {
      if (globalTranslate && key) {
        return globalTranslate?.(key, defaultValue);
      } else {
        return defaultValue;
      }
    }

    this._navigationInterface.push(
      `${this._navigationInterface.location.pathname}${
        this._navigationInterface.location.search || ''
      }`
    );

    const getConfirmBeforeLeave = () => {
      const manifest = this._storeInterface?.state?.manifest;

      const {
        message: manifestMessage,
        translationKey,
        enable
      } = manifest?.services?.onboarding?.confirmBeforeLeave || {};

      const hardCodedDefaultMessage =
        'Please stay on this screen. If you select OK, you may lose your progress. To continue, select Cancel.';

      const defaultMessage =
        typeof manifestMessage === 'string'
          ? manifestMessage
          : hardCodedDefaultMessage;

      return {
        enable: !!enable,
        message: translate?.(translationKey, defaultMessage)
      };
    };

    const removeNavigationBlocker = this._navigationInterface.block(
      (location, action) => {
        const { enable, message } = getConfirmBeforeLeave();
        const allowedAction =
          action === 'POP' && lastAction !== 'REPLACE' && lastAction !== 'PUSH';
        lastAction = action;

        if (allowedAction && !!enable) {
          if (!confirmed) {
            confirmed = confirm(message);
          }

          if (confirmed) {
            const triggerPath =
              this._storeInterface?.state?.onboarding?.context?.sessionContext
                ?.onboardingContext?.entryUrl;

            if (this._removeConfirmBeforeLeaveListener)
              this._removeConfirmBeforeLeaveListener();

            window.location.href = triggerPath || '/';
          } else {
            this._navigationInterface.goForward();
            return false;
          }
        }
      }
    );

    const beforeunloadCallback = (event: BeforeUnloadEvent) => {
      const { enable, message } = getConfirmBeforeLeave();
      const loginPathList = ['/login', '/loggedin', '/loggedout'];
      const isLoginPath = loginPathList?.some?.((pathname) =>
        this._navigationInterface.location.pathname.startsWith(pathname)
      );

      if (!confirmed && enable && !isLoginPath) {
        event.returnValue = message;
        event.preventDefault();
        return message;
      }
    };

    if (!this._removeConfirmBeforeLeaveListener) {
      window.addEventListener('beforeunload', beforeunloadCallback, {
        capture: true
      });

      this._removeConfirmBeforeLeaveListener = () => {
        removeNavigationBlocker();
        window.removeEventListener('beforeunload', beforeunloadCallback, {
          capture: true
        });
      };
    }
  }

  private async _stopConfirmBeforeLeaveListener() {
    if (typeof this._removeConfirmBeforeLeaveListener === 'function') {
      this._removeConfirmBeforeLeaveListener?.();
    }
  }
}
