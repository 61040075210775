import { ISessionService } from '../services/session';
import { NavigationType } from '../interface/v1/orgSelector/types';
import { TenantHandlerService } from '../services/tenantHandler';

type NavigationWithSessionParams = {
  navigation: NavigationType;
  sessionService: ISessionService;
  tenantHandlerService: TenantHandlerService;
};

export default class UseLoggedInRules {
  private _navigation: NavigationType;
  private _sessionService: ISessionService;
  private _tenantHandlerService: TenantHandlerService;

  constructor({
    navigation,
    sessionService,
    tenantHandlerService
  }: NavigationWithSessionParams) {
    this._navigation = navigation;
    this._sessionService = sessionService;
    this._tenantHandlerService = tenantHandlerService;
  }

  public init = async (): Promise<void> => {
    const isLoggedIn = this._sessionService.isLoggedIn();
    if (!isLoggedIn) {
      return;
    }

    const loggedInPath = this._navigation?.createHref?.({
      pathname: '/loggedin'
    });

    const currentPath = window.location.pathname;

    if (currentPath === loggedInPath) {
      await this._sessionService.refresh({
        tenantsIdMap: this._tenantHandlerService?.getTenantIdsMap()
      });
    }
  };
}
