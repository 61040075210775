import * as T from './types';
import { StratusClient } from '@jarvis/web-stratus-client/dist/src/clients/stratus-client';
import {
  DEFAULT_INITIAL_CURRENT_PAGE as DEFAULT_INITIAL_PAGE,
  DEFAULT_PAGINATION_SIZE_GET_NODE_PERSON_DOMAIN
} from './constants';
import { Stack } from '../../../types/stratus';
import { getStratusBaseUrl } from '../../stackUtils';

export default class PersonClient extends StratusClient {
  apiName = 'ws-hp.com/tenancy-registry';
  apiVersion = '1';
  personDomainsV1Endpoint = '/v1/person';

  public static getBaseUrl(stack: Stack): string {
    return `${getStratusBaseUrl(stack)}/tenancy-registry/v1/person`;
  }

  public async getPersonDomain(
    options: T.GetPersonalDomainOptionsType
  ): Promise<T.GetNodeResponseType> {
    const { page, paginationSize, ...props } = options;

    const response = await this.jarvisWebHttpInstance.get({
      url: `/domains`,
      params: {
        page: page || DEFAULT_INITIAL_PAGE,
        size: paginationSize || DEFAULT_PAGINATION_SIZE_GET_NODE_PERSON_DOMAIN,
        ...props
      }
    });
    return response.data as T.GetNodeResponseType;
  }
}
