import { getAccessPolicies } from '../../clients/accessPolicies/getAccessPolicies';
import { UserContextEnum } from '../../interface/types';
import { AuthProviderV2Type } from '../../interface/v1/accessControl/types';
import { IAuthTokenService } from '../authTokenService';
import userContextEnumToAuthContextEnum from '../authTokenService/utils/userContextEnumToAuthContextEnum';
import { IScopeService, ScopeServiceParameterType } from './IScopeService';
import { ScopeRepository } from './ScopeRepository';
import { Scope } from './types';

export class ScopeService implements IScopeService {
  private _scopeRepository: ScopeRepository;
  private _authProvider: AuthProviderV2Type;
  private _authTokenService: IAuthTokenService;
  constructor({
    scopeRepository,
    authProvider,
    authTokenService
  }: ScopeServiceParameterType) {
    this._scopeRepository = scopeRepository;
    this._authProvider = authProvider;
    this._authTokenService = authTokenService;
  }

  public hasCachedScopes(options): boolean {
    const repositoryKey = this.getRepositoryKey(options);
    const result = this._scopeRepository.findOne(repositoryKey);
    return result?.length > 0;
  }

  public checkScopes(scopesToCheck, options): boolean {
    const repositoryKey = this.getRepositoryKey(options);
    const scopes = this._scopeRepository.findOne(repositoryKey);
    return scopesToCheck?.every?.((requestedScope) =>
      scopes?.some?.(
        (cachedScope: Scope) => requestedScope.scope === cachedScope.scope
      )
    );
  }

  // TODO: Refactor to work with AuthContextEnum instead of userContextEnum when AuthContextEnum authProvider exists
  private async getAccessPoliciesScopes(userContextString): Promise<Scope[]> {
    const userContext = userContextString
      ? UserContextEnum[userContextString]
      : null;
    const response = await getAccessPolicies(
      this._authProvider.createAuthProviderByUserContextEnum(userContext),
      this._authTokenService
    );
    return (
      response?.scopes.map((scopeName) => {
        return {
          scope: scopeName
        };
      }) || []
    );
  }

  public async updateScopes(options): Promise<boolean> {
    const repositoryKey = this.getRepositoryKey(options);
    const cachedScopes: Scope[] = this._scopeRepository.findOne(repositoryKey);
    // TODO: Refactor to work with AuthContextEnum instead of userContextEnum
    const newScopes = await this.getAccessPoliciesScopes(options?.userContext);
    const sameSize = newScopes?.length === cachedScopes?.length;
    const isEqual =
      sameSize && newScopes.toString() === cachedScopes.toString();
    if (!isEqual) {
      this._scopeRepository.save(repositoryKey, newScopes);
      return true;
    }
    return false;
  }

  // TODO: Need to be removed in the future and this service should work only with AuthContextEnum
  private getRepositoryKey(options): string {
    const userContext = options?.userContext;
    const authContext = userContextEnumToAuthContextEnum(userContext);
    return this._authTokenService.getSuffix(authContext);
  }
}
