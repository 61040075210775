import { JarvisWebHttpClient } from '@jarvis/web-http';
import { getOneCloudBaseUrl } from '../../../clients/stackUtils';
import {
  CreateAccountOptionsType,
  CreateAccountResponseType
} from '../../../clients/stratus/accountmgtsvc/types';
import { AuthProviderV2Type } from '../../../interface/v2/auth';
import { Stack } from '../../../types/stratus';

export default class OnecloudOauthClient {
  private urlPrefix = 'oauth-auth';
  private jarvisWebHttpInstance: JarvisWebHttpClient;
  private apiName = '/oauth2';
  private apiVersion = '/v1';
  private createStratusAccountEndpoint = '/stratus/create_account';

  private _baseUrl: string;

  constructor({
    stack,
    authProvider
  }: {
    stack: Stack;
    authProvider: AuthProviderV2Type;
  }) {
    this.jarvisWebHttpInstance = new JarvisWebHttpClient({
      defaultAuthProvider: authProvider
    });
    this._baseUrl = `${getOneCloudBaseUrl(stack, this.urlPrefix)}${
      this.apiName
    }${this.apiVersion}`;
  }

  public async createStratusAccount(
    options: CreateAccountOptionsType
  ): Promise<CreateAccountResponseType> {
    const { data } = await this.jarvisWebHttpInstance.post({
      url: `${this._baseUrl}${this.createStratusAccountEndpoint}`,
      data: options
    });
    return data as CreateAccountResponseType;
  }
}
