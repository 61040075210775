import * as T from './types';
import breadcrumbService from '../../../services/breadcrumbService';

export default class Breadcrumb {
  listen(callback: T.BreadcrumbListenerCallbackType): () => void {
    return breadcrumbService.listen(callback);
  }

  useReactListener(React: any): T.BreadcrumbItemType[] {
    return breadcrumbService.useReactListener(React);
  }

  getBreadcrumbs(): T.BreadcrumbItemType[] {
    return breadcrumbService.getBreadcrumbs();
  }

  add(breadcrumbItem: T.BreadcrumbItemType): void {
    breadcrumbService.add(breadcrumbItem);
  }

  isEnabled(): boolean {
    return breadcrumbService.isEnabled();
  }

  remove(breadcrumbKey: string): void {
    breadcrumbService.remove(breadcrumbKey);
  }
}
