enum EventNames {
  shellCriterionChangedEventName = 'shell-criterion-changed',
  shellEntitlementChangedEventName = 'shell-entitlement-changed',
  shellAccessControlChangedEventName = 'shell-access-control-changed',
  shellUserLogedOutEventName = 'shell-user-logged-out',
  shellUserLogedInEventName = 'shell-user-logged-in',
  shellCallInterfaceNavigationPush = 'shell-interface-navigation-push',
  shellFeatureFlagChangedEventName = 'shell-feature-flag-changed',
  webOnboardingStarted = 'WebOnboardingStarted',
  webOnboardingStageStarted = 'WebOnboardingStageStarted',
  webOnboardingStageFinished = 'WebOnboardingStageFinished',
  webOnboardingFinished = 'WebOnboardingFinished'
}

export default EventNames;
