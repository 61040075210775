import { ManifestType } from '../types/manifest';

export type InterfacesInitializerStatePropsType = {
  manifest: ManifestType;
  defaultAssetReferences?: AssetReferencesMap;
  [key: string]: unknown;
};

export enum TokenType {
  stratusAccessToken = 'shell-stratus-access-token',
  stratusIdToken = 'shell-stratus-id-token',
  deprecatedstratusAccessToken = 'stratus-access-token',
  deprecatedstratusIdToken = 'stratus-id-token',
  deprecatedOrglessToken = 'stratus-orgless-access-token',
  deprecatedOrgedToken = 'stratus-org-access-token',
  orglessToken = 'shellStratusOrglessAccessToken',
  orgedToken = 'shellStratusOrgAccessToken',
  astToken = 'shell-support-session',
  supportSession = 'shell-support-session',
  shellSessionId = 'shell-session-id'
}

export enum UserContextEnum {
  organization = 'organization',
  customer = 'customer',
  orgless = 'orgless'
}

export type RedirectToLoginParams = {
  postLoginRedirect: string;
};

export type AssetReferencesMap = {
  [key: string]: string;
};
