import { ISessionService } from '../../../services/session';
import { TenantHandlerService } from '../../../services/tenantHandler';
import {
  GetProviderListParam,
  GetProviderListResponseType
} from '../../../clients/shell/provider';
import bindAllMethods from '../../../utils/bindAllMethods';
import { GenerateAuthenticationUrlParams } from '../../../services/session/loginService';

export type SessionInterfaceV1Type = {
  isLoggedIn(): boolean;
  getProviderList(
    options?: GetProviderListParam
  ): Promise<GetProviderListResponseType>;
  generateAuthenticationUrl(
    options: GenerateAuthenticationUrlParams
  ): Promise<string>;
  logout(): Promise<void>;
  refreshToken(): Promise<void>;
  getIdToken(): string;
};

type SessionInterfaceV1Param = {
  sessionService: ISessionService;
  tenantHandler: TenantHandlerService;
};

class SessionInterfaceV1 {
  private _sessionService: ISessionService;
  private _tenantHandler: TenantHandlerService;

  constructor({ sessionService, tenantHandler }: SessionInterfaceV1Param) {
    this._sessionService = sessionService;
    this._tenantHandler = tenantHandler;
    bindAllMethods(this);
  }

  public getInterface(): SessionInterfaceV1Type {
    return {
      logout: this._sessionService.logout,
      refreshToken: this._refreshToken,
      isLoggedIn: this._sessionService.isLoggedIn,
      getIdToken: this._sessionService.getIdToken,
      getProviderList: this._sessionService.getProviderList,
      generateAuthenticationUrl: this._sessionService.generateAuthenticationUrl
    };
  }

  private _refreshToken(): Promise<void> {
    const tenantsIdMap = this._tenantHandler.getTenantIdsMap();
    return this._sessionService.refresh({ tenantsIdMap });
  }
}

export default SessionInterfaceV1;
