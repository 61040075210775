import * as LDClient from 'launchdarkly-js-client-sdk';
import bindAllMethods from '../../utils/bindAllMethods';
import { IEncryptService } from '../encryptService';
import { EventServiceType } from '../eventService/types';
import { IUserService } from '../userService';
import { LAUNCH_DARKLY_ANONYMOUS_HASH } from './constants';
import IFeatureFlagClient from './IFeatureFlagClient';
import IFeatureFlagService from './IFeatureFlagService';
import LaunchDarklyFeatureFlagClient from './LaunchDarklyFeatureFlagClient';
import { LaunchDarklyFFServiceParams, LaunchDarklyProjectType } from './types';

export default class LaunchDarklyFeatureFlagService
  implements IFeatureFlagService
{
  private _eventService: EventServiceType;
  private _projectSettingsList: LaunchDarklyProjectType[];
  private _launchDarklyClients: { key: string; client: IFeatureFlagClient }[];
  private _userContext: LDClient.LDSingleKindContext;
  private _userService: IUserService;
  private _encryptService: IEncryptService;
  private _appName: string;

  constructor({
    projectSettingsList,
    eventService,
    userService,
    encryptService,
    appName
  }: LaunchDarklyFFServiceParams) {
    this._projectSettingsList = projectSettingsList;
    this._appName = appName;
    this._launchDarklyClients = [];

    this._encryptService = encryptService;
    this._eventService = eventService;
    this._userService = userService;
    bindAllMethods(this);
  }

  public async init(options: {}): Promise<void> {
    this._userContext = await this._generateContext();
  }

  public async getClient(key: string): Promise<IFeatureFlagClient> {
    let client = this._launchDarklyClients?.find((c) => c.key === key)?.client;

    if (!client) {
      client = await this._initializeClient({ key });
      this._launchDarklyClients?.push({ key, client });
    }
    return client;
  }

  private async _generateContext(): Promise<LDClient.LDSingleKindContext> {
    let hashedKey = LAUNCH_DARKLY_ANONYMOUS_HASH;
    try {
      const email = await this._userService?.getEmail?.();
      if (email) {
        hashedKey = await this._encryptService?.encryptSHA256(email);
      }
    } catch (error) {
      console.warn("The device doesn't support Web Crypt API.", error);
    }
    return { kind: 'user', key: `${this._appName}-${hashedKey}` };
  }

  private async _initializeClient({
    key
  }: {
    key: string;
  }): Promise<IFeatureFlagClient> {
    const { clientId } = this._projectSettingsList.find((p) => p.key === key);

    const client = new LaunchDarklyFeatureFlagClient();
    await client.initializeClient({
      key,
      clientId,
      context: this._userContext,
      eventService: this._eventService
    });
    return client;
  }
}
