import { Scope } from '../services/scope';
import { internalLogger } from '../interface/v1/logger';
import { DAOItemType, IShellDAO } from './IShellDAO';
import { FindAllRepositoryItemsParams } from './types';

export class ShellLocalStorageDAOImpl implements IShellDAO {
  delete(key: string): boolean {
    const obj = this.findOne(key);
    if (obj) {
      localStorage.removeItem(key);
      return true;
    }
    return false;
  }

  findOne(key: string): Scope[] {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (err) {
      internalLogger?.error(err);
      return undefined;
    }
  }

  save(key: string, value: unknown): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  saveAll(key: string, values: []): void {
    this.save(key, values);
  }

  removeItem(key: string) {
    try {
      return localStorage.removeItem(key);
    } catch (err) {
      internalLogger?.error(err);
      return undefined;
    }
  }

  deleteAll(keys: string[]): void {
    try {
      keys.forEach((key) => localStorage.removeItem(key));
    } catch (err) {
      internalLogger?.error(err);
      return undefined;
    }
  }

  findAll({ prefixKey }: FindAllRepositoryItemsParams): DAOItemType[] {
    const items = [] as DAOItemType[];
    // TODO: i'm not sure if the code below Skip built-in properties
    // like length, setItem, etc.
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith(prefixKey)) {
        items.push({ key, value: this.findOne(key) });
      }
    }
    return items;
  }
}
