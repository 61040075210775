import getProgramInfo from '../../../clients/stratus/entitlements/getProgramInfo';
import getUserWithTenantDetails from '../../../clients/stratus/usersMgt/getUserWithTenantDetails';
import {
  EntitlementsListType,
  ProgramInfoMapPropertyType,
  SubscriptionOfferingListType,
  ProgramInfoResponseType
} from '../../../clients/stratus/entitlements/getProgramInfo/types';
import {
  InterfacesInitializerStatePropsType,
  UserContextEnum
} from '../../types';
import {
  AuthProviderV2Type,
  OfferingAndEntitlementType,
  EntitlementsDetailsType,
  RefreshType,
  OfferingAndEntitlementOptionsType
} from './types';
import { internalLogger } from '../logger';
import Store from '../store/index';
import { EventsType } from '../events/types';
import EventNames from '../../../config/eventNames';
import { PromiseHelper } from '../../../utils/PromiseHelper';
import { ISessionService } from '../../../services/session';
import AppContext from '../../../services/appContext/AppContext';
import { IAuthTokenService } from '../../../services/authTokenService';
import userContextEnumToAuthContextEnum from '../../../services/authTokenService/utils/userContextEnumToAuthContextEnum';
import { EntitlementRepository } from '../../../services/entitlements/EntitlementRepository';

export default async ({
  authProvider,
  manifest,
  store,
  events,
  sessionService,
  appContext,
  authTokenService,
  repository
}: InterfacesInitializerStatePropsType & {
  authProvider: AuthProviderV2Type;
} & { store: Store; events: EventsType } & {
  sessionService: ISessionService;
} & { appContext: AppContext } & { authTokenService: IAuthTokenService } & {
  repository: EntitlementRepository;
}) => {
  const { enabled } = manifest?.services?.entitlements || {};
  const { stack } = manifest?.portal;

  const defaultKey = 'default';
  const initializedUserContexts: Map<
    UserContextEnum | typeof defaultKey,
    boolean
  > = new Map();
  const promiseHelper = new PromiseHelper();

  function _isDifferentFromCache(
    newProgramInfo: ProgramInfoResponseType,
    authContextSuffix: string
  ) {
    try {
      const cachedProgramInfo: ProgramInfoResponseType =
        repository.findOne(authContextSuffix);

      const newProgramInfoString = JSON.stringify(newProgramInfo);
      const cachedProgramInfoString = JSON.stringify(cachedProgramInfo);

      return newProgramInfoString !== cachedProgramInfoString;
    } catch (err) {
      internalLogger?.error?.(err);
      return true;
    }
  }

  function _getAuthProviderByUserContextEnum(
    userContextTypeString: string
  ): AuthProviderV2Type {
    const userContext = userContextTypeString
      ? UserContextEnum[userContextTypeString]
      : null;
    return authProvider.createAuthProviderByUserContextEnum(userContext);
  }

  function _getAuthContextSuffix(options?: OfferingAndEntitlementOptionsType) {
    const authContext = options?.userContext
      ? userContextEnumToAuthContextEnum(options.userContext)
      : authTokenService.getCurrentContext();
    return authTokenService.getSuffix(authContext);
  }

  async function refreshAction(options?: OfferingAndEntitlementOptionsType) {
    const _authProvider = _getAuthProviderByUserContextEnum(
      options?.userContext
    );

    const userAndTenantDetailsResponse = await getUserWithTenantDetails({
      authProvider: _authProvider,
      stack
    });

    const tenantResourceId =
      userAndTenantDetailsResponse?.data?.userTenantDetail?.tenantResourceId;
    const userResourceId = userAndTenantDetailsResponse?.data?.user?.resourceId;

    const programInfoDetailsResponse = await getProgramInfo({
      authProvider: _authProvider,
      stack,
      tenantResourceId,
      userResourceId
    });

    const newProgramInfo =
      programInfoDetailsResponse?.data || ({} as ProgramInfoResponseType);

    const authContextSuffix = _getAuthContextSuffix(options);

    if (_isDifferentFromCache(newProgramInfo, authContextSuffix)) {
      repository.save(authContextSuffix, newProgramInfo);
      events.triggerEvent(
        EventNames.shellEntitlementChangedEventName,
        undefined
      );
    }
  }

  async function refresh(
    refreshOption?: RefreshType,
    options?: OfferingAndEntitlementOptionsType
  ) {
    const promiseKey = options?.userContext ?? 'default';
    let promise = promiseHelper.getPendingPromise(promiseKey);
    if (isEnabled() && !promise) {
      promise = refreshAction(options);
      promiseHelper.setPendingPromise(promiseKey, promise);
    }
    await promise;
  }

  async function _initialize(options?: OfferingAndEntitlementOptionsType) {
    const contextKey = options?.userContext ?? defaultKey;
    let promise = promiseHelper.getPendingPromise(contextKey);
    const isInitialized = initializedUserContexts.get(contextKey);

    if (!isInitialized) {
      initializedUserContexts.set(contextKey, true);
      if (!promise) {
        promise = refresh({ reloadContent: false }, options);
        promiseHelper.setPendingPromise(contextKey, promise);
      }
    }

    await promise;
  }

  function getFlatMap<key extends keyof ProgramInfoMapPropertyType>(
    propertyName: key,
    authContextSuffix: string
  ) {
    const programInfo: EntitlementsDetailsType =
      repository.findOne(authContextSuffix);

    let flatmap: ProgramInfoMapPropertyType[typeof propertyName] = [];

    if (typeof programInfo?.programInfoMap === 'object') {
      Object.values(programInfo?.programInfoMap).forEach?.((value) => {
        flatmap = [
          ...flatmap,
          ...value?.[propertyName]
        ] as ProgramInfoMapPropertyType[typeof propertyName];
      });
    }
    return flatmap.reverse() as ProgramInfoMapPropertyType[typeof propertyName];
  }

  async function checkEntitlements(
    entitlements: OfferingAndEntitlementType[],
    options?: OfferingAndEntitlementOptionsType
  ): Promise<boolean> {
    if (!isEnabled()) return true;

    await _initialize(options);

    const entitlementAndOfferingKey = _getAuthContextSuffix(options);

    const flatMapEntitlements = getFlatMap(
      'entitlementList',
      entitlementAndOfferingKey
    );
    const testEntitlements = entitlements?.every?.(
      (argumentEntitlement) =>
        !!flatMapEntitlements?.find?.(
          (cachedEntitlement) =>
            cachedEntitlement?.state == argumentEntitlement?.state &&
            cachedEntitlement?.serviceId == argumentEntitlement?.serviceId
        )
    );
    return !!testEntitlements;
  }

  async function checkOfferings(
    offerings: OfferingAndEntitlementType[],
    options?: OfferingAndEntitlementOptionsType
  ): Promise<boolean> {
    if (!isEnabled()) return true;

    await _initialize(options);

    const entitlementAndOfferingKey = _getAuthContextSuffix(options);

    const flatMapOfferings = getFlatMap(
      'subscriptionOfferingList',
      entitlementAndOfferingKey
    );
    const testOffering = offerings?.every?.(
      (argumentOffering) =>
        !!flatMapOfferings?.find?.(
          (cachedOffering) =>
            cachedOffering?.state == argumentOffering?.state &&
            cachedOffering?.serviceId == argumentOffering?.serviceId
        )
    );
    return !!testOffering;
  }

  async function getPlanInfo(options?: OfferingAndEntitlementOptionsType) {
    if (!isEnabled()) return undefined;

    await _initialize(options);

    const authContextSuffix = _getAuthContextSuffix(options);

    const programInfo: EntitlementsDetailsType =
      repository.findOne(authContextSuffix);
    return programInfo?.planInfo;
  }

  async function getOffering(
    serviceId: string,
    options?: OfferingAndEntitlementOptionsType
  ): Promise<SubscriptionOfferingListType> {
    if (!isEnabled()) return undefined;

    await _initialize(options);

    const entitlementAndOfferingKey = _getAuthContextSuffix(options);

    const flatOfferingMap = getFlatMap(
      'subscriptionOfferingList',
      entitlementAndOfferingKey
    );
    return flatOfferingMap?.find?.(
      (offering) => offering?.serviceId === serviceId
    );
  }

  async function getEntitlement(
    serviceId: string,
    options?: OfferingAndEntitlementOptionsType
  ): Promise<EntitlementsListType> {
    if (!isEnabled()) return undefined;

    await _initialize(options);

    const entitlementAndOfferingKey = _getAuthContextSuffix(options);

    const flatEntitlememtsMap = getFlatMap(
      'entitlementList',
      entitlementAndOfferingKey
    );
    return flatEntitlememtsMap?.find?.(
      (entitlement) => entitlement?.serviceId === serviceId
    );
  }

  function isEnabled() {
    if (!enabled) {
      console.warn('Entitlements: disabled');
      return false;
    }
    return true;
  }

  appContext.addInitServices(_initialize);
  appContext.addRefreshServices(refresh);

  return {
    refresh,
    checkEntitlements,
    checkOfferings,
    getPlanInfo,
    getOffering,
    getEntitlement
  };
};
