import MicrofrontendRouterOperationStateBuilder from '../../MicrofrontendRouterOperationStateBuilder';
import * as T from './types';
import OnboardingAgent from './OnboardingAgent';
import bindAllMethods from '../../../../../utils/bindAllMethods';
import Fallback from '../../../Fallback';
import { OnboardingInstanceSession } from './OnboardingAgent/types';

export default class OnboardingAgentOperation
  implements T.IMicrofrontendRouterOperation
{
  private _navigationInterface: T.OnboardingAgentOperationDependenciesType['navigationInterface'];
  private _userSessionInterface: T.OnboardingAgentOperationDependenciesType['userSessionInterface'];
  private _fallbackInterface: Fallback;
  private _onboardingAgent: OnboardingAgent;
  private _resumeOnTriggers = false;
  private sessionService: T.OnboardingAgentOperationDependenciesType['sessionService'];

  constructor(dependencies: T.OnboardingAgentOperationDependenciesType) {
    bindAllMethods(this);
    this._navigationInterface = dependencies.navigationInterface;
    this._userSessionInterface = dependencies.userSessionInterface;
    this.sessionService = dependencies.sessionService;
    this._fallbackInterface = dependencies.fallbackInterface;
    this._resumeOnTriggers = !!dependencies?.resumeOnTriggers;

    this._onboardingAgent = new OnboardingAgent({
      authProviderInterface: dependencies?.authProviderInterface,
      onboardingDirectorClientId: dependencies?.onboardingDirectorClientId,
      stack: dependencies?.stack,
      onboardingTriggerList: dependencies?.onboardingTriggerList,
      storeInterface: dependencies?.storeInterface,
      localizationInterface: dependencies?.localizationInterface,
      navigationInterface: dependencies?.navigationInterface,
      tenantHandlerInterface: dependencies?.tenantHandlerInterface
    });
  }

  private async isLoginRequired(options: {
    assetReference: string;
    public?: boolean;
  }) {
    if (!options?.public) {
      return !this.sessionService?.isLoggedIn?.();
    }
    return false;
  }

  getResumeSessionModalRootProperties(
    onboardingInstanceSession: OnboardingInstanceSession
  ) {
    const lastDateAndTime = (() => {
      const date =
        onboardingInstanceSession?.context?.sessionContext?.updatedAt;

      if (date) {
        return (
          new Date(date)?.toLocaleDateString() +
          ' ' +
          new Date(date)?.toLocaleTimeString()
        );
      }
    })();

    const productName = (() => {
      const modelName =
        onboardingInstanceSession?.context?.sessionContext?.device?.modelName;
      const deviceName =
        onboardingInstanceSession?.context?.sessionContext?.device?.deviceName;

      let result = ``;

      if (modelName) {
        result += modelName;
      }

      if (deviceName) {
        if (modelName) {
          result += ' - ';
        }
        result += deviceName;
      }

      return result;
    })();

    return {
      resumeSessionModalInfo: {
        lastDateAndTime,
        lastStep: onboardingInstanceSession?.context?.nextService?.serviceId,
        productName
      },
      resumeSession: async () => {
        await this._onboardingAgent.resumeOnboardingInstance(
          onboardingInstanceSession
        );
      },
      startNewSession: async () => {
        await this._onboardingAgent.launchOnboardingInstance(
          onboardingInstanceSession?.trigger
        );
      }
    };
  }

  async process(
    stateBuilder: MicrofrontendRouterOperationStateBuilder
  ): Promise<void> {
    const onboardingInstance =
      await this._onboardingAgent.getOnboardingInstance();

    const isValidLayoutKey = (layoutKey: string | false) =>
      typeof layoutKey === 'string' || layoutKey === false;

    const setLayout = (layoutKey: string | false) => {
      if (isValidLayoutKey(layoutKey)) {
        stateBuilder.setLayoutByKey(layoutKey);
      } else {
        stateBuilder.setLayout({
          enable: true,
          useDefaultOnboardingLayout: true
        });
      }
    };

    if (
      onboardingInstance &&
      onboardingInstance?.state !==
        this._onboardingAgent.OnboardingInstanceState.closed
    ) {
      stateBuilder.setEndProcessChain(true);
      const service = await this._onboardingAgent.getServiceFromServiceId(
        onboardingInstance?.session?.context?.nextService?.serviceId
      );
      const trigger = onboardingInstance?.session?.trigger;
      const retryUpdateOnboardingSession =
        onboardingInstance?.retryUpdateOnboardingSession;

      const setServiceLayout = (customLayoutKey?: string | false) => {
        const layoutKey = isValidLayoutKey(customLayoutKey)
          ? customLayoutKey
          : isValidLayoutKey(service?.layoutKey)
          ? service?.layoutKey
          : trigger?.layoutKey;

        setLayout(layoutKey);
      };

      if (
        onboardingInstance?.state ===
        this._onboardingAgent.OnboardingInstanceState.failedToLaunch
      ) {
        const fallbackKey =
          service?.fallbackKeys?.failedToLaunch ||
          trigger?.fallbackKeys?.failedToLaunch;

        const serviceFallback =
          this._fallbackInterface.getFallbackByKey(fallbackKey);

        const rootProperties = {
          retryUpdateOnboardingSession: async () =>
            retryUpdateOnboardingSession?.(),
          closeOnboardingSession: async () =>
            this._onboardingAgent.closeOnboardingInstace({
              result: 'failed'
            })
        };
        if (serviceFallback) {
          if (serviceFallback?.content?.assetReference) {
            stateBuilder.setContent({
              enable: true,
              assetReference: serviceFallback?.content?.assetReference,
              criterionKey: serviceFallback?.content?.criterionKey,
              properties: serviceFallback?.content?.properties,
              key: serviceFallback?.content?.key,
              rootProperties
            });
          }

          if (serviceFallback?.modalContent?.assetReference) {
            stateBuilder.setModalContent({
              enable: true,
              assetReference: serviceFallback?.modalContent?.assetReference,
              properties: serviceFallback?.modalContent?.properties,
              key: serviceFallback?.modalContent?.key,
              rootProperties
            });
          }

          setServiceLayout(serviceFallback?.layoutKey);
        } else {
          stateBuilder.setContent({
            enable: true,
            loadDefaultOnboardingError: true,
            rootProperties
          });
        }
      } else if (
        onboardingInstance?.state ===
        this._onboardingAgent.OnboardingInstanceState.running
      ) {
        if (service?.serviceType !== 'native') {
          const isLoginRequired = await this.isLoginRequired({
            assetReference: service?.assetReference,
            public: service?.public
          });

          if (isLoginRequired) {
            const loginType = onboardingInstance?.authentication?.loginType;
            const loginPath = this._userSessionInterface.getLoginPath({
              loginType
            });

            stateBuilder.setRedirectTo(loginPath);
          } else {
            stateBuilder.setContent({
              enable: !!service?.assetReference,
              assetReference: service?.assetReference,
              criterionKey: service?.criterionKey,
              properties: service?.properties,
              path: service?.path,
              key: service?.id
            });

            setServiceLayout();
          }
        }
      } else {
        setServiceLayout();
      }
    } else {
      const currentPathname = this._navigationInterface?.location?.pathname;
      const triggerFromPath =
        this._onboardingAgent.getOnboardingTriggerFromPath(currentPathname);

      if (triggerFromPath?.path) {
        stateBuilder.setEndProcessChain(true);
        stateBuilder.preFetchLayoutByKey(triggerFromPath?.layoutKey);

        const remoteCachedOnboardingAgentSession = this._resumeOnTriggers
          ? await this._onboardingAgent.getRemoteCachedOnboardingAgentSession(
              triggerFromPath
            )
          : undefined;

        if (remoteCachedOnboardingAgentSession) {
          setLayout(triggerFromPath?.layoutKey);

          const rootProperties = this.getResumeSessionModalRootProperties(
            remoteCachedOnboardingAgentSession
          );

          stateBuilder.setModalContent({
            enable: true,
            rootProperties,
            showResumeSessionModal: true
          });
        } else {
          await this._onboardingAgent.launchOnboardingInstance(triggerFromPath);
        }
      } else {
        const cachedOnboardingInstanceSession =
          this._onboardingAgent.getLocalCachedOnboardingAgentSession();
        const serviceIdFromCache =
          cachedOnboardingInstanceSession?.context?.nextService?.serviceId;

        const serviceFromPath = await this._onboardingAgent.getServiceFromPath(
          currentPathname
        );

        if (serviceFromPath?.id && serviceFromPath?.id === serviceIdFromCache) {
          stateBuilder.setEndProcessChain(true);
          await this._onboardingAgent.resumeOnboardingInstance(
            cachedOnboardingInstanceSession
          );
        }
      }
    }
  }
}
