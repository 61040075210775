import { InterfacesInitializerStatePropsType } from '../../types';

export default (initializerState: InterfacesInitializerStatePropsType) => {
  const { enable, themeProvider = {} } =
    initializerState?.manifest?.theme || {};
  const thisThemeProvider = JSON.parse(JSON.stringify(themeProvider));

  function getThemeProviderProperties() {
    if (enable) {
      return thisThemeProvider;
    } else {
      return {};
    }
  }

  return {
    getThemeProviderProperties
  };
};
