import { IAuthTokenService } from '../../../services/authTokenService';
import { isNative } from '../../../services/JWeb';
import { InterfacesInitializerStatePropsType } from '../../types';
import { IExchangeClient } from '../../../clients/shell/exchange';
import { IRefreshClient } from '../../../clients/shell/refresh';
import {
  ISessionService,
  SessionServiceNative,
  SessionServiceWeb
} from '../../../services/session';
import { ISupportSessionService } from '../../../services/supportSession';
import { IdTokenRepository } from '../../../services/session/IdTokenRepository';

type CreateSessionServiceSingletonPropsType =
  InterfacesInitializerStatePropsType & {
    refreshClient: IRefreshClient;
    exchangeClient: IExchangeClient;
    supportSessionService: ISupportSessionService;
    authTokenService: IAuthTokenService;
    idTokenRepository: IdTokenRepository;
  };

let sessionServiceSingleton: ISessionService;

async function _createSessionService(
  options: CreateSessionServiceSingletonPropsType
): Promise<ISessionService> {
  const {
    refreshClient,
    exchangeClient,
    supportSessionService,
    authTokenService,
    idTokenRepository
  } = options;

  let sessionService: ISessionService;
  if (!(await isNative())) {
    sessionService = new SessionServiceWeb({
      refreshClient,
      exchangeClient,
      supportSessionService,
      authTokenService,
      idTokenRepository
    });
  } else {
    sessionService = new SessionServiceNative({
      authTokenService
    });
  }

  return sessionService;
}

export function getSessionServiceSingleton(): ISessionService {
  return sessionServiceSingleton;
}

export default async function initializeSessionServiceSingleton(
  options: CreateSessionServiceSingletonPropsType
): Promise<ISessionService> {
  if (sessionServiceSingleton) return sessionServiceSingleton;

  sessionServiceSingleton = await _createSessionService(options);
  await sessionServiceSingleton.init();

  return sessionServiceSingleton;
}
