import { IAuthzClient } from '../../../clients/stratus/authz';
import { ILogoutClient } from '../../../clients/shell/logout';
import { ISupportSessionService } from '../../../services/supportSession';
import { Stack } from '../../../types/stratus';
import {
  ILogoutService,
  OnecloudLogoutService,
  ShellLogoutService,
  StratusLogoutService
} from '../../../services/session/logoutService';
import AuthenticationProviderEnum from '../../../config/authenticationProviderEnum';
import { ISessionService } from '../../../services/session';
import { HPIDClient } from '../../../clients/hpid';

type CreateLogoutServiceSingletonPropsType = {
  stack: Stack;
  hpidClient: HPIDClient;
  logoutClient: ILogoutClient;
  authzClient: IAuthzClient;
  supportSessionService: ISupportSessionService;
  authenticationProvider: AuthenticationProviderEnum;
  sessionService: ISessionService;
};

let logoutServiceSingleton: ILogoutService;

async function _createLogoutService(
  options: CreateLogoutServiceSingletonPropsType
): Promise<ILogoutService> {
  const {
    stack,
    logoutClient,
    authzClient,
    supportSessionService,
    authenticationProvider,
    sessionService,
    hpidClient
  } = options;

  let logoutService: ILogoutService;

  if (supportSessionService?.isSupportSession()) {
    logoutService = new ShellLogoutService({
      logoutClient
    });
  } else if (authenticationProvider === AuthenticationProviderEnum.coptor) {
    logoutService = new OnecloudLogoutService({
      logoutClient: hpidClient
    });
  } else {
    logoutService = new StratusLogoutService({
      stack,
      authzClient,
      logoutClient,
      sessionService
    });
  }

  return logoutService;
}

export function getLogoutServiceSingleton(): ILogoutService {
  return logoutServiceSingleton;
}

export default async function initializeLogoutServiceSingleton(
  options: CreateLogoutServiceSingletonPropsType
): Promise<ILogoutService> {
  if (logoutServiceSingleton) return logoutServiceSingleton;

  logoutServiceSingleton = await _createLogoutService(options);

  return logoutServiceSingleton;
}
