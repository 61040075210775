import AuthContextEnum from '../AuthContextEnum';

const tenantLevelToAuthContextEnum = (level: number): AuthContextEnum => {
  if (level === 0) return AuthContextEnum.tenantless;
  if (level === 1) return AuthContextEnum.tenant;
  if (level === 2) return AuthContextEnum.subtenant;
  return AuthContextEnum.subtenant;
};

export default tenantLevelToAuthContextEnum;
