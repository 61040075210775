import { DAOItemType, IShellDAO, OptionsType } from '../IShellDAO';
import { FindAllRepositoryItemsParams } from '../types';
import { ReceivedObjectType, RetrieveDataFromStorageParams } from './types';

export interface IShellDAOWithSanitize extends IShellDAO {
  setCallbacks({
    callbackToDeleteItem,
    callbackToAddItem
  }: callbackToItemsType): void;
  getEnhancedMethods(): IShellDAO;
  retrieveDataFromStorage(options: RetrieveDataFromStorageParams);
}

type callbackToItemsType = {
  callbackToDeleteItem?: (key: string) => void;
  callbackToAddItem?: (key: string, data: ReceivedObjectType['data']) => void;
};

export class WithTTL implements IShellDAOWithSanitize {
  private _shellDAO: IShellDAO;
  private callbackToAddItem: callbackToItemsType['callbackToAddItem'] = () =>
    '';
  private callbackToDeleteItem: callbackToItemsType['callbackToDeleteItem'] =
    () => '';

  constructor(storage: IShellDAO) {
    this._shellDAO = storage;
  }

  setCallbacks = ({
    callbackToDeleteItem,
    callbackToAddItem
  }: callbackToItemsType): void => {
    this.callbackToDeleteItem = callbackToDeleteItem;
    this.callbackToAddItem = callbackToAddItem;
  };

  save = (key: string, value: unknown, options?: OptionsType): void => {
    const createdAt = new Date();
    const valueWithMetadata = {
      value: value,
      options,
      createdAt: createdAt
    };
    this._shellDAO.save(key, JSON.stringify(valueWithMetadata));

    if (options?.ttl) this.callbackToAddItem(key, valueWithMetadata);
  };

  saveAll = (key: string, values: unknown, options?: OptionsType): void => {
    // TODO: Create other schema to save the data as bulk.
    this.save(key, values, options);
  };

  deleteAll = (keys: string[]): void => {
    keys.forEach((key) => this.callbackToDeleteItem(key));
    return this._shellDAO.deleteAll(keys);
  };

  delete = (key: string): boolean => {
    this.callbackToDeleteItem(key);
    return this._shellDAO.delete(key);
  };

  findOne = (key: string): DAOItemType['value'] => {
    const myItem = this._shellDAO.findOne(key);
    if (myItem === undefined || myItem == null) return '';
    const parsedItem = JSON.parse(myItem);
    return parsedItem.value;
  };

  findAll = ({ prefixKey }: FindAllRepositoryItemsParams): DAOItemType[] => {
    const items = this._shellDAO.findAll({ prefixKey });
    const listToBeSent = [];
    items.forEach((myItem) => {
      if (myItem !== null) {
        const value = JSON.parse(myItem.value).value;
        const key = myItem.key;
        listToBeSent.push({ key, value });
      }
    });
    return listToBeSent;
  };

  getEnhancedMethods = (): IShellDAO => {
    return {
      findOne: this.findOne,
      findAll: this.findAll,
      save: this.save,
      saveAll: this.saveAll,
      delete: this.delete,
      deleteAll: this.deleteAll
    };
  };

  // TODO: maybe we should to add a flag to retrieve or not the data from storage?
  retrieveDataFromStorage = ({
    prefixKey
  }: RetrieveDataFromStorageParams): void => {
    const items = this._shellDAO.findAll({ prefixKey });
    items.forEach((myItem) => {
      if (myItem === null || myItem === undefined) return;
      try {
        const dataParsed = JSON.parse(myItem.value);
        const ttl = dataParsed.options?.ttl;
        const value = dataParsed.value;
        const createdAt = dataParsed.createdAt;
        this.callbackToAddItem(myItem.key, {
          value,
          options: { ttl },
          createdAt
        });
      } catch (err) {
        // console.error('Data with no JSON.parse.');
      }
    });
  };
}
