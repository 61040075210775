import MicrofrontendRouterOperationStateBuilder from '../../MicrofrontendRouterOperationStateBuilder';
import matchPath from '../../../../../utils/matchPath';
import * as T from './types';

export default class LoginOperation implements T.IMicrofrontendRouterOperation {
  private navigationInterface: T.LoginOperationDependenciesType['navigationInterface'];
  private loginPathList = ['/login', '/loggedin', '/loggedout'];

  constructor(dependencies: T.LoginOperationDependenciesType) {
    this.navigationInterface = dependencies.navigationInterface;
  }

  private shouldProcess(): boolean {
    const pathToCompare = this.navigationInterface?.location?.pathname;
    return matchPath(this.loginPathList, { exact: true, pathToCompare });
  }

  async process(
    stateBuilder: MicrofrontendRouterOperationStateBuilder
  ): Promise<void> {
    if (this.shouldProcess()) {
      stateBuilder.setContent({
        enable: false,
        isLogin: true
      });
      stateBuilder.setEndProcessChain(true);
    }
  }
}
