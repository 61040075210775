import { JarvisWebHttpClient } from '@jarvis/web-http';
import { convertTenantStrategyToSessionAPITenantStrategy } from '../SessionAPIUtils';
import IExchangeClient, {
  ExchangeTenantTokenDTOType,
  ExchangeTenantTokenRequestType,
  ExchangeTenantTokenResponseType
} from './IExchangeClient';

export default class ExchangeV3Client implements IExchangeClient {
  private jarvisWebHttpInstance: JarvisWebHttpClient;
  private apiName = '/api/session';
  private apiVersion = '/v3';
  private exchangeTokenTenantsEndpoint = '/exchangetoken';
  private _baseUrl: string;

  constructor(baseUrl: string) {
    this.jarvisWebHttpInstance = new JarvisWebHttpClient();
    this._baseUrl = `${baseUrl}${this.apiName}${this.apiVersion}`;
  }

  public async exchangeToken(
    exchangeTenantTokenDTOType: ExchangeTenantTokenDTOType
  ): Promise<ExchangeTenantTokenResponseType> {
    const payload: ExchangeTenantTokenRequestType = {
      tenantId: exchangeTenantTokenDTOType.tenantId,
      tokenType: convertTenantStrategyToSessionAPITenantStrategy(
        exchangeTenantTokenDTOType.tenantStrategy
      ),
      shellSessionId: exchangeTenantTokenDTOType.shellSessionId
    };
    const { data } = await this.jarvisWebHttpInstance.post({
      url: `${this._baseUrl}${this.exchangeTokenTenantsEndpoint}`,
      headers: {
        Authorization: `Bearer ${exchangeTenantTokenDTOType.accessToken}`
      },
      data: payload
    });
    return data as ExchangeTenantTokenResponseType;
  }
}
