import * as cookies from '../utils/cookies';
import getCurrentRoute from '../utils/getCurrentRoute';
import { getStratusAccessToken } from '../utils/getStratusAccessToken';
import { stackValidator } from '../utils/stack';

export default {
  cookies,
  getCurrentRoute,
  getStratusAccessToken,
  stackValidator
};
