import { UserContextEnum } from '../../../interface/types';
import AuthContextEnum from '../AuthContextEnum';

const userContextEnumToAuthContextEnum = (
  userContext: UserContextEnum
): AuthContextEnum => {
  if (userContext === UserContextEnum.orgless)
    return AuthContextEnum.tenantless;
  if (userContext === UserContextEnum.organization)
    return AuthContextEnum.tenant;
  if (userContext === UserContextEnum.customer)
    return AuthContextEnum.subtenant;
  return null;
};

export default userContextEnumToAuthContextEnum;
