import { StratusClient } from '@jarvis/web-stratus-client/dist/src/clients/stratus-client';
import { Stack } from '../../../types/stratus';
import { getStratusBaseUrl } from '../../stackUtils';
import * as T from './types';

export default class UserMgtClient extends StratusClient {
  apiName = 'UserMgt';
  apiVersion = 'v3';
  usersMeEndpoint = '/users/me';

  public static getBaseUrl(stack: Stack): string {
    return `${getStratusBaseUrl(stack)}/v3/usermgtsvc`;
  }

  public async getUsersMe(options?: {}): Promise<T.UserType> {
    const response = await this.jarvisWebHttpInstance.get({
      url: this.usersMeEndpoint
    });
    return response.data as T.UserType;
  }
}
