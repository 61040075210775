import bindAllMethods from '../../../utils/bindAllMethods';
import { ILogoutClient } from '../../../clients/shell/logout';
import ILogoutService from './ILogoutService';

export type ShellLogoutServiceParameters = {
  logoutClient: ILogoutClient;
};

// This service is current being user in Coptor and in AST.
export default class ShellLogoutService implements ILogoutService {
  private _logoutClient: ILogoutClient;

  constructor({ logoutClient }: ShellLogoutServiceParameters) {
    this._logoutClient = logoutClient;
    bindAllMethods(this);
  }

  public async logout(): Promise<void> {
    const redirectTo = this._logoutClient.configureLogoutUrl(
      location.origin,
      'shell'
    );
    return new Promise(() => {
      window.location.href = redirectTo;
    });
  }
}
