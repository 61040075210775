import matchPath from '../../utils/matchPath';
import * as T from './types';

class RoutesService {
  private _routes: T.RouteType[] = [];
  private _defaultLayoutKey?: string;

  private recursiveFind(
    callback: (route: T.RouteType) => boolean,
    route = this._routes
  ) {
    let result: T.RouteType;

    route.forEach((thisRoute) => {
      if (!result) {
        if (callback(thisRoute)) {
          result = thisRoute;
        } else if (thisRoute?.subRoutes) {
          this.recursiveFind(callback, thisRoute?.subRoutes);
        }
      }
    });

    return result;
  }

  setDependencies(dependencies: T.RoutesServiceDependenciesType): void {
    this._defaultLayoutKey = dependencies?.defaultLayoutKey;

    const recursiveInjectRoutesDefaultValues = (routes: T.RouteType[]) => {
      if (!Array.isArray(routes)) return;
      return routes?.map?.((route) => {
        const layoutKey = (() => {
          if (route?.layoutKey === false) return false;
          return route?.layoutKey || this._defaultLayoutKey;
        })();

        return {
          ...route,
          layoutKey,
          subRoutes: recursiveInjectRoutesDefaultValues(route?.subRoutes)
        };
      });
    };

    this._routes =
      recursiveInjectRoutesDefaultValues(dependencies?.routes) || [];
  }

  getRoutes(): T.RouteType[] {
    return this._routes;
  }

  findRouteByKey(key: string): T.RouteType {
    if (!key) return;
    return this.recursiveFind((route) => route?.key === key);
  }

  findRouteByPath(path: string | string[]): T.RouteType {
    if (!path) return;

    return this.recursiveFind((route) => {
      return matchPath(route?.path, {
        pathToCompare: path,
        exact:
          typeof route?.exact === 'boolean' ? route?.exact : route?.path === '/'
      });
    });
  }
}

const routesService = new RoutesService();

export default routesService;
