import TenantHandlerService from '../../../services/tenantHandler/TenantHandlerService';
import { TenantRepository } from '../../../services/tenantHandler/TenantRepository';
import { stackValidator } from '../../../utils/stack';
import { AuthProviderV2Type } from './types';
import { InterfacesInitializerStatePropsType } from '../../types';
import { TenantHandlerParamsType } from '../../../services/tenantHandler/strategy/types';
import { TenantStrategyEnum } from '../../../services/tenantHandler/strategy/strategy';
import TenantHandlerInterfaceV1 from './TenantHandlerInterfaceV1';

type CreateTenantHandlerSingletonPropsType =
  InterfacesInitializerStatePropsType & {
    repository: TenantRepository;
    authProvider: AuthProviderV2Type;
  };

let tenantHandlerSingleton: ReturnType<typeof _createTenantHandler>;
let tenantHandlerInterfaceV1: TenantHandlerInterfaceV1;

function _createTenantHandler(options: CreateTenantHandlerSingletonPropsType) {
  const { manifest, repository, authProvider, defaultAssetReferences } =
    options;
  const tenantHandler = manifest?.services?.tenantHandler;
  const _stack = stackValidator(manifest.portal.stack);

  const { tenantHandlerList, globalTenantHandler, enable } =
    tenantHandler || {};

  const result = new TenantHandlerService({
    tenantHandlerList,
    stack: _stack,
    globalTenantHandler,
    tenantRepository: repository,
    enable,
    authProvider,
    tenantSelectorAssetReference: defaultAssetReferences?.['tenant-selector']
  });

  return result;
}

export function getTenantHandlerSingleton(): TenantHandlerService {
  return tenantHandlerSingleton;
}

export function getTenantHandlerInterface(): TenantHandlerInterfaceV1 {
  return tenantHandlerInterfaceV1;
}

export default async function initializeTenantHandlerSingleton(
  options: CreateTenantHandlerSingletonPropsType
): Promise<TenantHandlerService> {
  if (tenantHandlerSingleton) return tenantHandlerSingleton;

  // TODO: This behavior is temporary to keep the retrocompatibility of the OrgSelector interface
  adaptOrgAndCustomerSelectorIntoTenantHandler(options);

  tenantHandlerSingleton = _createTenantHandler(options);
  tenantHandlerInterfaceV1 = new TenantHandlerInterfaceV1({
    tenantHandlerService: tenantHandlerSingleton
  });

  return tenantHandlerSingleton;
}

// TODO: Retrocompatibility behavior
function adaptOrgAndCustomerSelectorIntoTenantHandler(
  options: CreateTenantHandlerSingletonPropsType
) {
  const DEFAULT_TENANT_HANDLER_KEY = 'default';
  const { manifest } = options;
  const isTenantHandlerActive = !!manifest?.services?.tenantHandler?.enable;

  if (isTenantHandlerActive) return;

  const orgSelectorSettings = manifest?.navigation?.header?.domainSelector;

  if (orgSelectorSettings?.enable) {
    const orgSelectorToTenantHandlerSettings: TenantHandlerParamsType = {
      key: DEFAULT_TENANT_HANDLER_KEY,
      strategy: TenantStrategyEnum.stratusOrganization,
      options: {
        fallback: orgSelectorSettings?.fallback,
        filter: orgSelectorSettings?.filter
      }
    };

    manifest.services['tenantHandler'] = {
      enable: true,
      globalTenantHandler: orgSelectorToTenantHandlerSettings.key,
      tenantHandlerList: [orgSelectorToTenantHandlerSettings]
    };

    // TODO: This is very fragile, the portal could change the assetReference to any other name.
    const customerSelectorSettings =
      manifest?.navigation?.header?.widgets?.find(
        (w) =>
          w.assetReference === '@jarvis/react-shell-customer-selector' &&
          w.enable
      );

    // Customer Selector Behavior
    if (customerSelectorSettings?.enable) {
      const customerSelectorToTenantHandlerSettings: TenantHandlerParamsType = {
        strategy: TenantStrategyEnum.stratusCustomer
      };

      orgSelectorToTenantHandlerSettings['subTenant'] =
        customerSelectorToTenantHandlerSettings;
    }
  }
}
