import { internalLogger } from '../../../interface/v1/logger';
import { getJWeb, isNative, JWebErrorHandler } from '..';
import { LaunchServiceOptions, ServiceList, Service } from './types';

export async function getAvailableServices(): Promise<Service[]> {
  if (!(await isNative())) return;

  const ServiceRouting = (await getJWeb())?.Plugins?.ServiceRouting;
  const serviceList = await ServiceRouting?.getServices();

  internalLogger?.log?.('onboarding-native-getAvailableServices: ', {
    serviceList
  });

  return JWebErrorHandler<ServiceList>(serviceList)?.services;
}

export async function getNativeServiceLaunchOptions(): Promise<LaunchServiceOptions> {
  if (!(await isNative())) return;
  const ServiceRouting = (await getJWeb())?.Plugins?.ServiceRouting;
  const launchServiceOptionsResult =
    await ServiceRouting?.getServiceInstanceLaunchOptions();

  internalLogger?.log?.('onboarding-native-getNativeServiceLaunchOptions: ', {
    launchServiceOptionsResult
  });

  const launchServiceOptions = JWebErrorHandler<LaunchServiceOptions>(
    launchServiceOptionsResult
  );

  return launchServiceOptions;
}

export async function findNativeService(serviceId: string): Promise<Service> {
  if (!(await isNative())) return;
  const nativeStages = await getAvailableServices();

  const service = nativeStages?.find?.((r) => r.id === serviceId);

  internalLogger?.log?.('onboarding-native-findNativeService: ', {
    service
  });

  return service;
}

export async function launchNativeService(
  launchServiceOptions: LaunchServiceOptions
): Promise<void> {
  if (!(await isNative())) return;
  const ServiceRouting = (await getJWeb())?.Plugins?.ServiceRouting;

  const serviceInstance = await ServiceRouting.launchService(
    launchServiceOptions
  );

  internalLogger?.log?.('onboarding-native-launchNativeService: ', {
    serviceInstance
  });
}

export async function closeNativeOnboardingInstance(data?: {
  resultData: {
    appSessionId: string;
    serviceId: string;
    result: {
      result: string;
      xCorrelationId: string;
    };
    errorInfo?: {
      errorCode?: string;
    };
  };
}): Promise<void> {
  if (!(await isNative())) return;
  const ServiceRouting = (await getJWeb())?.Plugins?.ServiceRouting;

  return new Promise((resolve, reject) => {
    try {
      internalLogger?.log?.('onboarding-native-closeNativeOnboardingInstance');

      // It should not be resolved since this call should close the webview that load this instance.
      ServiceRouting?.closeServiceInstance(data);
    } catch (error) {
      reject(error);
    }
  });
}
