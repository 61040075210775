import { TenantHandlerParamsType } from '../services/tenantHandler/strategy/types';
import { CriterionType } from '../interface/v1/Criterion/types';
import { OnboardingDirectorContextType } from '../interface/v1/MicrofrontendRouter/operations/OnboardingAgentOperation/OnboardingAgent/clients/OnboardingDirector/types';
import AuthenticationProviderEnum from '../config/authenticationProviderEnum';

export type OnboardingRoutes = {
  path: string;
  serviceId: string;
  assetReference: string;
};

export type IdleListenersType = {
  mousemove?: boolean;
  mousedown?: boolean;
  keypress?: boolean;
};

export type IdleType = {
  time: number;
  listeners: IdleListenersType;
};

export type NavigationStylizationType = {
  logo?: string;
  backgroundColor?: string;
};

export type NavigationRoutesType = {
  key?: string;
  label?: string;
  path?: string | string[];
  type?: string;
  layoutKey?: string | false;
  criterionKey?: string;
  assetReference?: string;
  iconReference?: string;
  properties?: Record<string, any>;
  subRoutes?: NavigationRoutesType[];
  translationKey?: string;
  public?: boolean;
  hidden?: boolean;
  breadcrumbKey?: string | false;
  tenantHandlerOverride?: string;
  exact?: boolean;
};

export type ManifestPortalType = {
  appName?: string;
  basePath: string;
  fallbackRoute?: string;
  stack: string;
  clientId?: string;
  requiredScopes?: string[];
};

export type OnboardingStages = {
  layoutKey?: string | false;
  path?: string | string[];
  serviceId?: string;
  public?: boolean;
  assetReference?: string;
  criterionKey?: string;
  properties?: Record<string, unknown>;
};

export type ServiceOnboardingTriggerType = {
  path: string;
  onboardingContext: OnboardingDirectorContextType;
  layoutKey?: string;
  criterionKey?: string;
  fallbackKeys?: {
    failedToLaunch: string;
  };
};

export type ServiceOnboardingSideBarType = {
  assetReference?: string;
  defaultVisible?: boolean;
};

export type ServicesOnboardingType = {
  enable?: boolean;
  userOnboardingPath?: string;
  postOnboardRedirect?: string;
  assetReference?: string;
  resumeOnTriggers?: boolean;
  clientId?: string;
  sideBar?: ServiceOnboardingSideBarType;
  triggers?: ServiceOnboardingTriggerType[];
  stages?: OnboardingStages[];
};

export type ServicesLoginType = {
  enable?: boolean;
  authenticationProvider?: AuthenticationProviderEnum;
  assetReference?: string;
  emailGathering?: boolean;
  postLoginRedirect?: string;
  preLoginValidation?: {
    enable: boolean;
    assetReference: string;
    properties?: {
      onSuccessValidationEvent?: string;
    };
  };
};

export type ManifestFeatureFlagClientType = {
  key: string;
  clientId: string;
};

export type ManifestServiceLocalizationLanguageType = Record<string, string[]>;

export type ManifestServiceLocalizationFallbackType = {
  [key: string]: {
    language: string;
    country: string;
  };
};

export type ManifestServiceLocalizationType = {
  enable?: boolean;
  removeLocaleUrl?: boolean;
  languages?: ManifestServiceLocalizationLanguageType;
  fallbacks?: ManifestServiceLocalizationFallbackType;
};

export type ManifestServiceAnalyticsType = {
  enable?: boolean;
  assetType?: string;
  telemetryAPIkey?: string;
  valveControllerAPIkey?: string;
};

export type ManifestServiceFeatureFlagsType = {
  enable: boolean;
  clients: ManifestFeatureFlagClientType[];
};

// FIXME: Change enabled to enable to keep the pattern
export type ManifestServiceSplunkType = {
  enabled?: boolean;
  rumAuth?: string;
};

export enum EnumAllowedLogType {
  'debug' = 'debug',
  'error' = 'error',
  'log' = 'log',
  'warn' = 'warn'
}

export type LoggerAllowedTypes = { [key in EnumAllowedLogType]?: boolean };

export type ManifestServiceLoggerType = {
  enable?: boolean;
  allowedExpressions?: string[];
  allowedTypes?: LoggerAllowedTypes;
};

export type ManifestServiceUserActivity = {
  enable?: boolean;
  idle?: {
    time?: number;
  };
};

export type ManifestServiceEntitlementsType = {
  enabled?: boolean;
};

export type ManifestServiceAccessControl = {
  enabled?: boolean;
};

export type ManifestServiceOptimizelyType = {
  enable?: boolean;
  snippetId?: string;
};

export type ASTType = {
  enable?: boolean;
};

export type ManifestBreadcrumbServiceInitialBreadcrumbSettingsType = {
  key?: string;
  routeKey?: string;
  useTenantName?: boolean;
  text?: string;
  translationKey?: string;
  url?: string;
};

export type ManifestBreadcrumbServiceSettingsType = {
  key: string;
  initialBreadcrumbs: ManifestBreadcrumbServiceInitialBreadcrumbSettingsType[];
};

export type ManifestBreadcrumbService = {
  enable: boolean;
  defaultSettingsKey?: string;
  settingsList?: ManifestBreadcrumbServiceSettingsType[];
};

export type ManifestServicesType = {
  ast?: ASTType;
  onboarding?: ServicesOnboardingType;
  tenantHandler?: ServicesTenantHandlerType;
  login?: ServicesLoginType;
  idle?: IdleType;
  logger?: ManifestServiceLoggerType;
  localization?: ManifestServiceLocalizationType;
  analytics?: ManifestServiceAnalyticsType;
  featureFlags?: ManifestServiceFeatureFlagsType;
  splunk?: ManifestServiceSplunkType;
  userActivity?: ManifestServiceUserActivity;
  entitlements?: ManifestServiceEntitlementsType;
  accessControl?: ManifestServiceAccessControl;
  optimizely?: ManifestServiceOptimizelyType;
  breadcrumb?: ManifestBreadcrumbService;
};

export type NavigationHeaderBellType = {
  enable?: boolean;
  assetReference?: string;
  importKey?: string;
  properties?: object;
};

export type NavigationHeaderDomainSelectorFilterType = {
  role?: string;
  tenantType?: string[];
};

export type NavigationHeaderdomainSelectorType = {
  enable?: boolean;
  fallback?: string;
  filter?: NavigationHeaderDomainSelectorFilterType;
};

export type NavigationHeaderAvatarMenuListItemType = {
  key: string;
  label?: string;
  path?: string;
};

export type NavigationHeaderAvatarMenuType = {
  menuList?: NavigationHeaderAvatarMenuListItemType[];
};

export type NavigationHeaderType = {
  stylization?: Record<string, unknown>;
  bell?: NavigationHeaderBellType;
  avatarMenu?: NavigationHeaderAvatarMenuType;
  domainSelector?: NavigationHeaderdomainSelectorType;
  widgets?: NavigationWidgetType[];
};

export type ManifestNavigationType = {
  stylization?: NavigationStylizationType;
  header?: NavigationHeaderType;
  routes?: NavigationRoutesType[];
  defaultLayoutKey?: string;
};

export type ManifestThemeType = {
  enable?: boolean;
  themeProvider?: Record<string, any>;
};

export type ManifestLayoutType = {
  key: string;
  assetReference: string;
  properties?: Record<string, unknown>;
};

export type ManifestType = {
  adapter?: {
    disableRouter?: boolean;
  };
  portal?: ManifestPortalType;
  services?: ManifestServicesType;
  layouts?: ManifestLayoutType[];
  theme?: ManifestThemeType;
  navigation?: ManifestNavigationType;
  criterions?: CriterionType<any>[];
};

export type ServicesTenantHandlerType = {
  enable?: boolean;
  globalTenantHandler?: string;
  tenantHandlerList?: TenantHandlerParamsType[];
};

export type NavigationWidgetType = {
  enable?: boolean;
  assetReference?: string;
  importKey?: string;
  properties?: Record<string, any>;
  loader?: {
    height?: string | number;
    width?: string | number;
    circle?: boolean;
  };
};
