import { TenantHandlerService } from '../tenantHandler';
import TenantLevelEnum from '../tenantHandler/TenantLevelEnum';
import AuthContextEnum from './AuthContextEnum';
import { AuthTokenRepository } from './AuthTokenRepository';
import IAuthTokenService from './IAuthTokenService';
import { AuthTokenDependenciesType, AuthTokenStorageType } from './types';

export default class AuthTokenService implements IAuthTokenService {
  private _authTokenRepository: AuthTokenRepository;
  private _tenantHandler: TenantHandlerService;

  constructor({
    authTokenRepository,
    tenantHandler
  }: AuthTokenDependenciesType) {
    this._authTokenRepository = authTokenRepository;
    this._tenantHandler = tenantHandler;
  }

  public getCurrentContext(): AuthContextEnum {
    return this._tenantHandler.getCurrentContext();
  }

  public getToken(authContext?: AuthContextEnum): AuthTokenStorageType {
    let _authContext = authContext;
    if (!_authContext) _authContext = this._tenantHandler.getCurrentContext();
    const sufix = this.getSuffix(_authContext);
    return this._authTokenRepository.findOne(sufix);
  }

  public setToken(token: string, authContext: AuthContextEnum): void {
    if (!token) {
      console.error('You cannot set an empty token');
      throw new Error('You cannot set an empty token');
    }
    const authToken = { token, context: authContext } as AuthTokenStorageType;
    this._clearTokensFromUpperLevels(authContext);
    const sufix = this.getSuffix(authContext);
    this._authTokenRepository.save(sufix, authToken);
  }

  public deleteToken(authContext: AuthContextEnum): void {
    const sufix = this.getSuffix(authContext);
    this._authTokenRepository.delete(sufix);
  }

  public clear(): void {
    this._authTokenRepository.clear();
  }

  public getSuffix(authContext: AuthContextEnum): string {
    if (!this._tenantHandler.isEnabled()) return '';
    let sufix: string;
    switch (authContext) {
      case AuthContextEnum.tenantless:
        sufix = '';
        break;
      case AuthContextEnum.tenant:
        sufix = this._tenantHandler.getTenantSuffix(TenantLevelEnum.tenant);
        break;
      case AuthContextEnum.subtenant:
        sufix = this._tenantHandler.getTenantSuffix(TenantLevelEnum.subTenant);
        break;
      default:
        sufix = this._tenantHandler.getTenantSuffix();
        break;
    }
    return sufix;
  }

  // TODO: Need to be here until tenantHandler be the only way to deal with tenants
  private _clearTokensFromUpperLevels(authContext: AuthContextEnum) {
    if (this._tenantHandler?.isEnabled()) return;
    if (authContext === AuthContextEnum.subtenant) {
      return;
    }
    if (authContext === AuthContextEnum.tenant) {
      this.deleteToken(AuthContextEnum.subtenant);
    } else if (authContext === AuthContextEnum.tenantless) {
      this.deleteToken(AuthContextEnum.tenant);
      this.deleteToken(AuthContextEnum.subtenant);
    }
  }
}
