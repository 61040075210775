import { Publisher, Subscriber, SubscriptionHandle } from '@jarvis/jweb-core';
import { getRandomUUID } from '../../utils/crypto';
import EventNames from '../../config/eventNames';
import { getJWeb, isNative, JWebErrorHandler } from '../JWeb';
import * as T from './types';
import isObject from '../../utils/isObject';

const publisherId = 'shell-commons-event-service-' + getRandomUUID();

const nativeEventPublisherPromise: Promise<Publisher> = (async () => {
  const jweb = await getJWeb();
  if (await isNative()) {
    const nativeEventService = jweb.Plugins.EventService;

    const nativeEventPublisher = await nativeEventService.createPublisher(
      publisherId
    );

    return JWebErrorHandler<Publisher>(nativeEventPublisher);
  }
})();

const nativeSubscriberPromise = (async () => {
  const JWeb = await getJWeb();
  if (await isNative()) {
    const EventService = JWeb?.Plugins?.EventService;

    return await EventService.createSubscriber().then((v) =>
      JWebErrorHandler<Subscriber>(v)
    );
  }
})();

class EventService implements T.EventServiceType {
  private _eventListeners: T.EventServiceListenersType = new Map();
  eventNames = EventNames;

  constructor() {
    this.subscribe = this.subscribe.bind(this);
    this.publish = this.publish.bind(this);
  }

  async subscribe(
    name: string,
    action: T.EventServiceCallBackType
  ): Promise<T.ListenerHandlerType> {
    if (await isNative()) {
      return this._nativeAddListener(name, action);
    } else {
      return this._webAddListener(name, action);
    }
  }

  async publish(
    name: string,
    eventData: T.EventServiceValueType['eventData']
  ): Promise<void> {
    const sanitizedEventData = isObject(eventData) ? eventData : {};

    if (await isNative()) {
      await this._nativePublish(name, sanitizedEventData);
    } else {
      this._webPublish(name, sanitizedEventData);
    }
  }

  private _getWebActionMap(name: string) {
    let actionMap = this._eventListeners.get(name);
    if (!actionMap) {
      actionMap = new Map();
      this._eventListeners.set(name, actionMap);
    }

    return actionMap;
  }

  private _webAddListener(
    eventName: string,
    action: T.EventServiceCallBackType
  ): T.ListenerHandlerType {
    const actionMap = this._getWebActionMap(eventName);
    actionMap.set(action, ({ eventData }) => {
      action({
        eventData,
        eventName,
        publisherId
      });
    });

    return {
      unsubscribe: async () => {
        actionMap.delete(action);
      }
    };
  }

  private async _nativeAddListener(
    eventName: string,
    action: T.EventServiceCallBackType
  ): Promise<T.ListenerHandlerType> {
    const nativeSubscriber = await nativeSubscriberPromise;

    const nativeListenerHandler = JWebErrorHandler<SubscriptionHandle>(
      await nativeSubscriber.subscribe({ eventName }, ({ eventData }) => {
        action({
          eventData,
          eventName,
          publisherId
        });
      })
    );

    return {
      unsubscribe: async () => {
        await nativeListenerHandler.unsubscribe();
      }
    };
  }

  private async _webPublish(
    eventName: string,
    eventData: T.EventServiceValueType
  ): Promise<void> {
    const actionMap = this._getWebActionMap(eventName);

    actionMap.forEach((action) => {
      action({ eventData, eventName, publisherId });
    });
  }

  private async _nativePublish(
    name: string,
    event: T.EventServiceValueType
  ): Promise<void> {
    const nativeEventPublisher = await nativeEventPublisherPromise;

    await nativeEventPublisher.publish(name, event);
  }
}

const eventService = new EventService();

export default eventService;
