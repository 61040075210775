export default function bindAllMethods(object: Record<string, any>): void {
  if (typeof object === 'object') {
    const allKeys = Object.getOwnPropertyNames(Object.getPrototypeOf(object));

    allKeys?.forEach?.((key) => {
      if (object?.[key] instanceof Function && key !== 'constructor') {
        object[key] = object[key].bind(object);
      }
    });
  }
}
