import { InterfacesInitializerStatePropsType } from '../../types';
import { ISessionService } from '../../../services/session';
import { AuthProviderV2Type } from '../../v2/auth';
import {
  IUserOnboardingService,
  OnecloudUserOnboardingService,
  StratusUserOnboardingService
} from '../../../services/userOnboardingService';
import AuthenticationProviderEnum from '../../../config/authenticationProviderEnum';
import UserInterfaceV1, { UserInterfaceV1Type } from './UserInterfaceV1';
import AccountsClient from '../../../clients/stratus/accountmgtsvc/AccountsClient';
import { PersonClient } from '../../../clients/stratus/tntCommonResourceRegistry';
import { stackValidator } from '../../../utils/stack';
import OnecloudOauthClient from '../../../clients/oneCloud/oauth/OnecloudOauthClient';
import OnecloudRegistryRelationshipClient from '../../../clients/oneCloud/registryRelationship/OnecloudRegistryRelationshipClient';

type CreateUserInterfaceV1SingletonPropsType =
  InterfacesInitializerStatePropsType & {
    sessionService: ISessionService;
    authProvider: AuthProviderV2Type;
    authenticationProvider: AuthenticationProviderEnum;
  };

let userOnboardingService: IUserOnboardingService;
let userInterfaceV1: UserInterfaceV1;

function _createUserOnboardingService(
  options: CreateUserInterfaceV1SingletonPropsType
) {
  const { sessionService, authProvider, manifest, authenticationProvider } =
    options;

  const _stack = stackValidator(manifest.portal.stack);

  if (authenticationProvider === AuthenticationProviderEnum.coptor) {
    const onecloudOauthClient = new OnecloudOauthClient({
      stack: _stack,
      authProvider
    });
    const onecloudRegistryRelationshipClient =
      new OnecloudRegistryRelationshipClient({
        stack: _stack,
        authProvider
      });

    userOnboardingService = new OnecloudUserOnboardingService({
      sessionService,
      onecloudOauthClient,
      onecloudRegistryRelationshipClient
    });
  } else {
    const stratusAccountMgtSvcClient = new AccountsClient(
      AccountsClient.getBaseUrl(_stack),
      authProvider
    );
    const stratusTntResourceRegistryPersonClient = new PersonClient(
      PersonClient.getBaseUrl(_stack),
      authProvider
    );
    userOnboardingService = new StratusUserOnboardingService({
      sessionService,
      stratusAccountMgtSvcClient,
      stratusTntResourceRegistryPersonClient
    });
  }
}

export function getUserInterfaceV1(): UserInterfaceV1 {
  return userInterfaceV1;
}

export default async function initializeUserInterfaceV1(
  options: CreateUserInterfaceV1SingletonPropsType
): Promise<UserInterfaceV1Type> {
  if (userInterfaceV1) return userInterfaceV1.getInterface();
  _createUserOnboardingService(options);

  userInterfaceV1 = new UserInterfaceV1({ userOnboardingService });

  return userInterfaceV1.getInterface();
}
