import { AuthzClient, IAuthzClient } from '../../clients/stratus/authz';
import {
  ExchangeV3Client,
  IExchangeClient
} from '../../clients/shell/exchange';
import { ILogoutClient, LogoutClient } from '../../clients/shell/logout';
import { IRefreshClient, RefreshV3Client } from '../../clients/shell/refresh';
import { ShellLocalStorageDAOImpl } from '../../dao/ShellLocalStorageDAOImpl';
import { AuthTokenRepository } from '../../services/authTokenService';
import { ScopeRepository, ScopeService } from '../../services/scope';
import {
  ISupportSessionService,
  SupportSessionService
} from '../../services/supportSession';
import { TenantRepository } from '../../services/tenantHandler';
import { Stack } from '../../types/stratus';
import { InterfacesInitializerStatePropsType } from '../types';
import authV2 from '../v2/auth';
import accessControlImport from './accessControl';
import analyticsImport from './analytics';
import auth from './auth';
import initializeAuthTokenSingleton from './AuthToken';
import initializeCriterionSingleton from './Criterion/singleton';
import entitlementsImport from './entitlements';
import Fallback from './Fallback';
import Layout from './Layout';
import localizationImport from './localization';
import loggerImport from './logger';
import createMicrofrontendRouterSingleton from './MicrofrontendRouter/createMicrofrontendRouterSingleton';
import navigationImport from './navigation';
import optimizely from './optimizely';
import OrgSelector from './orgSelector';
import serviceRoutingImport from './serviceRouting';
import initializeSessionServiceSingleton from './sessionInterface/initializeSessionService';
import Store from './store';
import initializeTenantHandlerSingleton, {
  getTenantHandlerInterface
} from './TenantHandler';
import themeImport from './theme';
import userActivityImport from './userActivity';
import userSessionImport from './userSession';
import appContextSingleton from '../../services/appContext';
import { setAuthTokenService } from '../../utils/getStratusAccessToken';
import EventInterface from './events';
import { EntitlementRepository } from '../../services/entitlements/EntitlementRepository';
import Breadcrumb from './breadcrumb';
import Routes from './routes';
import routesService from '../../services/RoutesService';
import breadcrumbService from '../../services/breadcrumbService';
import initializeUserInterfaceV1 from './userInterface';
import { isNative as JWebIsNative } from '../../services/JWeb';
import { IProviderClient, ProviderClient } from '../../clients/shell/provider';
import AuthenticationProviderEnum from '../../config/authenticationProviderEnum';
import initializeLogoutServiceSingleton from './sessionInterface/initializeLogoutService';
import initializeSessionInterfaceV1 from './sessionInterface';
import initializeLoginServiceSingleton from './sessionInterface/initializeLoginService';
import { HPIDClient } from '../../clients/hpid';
import { IdTokenRepository } from '../../services/session/IdTokenRepository';
import UserMgtClient from '../../clients/stratus/usersMgt/UserMgtClient';
import initializeUserServiceSingleton from './userInterface/initializeUserService';
import initializeFeatureFlagServiceSingleton from './featureFlags/initializeFeatureFlagService';
import initializeFeatureFlagInterfaceV1 from './featureFlags';
import initializeAppInterfaceV1 from './app';

export default async function initializeShellInterfaceV1(
  initializerState: InterfacesInitializerStatePropsType
) {
  const adapter = initializerState?.manifest?.adapter;
  const appContext = appContextSingleton;

  const stack: Stack = Stack[initializerState?.manifest?.portal?.stack];
  const clientId = initializerState?.manifest?.portal?.clientId;
  const authenticationProvider =
    initializerState?.manifest?.services?.login?.authenticationProvider ||
    AuthenticationProviderEnum.authz;
  const isNative = await JWebIsNative();
  const appName = initializerState?.manifest?.portal?.appName;

  //Initializing repositories
  const shellLocalStorageDAO = new ShellLocalStorageDAOImpl();
  const scopeRepository = new ScopeRepository({
    shellDAO: shellLocalStorageDAO
  });
  const authTokenRepository = new AuthTokenRepository({
    shellDAO: shellLocalStorageDAO
  });
  const tenantRepository = new TenantRepository({
    shellDAO: shellLocalStorageDAO
  });
  const entitlementRepository = new EntitlementRepository({
    shellDAO: shellLocalStorageDAO
  });

  const idTokenRepository = new IdTokenRepository({
    shellDAO: shellLocalStorageDAO
  });

  const appInterface = await initializeAppInterfaceV1({
    clientId,
    appName,
    stack
  });

  //Initializing auth provider
  const authProvider = await auth(initializerState);
  const authProviderV2 = authV2(initializerState);

  //Initializing http clients
  const providerClient: IProviderClient = new ProviderClient({
    clientId,
    baseUrl: window.location.origin
  });

  const refreshClient: IRefreshClient = new RefreshV3Client(
    window.location.origin
  );

  const exchangeClient: IExchangeClient = new ExchangeV3Client(
    window.location.origin
  );

  const authzClient: IAuthzClient = new AuthzClient();

  const logoutClient: ILogoutClient = new LogoutClient();

  const hpidClient = new HPIDClient({ stack });

  const userMgtClient = new UserMgtClient(
    UserMgtClient.getBaseUrl(stack),
    authProviderV2
  );

  // Initialize services
  const initialEnablementStatusSupporSession =
    !!initializerState?.manifest?.services?.ast?.enable;

  const supportSessionService: ISupportSessionService =
    new SupportSessionService({
      initialEnablementStatus: initialEnablementStatusSupporSession
    });

  //Disable tenantHandler and orgSelector when support session is enabled
  if (supportSessionService.isSupportSession()) {
    if (initializerState.manifest?.services?.tenantHandler) {
      initializerState.manifest.services.tenantHandler.enable = false;
    }
    if (initializerState.manifest?.navigation?.header?.domainSelector) {
      initializerState.manifest.navigation.header.domainSelector.enable = false;
    }
  }

  const tenantHandler = await initializeTenantHandlerSingleton({
    ...initializerState,
    repository: tenantRepository,
    authProvider: authProviderV2
  });

  const authTokenService = await initializeAuthTokenSingleton({
    ...initializerState,
    repository: authTokenRepository,
    tenantHandler
  });

  setAuthTokenService(authTokenService);

  const sessionService = await initializeSessionServiceSingleton({
    ...initializerState,
    refreshClient,
    exchangeClient,
    supportSessionService,
    authTokenService,
    idTokenRepository
  });

  const logoutService = await initializeLogoutServiceSingleton({
    stack,
    logoutClient,
    authzClient,
    supportSessionService,
    authenticationProvider,
    sessionService,
    hpidClient
  });

  const loginService = await initializeLoginServiceSingleton({
    providerClient,
    isNative,
    sessionService
  });

  sessionService.setDependencies({ logoutService, loginService });

  await tenantHandler.init(sessionService);
  authProvider._setSessionService(sessionService);
  authProvider._setTenantHandlerService(tenantHandler);

  authProviderV2._setSessionService(sessionService);
  authProviderV2._setTenantHandlerService(tenantHandler);

  const events = new EventInterface();

  //Initializing other services
  const userService = await initializeUserServiceSingleton({
    sessionService,
    userMgtClient
  });

  const featureFlagService = await initializeFeatureFlagServiceSingleton({
    userService,
    manifestFeatureFlag: initializerState?.manifest?.services?.featureFlags,
    appName
  });

  const store = new Store();
  const userActivity = userActivityImport(initializerState, events);
  optimizely(initializerState);
  const analytics = await analyticsImport(
    initializerState,
    authProvider,
    store,
    events,
    isNative
  );
  const navigation = await navigationImport(initializerState);
  const localization = await localizationImport(initializerState);

  const scopeService = new ScopeService({
    scopeRepository,
    authProvider: authProviderV2,
    authTokenService
  });

  const accessControl = await accessControlImport({
    ...initializerState,
    appContext,
    store,
    events,
    scopeService
  });

  const entitlements = await entitlementsImport({
    ...initializerState,
    authProvider: authProviderV2,
    navigation,
    localization,
    store,
    events,
    sessionService,
    appContext,
    authTokenService,
    repository: entitlementRepository
  });

  const orgSelector = await OrgSelector({
    ...initializerState,
    authProvider: authProviderV2.createOrgedAuthProvider(),
    orglessAuthProvider: authProviderV2.createOrglessAuthProvider(),
    navigation,
    localization,
    sessionService,
    tenantHandlerService: tenantHandler
  });

  const featureFlags = await initializeFeatureFlagInterfaceV1({
    featureFlagService
  });

  const theme = themeImport(initializerState);
  const serviceRouting = serviceRoutingImport({
    ...initializerState,
    store
  });
  const logger = await loggerImport(initializerState);
  const criterion = initializeCriterionSingleton({
    interfaces: {
      accessControl,
      entitlements
    },
    services: {
      events,
      sessionService,
      tenantHandler: tenantHandler,
      featureFlagService
    },
    manifest: initializerState.manifest
  });

  const userSession = userSessionImport(initializerState);
  const fallback = new Fallback({
    event: {
      eventInterface: events,
      eventName: 'shell-fallback'
    },
    navigationInterface: navigation,
    configuration: {
      defaultFallbackKey: (initializerState?.manifest as any)?.fallback
        ?.defaultFallbackKey,
      defaultFallback: {
        strategy: 'redirect',
        redirectTo: initializerState?.manifest?.portal?.fallbackRoute || '/',
        key: undefined
      },
      fallbackList: (initializerState?.manifest as any)?.fallback?.fallbackList
    }
  });

  const layout = new Layout({
    // defaultLayoutKey: initializerState?.manifest?.layouts?.
    layoutList: initializerState?.manifest?.layouts
  });

  const manifestNavigation = initializerState?.manifest?.navigation;
  routesService.setDependencies({
    defaultLayoutKey: manifestNavigation?.defaultLayoutKey,
    routes: manifestNavigation?.routes
  });

  const manifestBreadcrumb = initializerState?.manifest?.services?.breadcrumb;
  await breadcrumbService.start({
    enable: manifestBreadcrumb?.enable,
    settingsList: manifestBreadcrumb?.settingsList,
    defaultSettingsKey: manifestBreadcrumb?.defaultSettingsKey,
    tenantHandler,
    navigation,
    criterion
  });

  const breadcrumbs = new Breadcrumb();

  const routes = new Routes();

  const microfrontendRouter = adapter?.disableRouter
    ? undefined
    : createMicrofrontendRouterSingleton({
        interfaces: {
          events,
          navigation,
          store,
          fallback,
          criterion,
          layout,
          userSession,
          authProvider,
          localization,
          sessionService,
          tenantHandler
        },
        manifest: initializerState.manifest,
        stack: Stack[initializerState.manifest.portal.stack]
      });

  const userInterface = await initializeUserInterfaceV1({
    manifest: initializerState.manifest,
    sessionService,
    authProvider: authProviderV2,
    authenticationProvider
  });

  const sessionInterface = await initializeSessionInterfaceV1({
    tenantHandlerService: tenantHandler,
    sessionService
  });

  // Clear repositories
  const repositoriesToClearWhenUserIsLoggedout = [];
  repositoriesToClearWhenUserIsLoggedout.push(
    scopeRepository,
    authTokenRepository,
    tenantRepository,
    entitlementRepository,
    idTokenRepository
  );
  if (!sessionService.isLoggedIn()) {
    repositoriesToClearWhenUserIsLoggedout.forEach((r) => r?.clear?.());
  }

  return {
    app: appInterface,
    userInterface,
    sessionInterface,
    sessionService,
    authProvider,
    analytics,
    events,
    navigation,
    store,
    featureFlags,
    localization,
    orgSelector,
    theme,
    serviceRouting,
    logger,
    userActivity,
    entitlements,
    accessControl,
    criterion,
    userSession,
    fallback,
    microfrontendRouter,
    tenantHandlerInterface: getTenantHandlerInterface(),
    tenantHandler,
    authToken: authTokenService,
    breadcrumbs: breadcrumbs,
    routes
  };
}
