import { LogLevel } from '@jarvis/jweb-core';
import { jshellLoggerPropsLocalStorageKey } from '../../../config/localStorageKeys';
import {
  setItemLocalStorage,
  getItemLocalStorage,
  removeItemLocalStorage
} from '../../../utils/localStorage';
import { getJWeb, isNative } from '../../../services/JWeb';
import { JWebType } from '../../../services/JWeb/types';
import * as T from './types';
import {
  ManifestServiceLoggerType,
  EnumAllowedLogType
} from '../../../types/manifest';

/* internal variables */
let dependencies: T.ServiceRoutingInitializerStatePropsType;
let isNativeInfo: boolean;
let jweb: JWebType;

const types: EnumAllowedLogType[] = Object.values(EnumAllowedLogType);

const _loggerForNative = (type: EnumAllowedLogType, arrayValue: any[]) => {
  const jwebConsole = jweb?.Plugins?.Console;

  const logLevel = (() => {
    switch (type) {
      case 'debug':
        return LogLevel.Debug;
      case 'error':
        return LogLevel.Error;
      case 'warn':
        return LogLevel.Warn;
      case 'log':
        return LogLevel.Log;
    }
  })();

  const message = (() => {
    let result = '';
    arrayValue?.forEach((value) => {
      if (typeof value === 'string') {
        result += ' ' + value;
      } else {
        try {
          result += ' ' + JSON.stringify(value);
        } catch (error) {
          console.error(error);
        }
      }
    });
    return result;
  })();

  jwebConsole.log({
    logLevel,
    message,
    tag: ''
  });
};

const _consoleApi = (type: EnumAllowedLogType, arrayValue: any[]) => {
  if (isNativeInfo) {
    _loggerForNative(type, arrayValue);
  } else {
    console?.[type]?.(...arrayValue);
  }
};

const _getLoggerOptions = (): ManifestServiceLoggerType => {
  const loggerProps = getItemLocalStorage(jshellLoggerPropsLocalStorageKey);

  if (loggerProps) {
    return loggerProps;
  }

  return dependencies?.manifest?.services?.logger || {};
};

const _isValidExpressions = (
  allowedExpressions: string[],
  ...args: Parameters<T.LogFuncType>
): boolean => {
  if (allowedExpressions) {
    return allowedExpressions.some((expression) =>
      args.some((arg) => {
        return typeof arg === 'string' && new RegExp(expression).test(arg);
      })
    );
  }
  return true;
};

const _createLogger: T.CreateLoggerType = ({ type, preffixLog }) => {
  return (...args) => {
    const { allowedTypes, allowedExpressions, enable } = _getLoggerOptions();

    const logs = [...args];
    if (preffixLog) logs.unshift(preffixLog);

    if (enable) {
      try {
        if (
          _isValidExpressions(allowedExpressions, ...logs) &&
          allowedTypes?.[type]
        ) {
          _consoleApi(type, logs);
        }
      } catch (err) {
        if (allowedTypes?.error) {
          const withPreffixLog = preffixLog ? preffixLog + ' = ' : '';
          console?.['error']?.(withPreffixLog + err.toString());
        }
      }
    }
  };
};

export const createLoggerInstance: T.CreateLoggerInstanceType = (options) => {
  const loggers: T.LoggerType = {} as any;

  types?.forEach((type) => {
    loggers[type] = _createLogger({ ...options, type });
  });
  return loggers;
};

const setLocalOptions: T.SetLocalOptionsType = (options) => {
  setItemLocalStorage(jshellLoggerPropsLocalStorageKey, options);
};

const removeLocalOptions: T.RemoveLocalOptionsType = () => {
  removeItemLocalStorage(jshellLoggerPropsLocalStorageKey);
};

const publicMethods = {
  createLoggerInstance,
  setLocalOptions,
  removeLocalOptions
};

const defaultLoggerInstance: T.LoggerType = {
  ...createLoggerInstance(),
  ...publicMethods
};

export const internalLogger = createLoggerInstance({
  preffixLog: 'JSHELL__@jarvis__shell-commons'
});

export default async function logger(
  initializerState: T.ServiceRoutingInitializerStatePropsType
) {
  dependencies = initializerState;
  jweb = await getJWeb();
  isNativeInfo = await isNative();
  return defaultLoggerInstance;
}
