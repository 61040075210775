import IdleJs from 'idle-js';
import { InterfacesInitializerStatePropsType } from '../../../interface/types';
import { EventsType } from '../events/types';
import * as internals from './internals';

export default (
  initializerState: InterfacesInitializerStatePropsType,
  events: EventsType
) => {
  let { enable, idle } =
    initializerState.manifest?.services?.userActivity || {};

  if (enable == undefined) {
    enable = true;
  }

  if (!idle) {
    idle = { time: 1800000 };
  }

  const idleTimer = new IdleJs({
    idle: idle.time, // idle time in ms
    events: ['mousemove', 'scroll', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
    keepTracking: true, // set it to false if you want to be notified only on the first idleness change
    startAtIdle: false, // set it to true if you want to start in the idle state
    onActive: () => internals.handleOnActive(events), // callback function to be executed after back form idleness
    onIdle: () => internals.handleOnIdle(events), // callback function to be executed after idle time
    onHide: () => internals.handleOnHide(events), // callback function to be executed when window become hidden
    onShow: () => internals.handleOnShow(events) // callback function to be executed when window become visible
  });

  idleTimer.start();

  let isIdle: () => boolean;
  if (enable) {
    isIdle = () => idleTimer.idle;
  } else {
    console.warn('userActivity is disabled');
    isIdle = () => false;
  }

  return { isIdle };
};
