import bindAllMethods from '../../utils/bindAllMethods';
import getTenantStrategy from './strategy/strategy';
import {
  StackType,
  TenantDataType,
  TenantHandlerParamsType,
  TenantHandlerStrategyType,
  TenantVisualizationType
} from './strategy/types';
import { TenantRepository } from './TenantRepository';
import * as T from './types';

import TenantObserver, { TenantEvents } from './TenantObserver';
import TenantStrategy from './strategy/TenantStrategy';
import { ISessionService } from '../session';
import AuthContextEnum from '../authTokenService/AuthContextEnum';
import tenantLevelToAuthContextEnum from '../authTokenService/utils/tenantLevelToAuthContextEnum';
import authContextEnumToTenantLevel from '../authTokenService/utils/authContextEnumToTenantLevel';

export default class TenantHandlerService {
  public TENANT_SUFFIX_CONCAT_STRING = '__';
  public START_TENANT_LEVEL = 1;

  private _initialTenants: T.InitialTenantType[];
  private _tenantRepository: TenantRepository;
  private _stack: StackType;
  private _globalTenantHandler: string;

  private _tenantHandlerParams: TenantHandlerParamsType[];
  private _enable: boolean;

  private _currentTenantHandlerKey: string;
  private _currentTenantHandler: TenantHandlerParamsType;

  // Object to keep tenantHandlers objects and processed in level hierarchy
  private _handledTenants: T.HandledTenant[];

  private _authProvider: T.AuthProviderV2Type;

  private _tenantSelectorAssetReference: string;

  private _tenantStrategyInstanceList: Record<
    string,
    T.TenantStrategyInstanceType[]
  >;

  public _sessionService: ISessionService;

  constructor({
    tenantHandlerList,
    stack,
    globalTenantHandler,
    tenantRepository,
    enable,
    authProvider,
    tenantSelectorAssetReference
  }: T.TenantHandlerDependenciesType) {
    this._tenantHandlerParams = tenantHandlerList;
    this._stack = stack;
    this._handledTenants = [];
    this._globalTenantHandler = globalTenantHandler;
    this._tenantRepository = tenantRepository;
    this._enable = enable;
    this._authProvider = authProvider;
    this._tenantSelectorAssetReference = tenantSelectorAssetReference;
    bindAllMethods(this);
  }

  public async init(sessionService: ISessionService): Promise<void> {
    this._sessionService = sessionService;
    if (!this.isEnabled()) return;
    this._tenantStrategyInstanceList = {};
    this._tenantHandlerParams.forEach((t) => {
      this._tenantStrategyInstanceList[t.key] = [];
      this._createTenantInstanceList(t, this.START_TENANT_LEVEL, t.key);
    });
  }

  public isEnabled(): boolean {
    return this._enable;
  }

  public setTenantHandlerKey = async (
    key: string = this._globalTenantHandler
  ): Promise<void> => {
    if (!this.isEnabled()) return;
    if (this._currentTenantHandlerKey !== key) {
      this._handledTenants = [];
      const firstTenantLevel = this.START_TENANT_LEVEL;

      this._currentTenantHandlerKey = key || this._globalTenantHandler;
      const tenantHierarchy = this._tenantHandlerParams.find(
        (t) => t.key === key
      );

      this._currentTenantHandler = tenantHierarchy;
      if (!tenantHierarchy) console.error('TenantKey not found.');

      this.createHandleTenantObject(
        this._currentTenantHandler,
        firstTenantLevel
      );

      await TenantObserver.notify(
        TenantEvents.SET_TENANT_HANDLER_KEY,
        this._handledTenants
      );
    }
  };

  public getTenantSuffix = (level: number = null): string => {
    if (this._handledTenants.length === 0) return '';
    const suffixList = this._getOrderedTenantSuffixes(level);
    return suffixList.join(this.TENANT_SUFFIX_CONCAT_STRING);
  };

  public generateTenantSuffix = (
    authContext: AuthContextEnum,
    tenantId: string
  ): string => {
    const tenantLevel = authContextEnumToTenantLevel(authContext);
    const suffixList = this._getOrderedTenantSuffixes(tenantLevel - 1);
    suffixList.push(tenantId);
    return suffixList.join(this.TENANT_SUFFIX_CONCAT_STRING);
  };

  public getCurrentContext(): AuthContextEnum {
    if (this._handledTenants.length === 0) return AuthContextEnum.tenantless;
    let highestTenantLevel = 0;
    this._handledTenants.forEach((t) => {
      if (t.proccessed && t.level > highestTenantLevel)
        highestTenantLevel = t.level;
    });
    return tenantLevelToAuthContextEnum(highestTenantLevel);
  }

  public checkIfTenantIsStoredBySuffix = (sufix: string): boolean => {
    return !!this._tenantRepository.findOne(sufix)?.id;
  };

  public clearTenants = (level = this.START_TENANT_LEVEL): void => {
    this._validateLevel(level);
    this._tenantRepository.clear(level);
  };

  public getNextUnproccessedTenant = (): T.HandledTenant &
    T.TenantStrategyInstanceType => {
    const data = this._handledTenants.find((t) => t.proccessed === false);
    if (!data) return null;
    const strategy = this.getTenantStrategy(data?.level);
    return { ...data, strategy };
  };

  public getTenantId = (level = this.START_TENANT_LEVEL): string => {
    this._validateLevel(level);
    const tenantData = this._tenantRepository.findOne(
      this.getTenantSuffix(level)
    );
    return tenantData?.level === level ? tenantData.id : null;
  };

  public getTenantIdsMap = (): Record<string, string> => {
    const tenantIdsMap = {};
    this._handledTenants.forEach((t) => {
      if (t.proccessed) {
        tenantIdsMap[t.strategyEnum] = t.id;
      }
    });
    return tenantIdsMap;
  };

  public getTenantByLevel = (
    level = this.START_TENANT_LEVEL
  ): T.HandledTenant => {
    this._validateLevel(level);
    return this._handledTenants.find((t) => t.level === level);
  };

  public getTenantByContext = (
    authContext: AuthContextEnum
  ): T.HandledTenant => {
    const tenantLevel = authContextEnumToTenantLevel(authContext);
    return this._handledTenants.find(
      (t) => t.level === tenantLevel && t.proccessed
    );
  };

  public getTenantStrategy(level = this.START_TENANT_LEVEL): TenantStrategy {
    return this._tenantStrategyInstanceList?.[
      `${this._currentTenantHandlerKey}`
    ]?.find((t) => t.level === level)?.strategy;
  }

  public setTenant = async (
    tenantId: string,
    level: number,
    options: { reload?: boolean },
    data?: TenantDataType
  ): Promise<void> => {
    this._validateLevel(level);
    const handledTenant = this._handledTenants.find((t) => t.level === level);
    const oldHandledTenant: T.HandledTenant = { ...handledTenant };

    try {
      handledTenant.id = tenantId;
      handledTenant.proccessed = true;
      handledTenant.data = data;

      await this.getTenantStrategy(level).setTenant(
        tenantId,
        tenantLevelToAuthContextEnum(level)
      );
      await this.setTenantId(handledTenant, tenantId, level, data);
    } catch (e) {
      handledTenant.id = oldHandledTenant.id;
      handledTenant.proccessed = oldHandledTenant.proccessed;
      handledTenant.data = oldHandledTenant.data;
      console.error(`Tenant ${tenantId} wasn't able to be exchanged.`);
    }

    await TenantObserver.notify(TenantEvents.SET_TENANT, this._handledTenants);
    options?.reload && window.location.reload();
  };

  public getHandledTenantList = (): T.HandledTenant[] => {
    return this._handledTenants;
  };

  public setInitialTenants(initialTenants: T.InitialTenantType[]): void {
    this._initialTenants = initialTenants;
  }

  public getInitialTenants(): T.InitialTenantType[] {
    return this._initialTenants;
  }

  public async getTenantList({
    authContext,
    refreshList
  }: {
    authContext: AuthContextEnum;
    refreshList?: boolean;
  }): Promise<TenantVisualizationType[]> {
    const tenantLevel = authContextEnumToTenantLevel(authContext);
    return this.getTenantStrategy(tenantLevel).getTenantList(refreshList);
  }

  private setTenantId = async (
    handledTenant: T.HandledTenant,
    tenantId: string,
    level = this.START_TENANT_LEVEL,
    data: TenantDataType
  ) => {
    this._validateLevel(level);
    const tenantStrategy = this.getTenantStrategy(level);

    const tenantStorage = {
      id: tenantId,
      strategy: tenantStrategy.getStrategy(),
      tenantHandlerKey: this._currentTenantHandlerKey,
      level,
      data
    };

    if (!data) {
      const tenantData = await tenantStrategy.getTenantById(tenantId);
      tenantStorage.data = { type: tenantData.type, name: tenantData.name };
      handledTenant.data = tenantStorage.data;
    }
    const tenantSuffix = this.getTenantSuffix(level);

    this._tenantRepository.save(tenantSuffix, tenantStorage);

    this.unProccessChainedHandledTenants(level + 1);
    return tenantStorage;
  };

  private _getInitialTenantSuffix(level) {
    this._validateLevel(level);
    const tenantIds = [];
    this._initialTenants?.forEach((t) => {
      if (t.level > level) return;
      tenantIds.push(t.id);
    });

    return tenantIds.join(this.TENANT_SUFFIX_CONCAT_STRING);
  }

  private createHandleTenantObject = (
    tenantHierarchy: TenantHandlerParamsType,
    level = this.START_TENANT_LEVEL,
    resetProccess = false
  ): T.HandledTenant => {
    this._validateLevel(level);
    const strategy = tenantHierarchy?.strategy;

    const sufix = this._getInitialTenantSuffix(level);

    const storedTenant = this._tenantRepository.findOne(
      sufix || this.getTenantSuffix(level)
    );

    const isProccessed = this._checkIfTenantIsProccessed(
      tenantHierarchy,
      storedTenant,
      strategy
    );

    const handledTenant: T.HandledTenant = {
      level,
      id: storedTenant?.id,
      proccessed: isProccessed,
      data: storedTenant?.data,
      strategyEnum: strategy
    };

    this._handledTenants.push(handledTenant);

    if (tenantHierarchy.subTenant) {
      this.createHandleTenantObject(
        tenantHierarchy.subTenant,
        level + 1,
        resetProccess || isProccessed
      );
    }
    return handledTenant;
  };

  private _validateLevel = (level: number) => {
    if (level < this.START_TENANT_LEVEL)
      throw new Error(
        `The tenant level must be ${this.START_TENANT_LEVEL} or higher.`
      );
  };

  private unProccessChainedHandledTenants = (
    level = this.START_TENANT_LEVEL
  ) => {
    this._validateLevel(level);
    this._handledTenants.forEach((t) => {
      if (t.level >= level) {
        t.proccessed = false;
        t.data = null;
      }
    });
  };

  private _createTenantInstanceList(
    tenantHierarchy: TenantHandlerParamsType,
    level: number,
    key: string
  ) {
    const attributes: TenantHandlerStrategyType = {
      stack: this._stack,
      authProvider: this._authProvider,
      assetReference:
        tenantHierarchy?.assetReference || this._tenantSelectorAssetReference,
      options: tenantHierarchy?.options,
      sessionService: this._sessionService
    };
    const tenantStrategy = getTenantStrategy(
      tenantHierarchy.strategy,
      attributes
    );

    this._tenantStrategyInstanceList[`${key}`].push({
      level,
      strategy: tenantStrategy
    });

    !!tenantHierarchy.subTenant &&
      this._createTenantInstanceList(tenantHierarchy.subTenant, level + 1, key);
  }

  private _checkIfTenantIsProccessed = (
    tenantHierarchy: TenantHandlerParamsType,
    tenant: T.TenantStorageType,
    strategy: string
  ): boolean => {
    let isValid = false;
    const tenantTypeFilter = tenantHierarchy?.options?.filter?.tenantType;
    if (tenantTypeFilter) {
      isValid =
        tenantTypeFilter && tenantTypeFilter?.includes?.(tenant?.data?.type);
    } else {
      isValid = true;
    }

    return tenant?.strategy === strategy && isValid;
  };

  private _getOrderedTenantSuffixes = (level: number = null): string[] => {
    const currentTenants: string[] = [];
    this._handledTenants.forEach((t) => {
      if (level && t.level > level) {
        return;
      }
      t.proccessed && currentTenants.push(t.id);
    });
    return currentTenants;
  };
}
