import { JarvisAuthProvider } from './jarvis-auth-provider'

export class JarvisAuthPluginAuthProvider implements JarvisAuthProvider {
    accessToken: string = ''
    async getAccessToken(forceRefresh?: boolean) {
        if (this.accessToken.length > 0 && !forceRefresh) {
          return this.accessToken;
        }
        if((window as any).JWeb) {
            try {
                let options = {
                    accountProviderOptions: {
                        allowNetworkAccess: true,
                        allowUserInteraction: true,
                        showAccountCreationLink: true,
                        skipTokenRefresh: false,
                        requireFreshToken: !!forceRefresh
                    }
                };
                let response = await (window as any).JWeb.Plugins.Auth.getToken(options)
                if(response !== undefined && response !== null && 'tokenValue' in response) {
                    this.accessToken = response.tokenValue
                }
            } catch (err) {
                // Do something!
            }
        }
        return this.accessToken
    }
}