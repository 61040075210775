import { internalLogger } from '../interface/v1/logger';

export function setItemLocalStorage(key: string, item: any) {
  localStorage.setItem(key, JSON.stringify(item));
}

export function getItemLocalStorage(key: string): any {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (err) {
    internalLogger?.error(err);
    return undefined;
  }
}

export function removeItemLocalStorage(key: string): any {
  try {
    return localStorage.removeItem(key);
  } catch (err) {
    internalLogger?.error(err);
    return undefined;
  }
}
