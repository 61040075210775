import AppInterfaceV1, {
  AppInterfaceV1Type,
  AppInterfaceV1Param
} from './AppInterfaceV1';

let appInterfaceV1: AppInterfaceV1;

function _createAppInterfaceV1(options: AppInterfaceV1Param) {
  const { clientId, appName, stack } = options;

  return new AppInterfaceV1({
    clientId,
    appName,
    stack
  });
}

export function getAppInterfaceV1(): AppInterfaceV1Type {
  return appInterfaceV1.getInterface();
}

export default async function initializeAppInterfaceV1(
  options: AppInterfaceV1Param
): Promise<AppInterfaceV1Type> {
  if (appInterfaceV1) return appInterfaceV1.getInterface();
  appInterfaceV1 = _createAppInterfaceV1(options);

  return appInterfaceV1.getInterface();
}
