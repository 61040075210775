import { DAOItemType, IShellDAO, OptionsType } from '../dao/IShellDAO';
import { TokenContextUtils } from '../utils/TokenContextUtils';
import { WithTTL } from '../dao/decorators/WithTTL';
import { SanitizeService } from './sanitizer';

// <Type>(arg: Type): Type {
export abstract class AbstractRepository<ObjectType> {
  public shellDAO: IShellDAO;
  public prefixKey: string;
  public enableSanitizer: boolean;
  public sanitizeService: SanitizeService = null;

  constructor({
    shellDAO,
    prefixKey,
    enableSanitizer = false,
    defaultTTL = 60
  }: {
    shellDAO: IShellDAO;
    prefixKey: string;
    enableSanitizer?: boolean;
    defaultTTL?: number;
  }) {
    this.prefixKey = prefixKey;
    this.enableSanitizer = enableSanitizer;

    if (enableSanitizer) {
      this.sanitizeService = new SanitizeService(defaultTTL);
      const daoWithTTL = new WithTTL(shellDAO);
      this.sanitizeService.setRepositoryWithTTL({
        shellDaoWithTTL: daoWithTTL,
        prefixKey
      });
      this.shellDAO = this.sanitizeService.getStorage();
    } else {
      this.shellDAO = shellDAO;
    }
  }

  save(sufix: string, data: ObjectType, options?: OptionsType): void {
    const _id = TokenContextUtils.getKey(this.prefixKey, sufix);
    this.shellDAO.save(_id, data, options);
  }

  saveAll(data: ObjectType[], sufix?: string, options?: OptionsType): void {
    const _id = TokenContextUtils.getKey(this.prefixKey, sufix);
    this.shellDAO.saveAll(_id, data, options);
  }

  findOne(sufix: string): ObjectType {
    const _id = TokenContextUtils.getKey(this.prefixKey, sufix);
    return this.shellDAO.findOne(_id) || undefined;
  }

  delete(sufix: string): boolean {
    const _id = TokenContextUtils.getKey(this.prefixKey, sufix);
    return this.shellDAO.delete(_id);
  }

  findAll(): ObjectType[] {
    const items = this.shellDAO.findAll({
      prefixKey: this.prefixKey
    }) as ObjectType[];
    return items;
  }

  clear(): void {
    const items = this.shellDAO.findAll({
      prefixKey: this.prefixKey
    }) as DAOItemType[];
    const filteredItemKeys = [];
    items.forEach((t) => {
      if (t.key.startsWith(this.prefixKey)) filteredItemKeys.push(t.key);
    });
    this.shellDAO.deleteAll(filteredItemKeys);
  }
}
