import AuthContextEnum from '../AuthContextEnum';

const authContextEnumToTenantLevel = (
  authContextEnum: AuthContextEnum
): number => {
  let level;
  switch (authContextEnum) {
    case AuthContextEnum.tenantless:
      level = 0;
      break;
    case AuthContextEnum.tenant:
      level = 1;
      break;
    default:
      level = 2;
      break;
  }
  return level;
};

export default authContextEnumToTenantLevel;
