export type MicrofrontendListItemType = {
  path?: string | string[];
  subRoutes?: MicrofrontendListItemType[];
};

export type GetMicrofrontendFromPathPropsType = {
  list: MicrofrontendListItemType[];
};

export default function getMicrofrontendsFlatMap<
  T extends GetMicrofrontendFromPathPropsType
>(options: T): T['list'] {
  const result: T['list'] = [];

  options?.list?.forEach((route) => {
    if (route?.path) {
      result.push(route);
    }
    if (route?.subRoutes) {
      const subRoutes = getMicrofrontendsFlatMap({ list: route.subRoutes });

      result.push(...subRoutes);
    }
  });

  return result;
}
