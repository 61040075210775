import { SESSION_API_BASE_URL, SESSION_API_LOGOUT_URL } from '../constants';
import ILogoutClient from './ILogoutClient';

export default class LogoutClient implements ILogoutClient {
  configureLogoutUrl(baseUrl: string, state: string): string {
    let stateQueryParameter = '';
    if (state) {
      stateQueryParameter = `?state=${state}`;
    }
    return `${baseUrl}${SESSION_API_BASE_URL}${SESSION_API_LOGOUT_URL}${stateQueryParameter}`;
  }
}
