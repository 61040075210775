import auth from './auth';
import { InterfacesInitializerStatePropsType } from '../types';

export default async function initializeShellInterfaceV2(
  initializerState: InterfacesInitializerStatePropsType
) {
  const authProvider = auth(initializerState);

  return {
    authProvider
  };
}
