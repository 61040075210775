import bindAllMethods from '../../../utils/bindAllMethods';
import {
  GetFeatureFlagParams,
  IFeatureFlagClient
} from '../../../services/featureFlagService';

export type FeatureFlagClientInterfaceV1Type = {
  getFeatureFlag({ key, defaultValue }: GetFeatureFlagParams): Promise<unknown>;
};

type FeatureFlagClientInterfaceV1Param = {
  featureFlagClient: IFeatureFlagClient;
};

class FeatureFlagClientInterfaceV1 {
  private _featureFlagClient: IFeatureFlagClient;

  constructor({ featureFlagClient }: FeatureFlagClientInterfaceV1Param) {
    this._featureFlagClient = featureFlagClient;
    bindAllMethods(this);
  }

  public getInterface(): FeatureFlagClientInterfaceV1Type {
    return {
      getFeatureFlag: this?._featureFlagClient?.getFeatureFlag
    };
  }
}

export default FeatureFlagClientInterfaceV1;
