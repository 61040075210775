import bindAllMethods from '../../../utils/bindAllMethods';
import {
  GetTenantListOptionsType,
  PaginatedTenantVisualizationType,
  TenantVisualizationType
} from '../../../services/tenantHandler/strategy/types';
import { AuthContextEnum } from '../../../services/authTokenService';
import { TenantHandlerService } from '../../../services/tenantHandler';
import authContextEnumToTenantLevel from '../../../services/authTokenService/utils/authContextEnumToTenantLevel';

export default class TenantHandlerInterfaceV1 {
  private _tenantHandlerService: TenantHandlerService;
  constructor({
    tenantHandlerService
  }: {
    tenantHandlerService: TenantHandlerService;
  }) {
    this._tenantHandlerService = tenantHandlerService;
    bindAllMethods(this);
  }

  public isEnabled(): boolean {
    return this._tenantHandlerService.isEnabled();
  }

  public getCurrentContext(): AuthContextEnum {
    return this._tenantHandlerService.getCurrentContext();
  }

  public getTenantData({
    authContext
  }: {
    authContext: AuthContextEnum;
  }): TenantVisualizationType {
    const tenantData =
      this._tenantHandlerService.getTenantByContext(authContext);
    return {
      id: tenantData?.id,
      name: tenantData?.data?.name,
      type: tenantData?.data?.type
    };
  }

  public getTenantId(authContext?: AuthContextEnum): string {
    const tenantLevel = authContextEnumToTenantLevel(authContext);
    return this._tenantHandlerService.getTenantId(tenantLevel);
  }

  public setTenant({
    tenantId,
    authContext
  }: {
    tenantId: string;
    authContext: AuthContextEnum;
  }): Promise<void> {
    const tenantLevel = authContextEnumToTenantLevel(authContext);
    const options = { reload: false };
    return this._tenantHandlerService.setTenant(tenantId, tenantLevel, options);
  }

  public async getTenantById({
    tenantId,
    authContext
  }: {
    tenantId: string;
    authContext: AuthContextEnum;
  }): Promise<TenantVisualizationType> {
    const tenantLevel = authContextEnumToTenantLevel(authContext);
    return this._tenantHandlerService
      .getTenantStrategy(tenantLevel)
      .getTenantById(tenantId);
  }

  public async getTenantList({
    authContext,
    refresh
  }: {
    authContext: AuthContextEnum;
    refresh?: boolean;
  }): Promise<TenantVisualizationType[]> {
    const tenantLevel = authContextEnumToTenantLevel(authContext);
    return this._tenantHandlerService
      .getTenantStrategy(tenantLevel)
      .getTenantList(refresh);
  }

  public async getPaginatedTenantList({
    authContext,
    ...options
  }: {
    authContext: AuthContextEnum;
  } & GetTenantListOptionsType): Promise<PaginatedTenantVisualizationType> {
    const tenantLevel = authContextEnumToTenantLevel(authContext);
    return this._tenantHandlerService
      .getTenantStrategy(tenantLevel)
      .getPaginatedTenantList(options);
  }
}
