import AuthContextEnum from '../AuthContextEnum';

const getParentContext = (authContext: AuthContextEnum): AuthContextEnum => {
  let parentAuthContext: AuthContextEnum;
  switch (authContext) {
    case AuthContextEnum.subtenant:
      parentAuthContext = AuthContextEnum.tenant;
      break;
    default:
      parentAuthContext = AuthContextEnum.tenantless;
  }
  return parentAuthContext;
};

export default getParentContext;
