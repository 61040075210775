import bindAllMethods from '../../utils/bindAllMethods';
import { internalLogger } from '../../interface/v1/logger';
import { IAuthTokenService } from '../authTokenService';
import AuthContextEnum from '../authTokenService/AuthContextEnum';
import { getJWeb, JWebErrorHandler } from '../JWeb';
import * as JWebTypes from '../JWeb/types';
import { TenantStrategyEnum } from '../tenantHandler/strategy/strategy';
import ISessionService, {
  RefreshParameterType,
  SessionServiceDependenciesType
} from './ISessionService';
import { GenerateAuthenticationUrlParams, ILoginService } from './loginService';
import {
  GetProviderListParam,
  GetProviderListResponseType
} from '../../clients/shell/provider';
import { getSessionData } from '../../clients/shell/session';

export type SessionServiceNativeParameters = {
  authTokenService: IAuthTokenService;
};

export default class SessionServiceNative implements ISessionService {
  private _refreshPromise: Promise<void>;
  private _authTokenService: IAuthTokenService;
  private _loginService: ILoginService;
  private _legacySessionDataValue = {
    isLoggedIn: false,
    idToken: ''
  };

  constructor({ authTokenService }: SessionServiceNativeParameters) {
    this._authTokenService = authTokenService;
    bindAllMethods(this);
  }

  private async _updateInitialState(): Promise<void> {
    this._legacySessionDataValue.isLoggedIn = !!(await getSessionData())
      ?.isLoggedIn;
  }

  public setDependencies({
    loginService
  }: SessionServiceDependenciesType): void {
    this._loginService = loginService;
  }

  public async init(): Promise<void> {
    console.log('Initializing session service native.');

    // TODO: change methods to be async and remove the code below
    await this._updateInitialState();
    // end of todo

    return Promise.resolve();
  }

  public async refresh(
    refreshParameterType?: RefreshParameterType
  ): Promise<void> {
    internalLogger.log('Refresh token', refreshParameterType?.tenantsIdMap);

    if (this._refreshPromise) return this._refreshPromise;

    const refreshAction = async () => {
      await this._setStratusAccessTokenFromNative();
    };

    this._refreshPromise = refreshAction().finally(() => {
      this._refreshPromise = undefined;
    });

    return this._refreshPromise;
  }

  public async exchangeTenantToken(
    tenantId: string,
    authContext: AuthContextEnum,
    tenantStrategy: TenantStrategyEnum
  ): Promise<void> {
    internalLogger.log(
      'Exchange tenant token',
      tenantId,
      authContext,
      tenantStrategy
    );
    await this._setStratusAccessTokenFromNative();
  }

  private async _setStratusAccessTokenFromNative(): Promise<void> {
    const Auth = await getJWeb()?.then?.((res) => res?.Plugins?.Auth);

    const { tokenValue } = await Auth?.getToken?.({
      accountProviderOptions: {
        allowUserInteraction: false
      }
    } as any).then((v) => {
      internalLogger.error('Error on getToken');
      return JWebErrorHandler<JWebTypes.AccessToken>(v);
    });

    const haveAccessToken =
      typeof tokenValue === 'string' && tokenValue.length > 1;
    if (haveAccessToken) {
      this._authTokenService.setToken(tokenValue, AuthContextEnum.tenantless);
    } else {
      this._authTokenService.deleteToken(AuthContextEnum.tenantless);
    }
  }

  public async clearSession(): Promise<void> {
    const Auth = await getJWeb()?.then?.((res) => res?.Plugins?.Auth);
    await Auth.logout().then((v) => JWebErrorHandler(v));
    await this._setStratusAccessTokenFromNative();
  }

  public async logout(): Promise<void> {
    await this.clearSession();
  }

  public async getProviderList(
    options?: GetProviderListParam
  ): Promise<GetProviderListResponseType> {
    return this._loginService.getProviderList(options);
  }

  public async generateAuthenticationUrl(
    options: GenerateAuthenticationUrlParams
  ): Promise<string> {
    return this._loginService.generateAuthenticationUrl(options);
  }

  public isLoggedIn(): boolean {
    return !!this._legacySessionDataValue?.isLoggedIn;
  }

  public getIdToken(): string {
    return this._legacySessionDataValue?.idToken || '';
  }
}
