import { internalLogger } from '../interface/v1/logger';

export function setItemSessionStorage(key: string, item: any) {
  sessionStorage.setItem(key, JSON.stringify(item));
}

export function getItemSessionStorage(key: string, asJson = true): any {
  try {
    if (asJson) return JSON.parse(sessionStorage.getItem(key));
    return sessionStorage.getItem(key);
  } catch (err) {
    internalLogger?.error(err);
    return undefined;
  }
}

export function removeItemSessionStorage(key: string): any {
  try {
    return sessionStorage.removeItem(key);
  } catch (err) {
    internalLogger?.error(err);
    return undefined;
  }
}
