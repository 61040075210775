import { UserOnboardingJoinInvitedOrganizationParams } from '../../../services/userOnboardingService/types';
import { IUserOnboardingService } from '../../../services/userOnboardingService';
import bindAllMethods from '../../../utils/bindAllMethods';

export type UserInterfaceV1Type = {
  onboarding: {
    isUserOnboarded(): Promise<boolean>;
    joinInvitedOrganization(
      options?: UserOnboardingJoinInvitedOrganizationParams
    ): Promise<void>;
    onboardUser(): Promise<void>;
    shouldRenderOnboardingScreen(): Promise<boolean>;
  };
};

type UserInterfaceV1Param = {
  userOnboardingService: IUserOnboardingService;
};

class UserInterfaceV1 {
  private _userOnboardingService: IUserOnboardingService;

  constructor({ userOnboardingService }: UserInterfaceV1Param) {
    this._userOnboardingService = userOnboardingService;
    bindAllMethods(this);
  }

  public getInterface(): UserInterfaceV1Type {
    return {
      onboarding: {
        isUserOnboarded: this._userOnboardingService.isUserOnboarded,
        joinInvitedOrganization:
          this._userOnboardingService.joinInvitedOrganization,
        onboardUser: this._userOnboardingService.onboardUser,
        shouldRenderOnboardingScreen:
          this._userOnboardingService.shouldRenderOnboardingScreen
      }
    };
  }
}

export default UserInterfaceV1;
