import { ManifestType } from '../../../types/manifest';
import Criterion from '.';
import {
  AccessControlInterfaceType,
  AccessControlOptionsType
} from '../accessControl/types';
import {
  EntitlementsInterfaceType,
  OfferingAndEntitlementType,
  OfferingAndEntitlementOptionsType
} from '../entitlements/types';
import { EventsType } from '../events/types';
import { ISessionService } from '../../../services/session';
import { AuthContextEnum } from '../../../services/authTokenService';
import { TenantHandlerService } from '../../../services/tenantHandler';
import { IFeatureFlagService } from '../../../services/featureFlagService';

type CreateCriterionSingletonPropsType = {
  interfaces: {
    entitlements: EntitlementsInterfaceType;
    accessControl: AccessControlInterfaceType;
  };
  services: {
    events: EventsType;
    sessionService: ISessionService;
    tenantHandler: TenantHandlerService;
    featureFlagService: IFeatureFlagService;
  };
  manifest: ManifestType;
};

let criterionSingleton: ReturnType<typeof _createCriterion>;

function _createCriterion(options: CreateCriterionSingletonPropsType) {
  const { accessControl, entitlements } = options.interfaces;

  const { events, sessionService, tenantHandler, featureFlagService } =
    options?.services;

  const result = new Criterion({
    valueFunctions: {
      entitlement: async (
        value: OfferingAndEntitlementType,
        options?: OfferingAndEntitlementOptionsType
      ) => !!(await entitlements.checkEntitlements([value], options)),
      offering: async (
        value: OfferingAndEntitlementType,
        options?: OfferingAndEntitlementOptionsType
      ) => !!(await entitlements.checkOfferings([value], options)),
      scope: async (value: string, options?: AccessControlOptionsType) => {
        return !!(await accessControl.checkScopes([{ scope: value }], options));
      },
      isLoggedIn: async (value: boolean) => {
        const isLoggedIn = !!sessionService?.isLoggedIn?.();

        return value ? isLoggedIn : !isLoggedIn;
      },
      authContext: async (value: { context: AuthContextEnum }) => {
        const authContext = value?.context;

        if (!authContext) return false;

        const currentAuthContext = tenantHandler.getCurrentContext();

        return authContext === currentAuthContext;
      },
      featureFlag: async (value: {
        clientKey: string;
        featureFlag: string;
        value: unknown;
        defaultValue: unknown;
      }) => {
        const client = await featureFlagService.getClient(value?.clientKey);
        const featureFlag = await client.getFeatureFlag({
          key: value?.featureFlag,
          defaultValue: value?.defaultValue
        });
        return featureFlag === value?.value;
      }
    },
    criterionList: options?.manifest?.criterions as any,
    eventInterface: events,
    eventList: [
      events.shellEventNames.shellAccessControlChangedEventName,
      events.shellEventNames.shellEntitlementChangedEventName,
      events.shellEventNames.shellUserLogedInEventName,
      events.shellEventNames.shellUserLogedOutEventName,
      events.shellEventNames.shellFeatureFlagChangedEventName
    ],
    eventName: events.shellEventNames.shellCriterionChangedEventName
  });

  return result;
}

export function getCriterionSingleton() {
  return criterionSingleton;
}

export default function initializeCriterionSingleton(
  options: CreateCriterionSingletonPropsType
) {
  if (criterionSingleton) return criterionSingleton;

  criterionSingleton = _createCriterion(options);

  return criterionSingleton;
}
