export const tenantIdName = 'shellTenantId';

export const customerIdName = 'shellCustomerTenantId';

export const organizationIdName = 'shellOrganizationTenantId';

export const shellSessionIdName = 'shell-session-id';

export const shellVisitUuidCookieName = 'shell-visit-uuid';

export const optanonConsentCookieName = 'OptanonConsent';

export const optanonAlertBoxClosedCookieName = 'OptanonAlertBoxClosed';

export const enableAST = 'enableAST';

export const tempOrganizationIdName = 'shellOrgId';

export const organizationIdNameViaParam = 'organizationId';

export const localePreferenceCookieName = 'shell-locale-preference';

export const errorInvalidLocaleFormat =
  'Shell Localization: Invalid locale format';
