import { Stack } from '../../../../../../../../types/stratus';
import { JarvisAuthProvider, JarvisWebHttpClient } from '@jarvis/web-http';
import getStratusApiDomain from '../../../../../../../../utils/getStratusApiDomain';
import * as T from './types';
import { internalLogger } from '../../../../../../logger';

class OnboardingDirector {
  baseUrl: string;
  authProvider: JarvisAuthProvider;
  httpClient?: JarvisWebHttpClient;

  constructor(stack: Stack, authProvider: JarvisAuthProvider) {
    this.authProvider = authProvider;
    this.baseUrl = getStratusApiDomain(stack);
    this.httpClient = new JarvisWebHttpClient({
      defaultAuthProvider: this.authProvider
    });
  }

  async post({
    data
  }: T.PostRequestOptionsType): Promise<T.OnboardingDirectorSessionResponseType> {
    const endPoint = '/onboarding-director/v1/sessions';
    const baseURL = this.baseUrl + endPoint;

    internalLogger?.log?.('OD POST request', { baseURL, data });

    return this.httpClient
      .post({ baseURL, data })
      .then((e) => {
        const result = e.data as T.OnboardingDirectorSessionResponseType;
        internalLogger?.error?.('OD POST response', result);

        return result;
      })
      .catch((error) => {
        internalLogger?.error?.('OD POST error response', error);
        throw error;
      });
  }

  async put({
    data,
    baseURL
  }: T.PutRequestOptionsType): Promise<T.OnboardingDirectorSessionResponseType> {
    internalLogger?.log?.('OD PUT request', { baseURL, data });

    return this.httpClient
      .put({ baseURL, data })
      .then((e) => {
        const result = e.data as T.OnboardingDirectorSessionResponseType;

        internalLogger?.error?.('OD PUT response', result);

        return result;
      })
      .catch((error) => {
        internalLogger?.error?.('OD PUT error response', error);
        throw error;
      });
  }

  async get({
    data
  }: T.GetRequestOptionsType): Promise<
    T.OnboardingDirectorSessionResponseType[]
  > {
    const { app, onboardingContext, limit } = data;
    let limitString = '',
      onboardingContextString = '',
      appString = '';

    if (limit) {
      limitString = `&limit=${limit}`;
    }
    if (onboardingContext) {
      onboardingContextString = `&onboardingContext=${encodeURI(
        JSON.stringify(onboardingContext)
      )}`;
    }
    if (app) {
      appString = `&app=${encodeURI(JSON.stringify(app))}`;
    }

    const endPoint = '/onboarding-director/v1/sessions/resumable';
    const queryParams = `?sortBy=${T.SortByEnum.updatedAtDesc}${limitString}${onboardingContextString}${appString}`;
    const baseURL = this.baseUrl + endPoint + queryParams;

    internalLogger?.log?.('OD GET resumable sessions request', { baseURL });

    return this.httpClient
      .get({ baseURL })
      .then((e) => {
        const result = e.data as T.OnboardingDirectorSessionResponseType[];

        internalLogger?.error?.('OD GET resumable sessions response', result);

        return result;
      })
      .catch((error) => {
        internalLogger?.error?.(
          'OD GET resumable sessions error response',
          error
        );
        throw error;
      });
  }
}

export default OnboardingDirector;
