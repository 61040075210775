import { TokenType, UserContextEnum } from '../interface/types';

export const getTokenContextBasedOnTypeName = (
  tokenTypeName: TokenType
): UserContextEnum => {
  const orglessTokenTypes = [
    TokenType.deprecatedOrglessToken,
    TokenType.orglessToken
  ];

  if (orglessTokenTypes.includes(tokenTypeName)) {
    return UserContextEnum.orgless;
  }

  return UserContextEnum.organization;
};
