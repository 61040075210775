import * as T from './types';
import routesService from '../../../services/RoutesService';

export default class Routes {
  getRoutes(): T.RouteType[] {
    return routesService.getRoutes();
  }

  findRouteByKey(key: string): T.RouteType {
    return routesService.findRouteByKey(key);
  }

  findRouteByPath(path: string): T.RouteType {
    return routesService.findRouteByPath(path);
  }
}
