import { JarvisWebHttpClient } from '@jarvis/web-http';
import { Stack } from '../../../../types/stratus';
import { ProgramInfoResponseType } from './types';

export default async function getProgramInfo(options) {
  const { authProvider, stack, tenantResourceId, userResourceId } =
    options || {};
  const urlEnv = (() => {
    const stackToCheck = Stack?.[stack] || stack;
    switch (stackToCheck) {
      case Stack.prod:
        return '';
      case Stack.stage:
        return 'stage-';
      default:
        return 'pie-';
    }
  })();

  const baseUrl = `https://${urlEnv}us1.api.ws-hp.com/ucde/ucde/v2/ecosystem/accountmgtsvc`;

  const httpClient = new JarvisWebHttpClient({
    defaultAuthProvider: authProvider,
    defaultBaseURL: baseUrl
  });

  const programInfoDetails: { data: ProgramInfoResponseType } =
    await httpClient.get({
      url: `/accounts/${tenantResourceId}/users/${userResourceId}/programinfos`
    });

  return programInfoDetails;
}
