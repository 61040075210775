export const handleOnActive = (events) => {
  events.triggerEvent('jshell-useractivity-onactive', null);
};

export const handleOnIdle = (events) => {
  events.triggerEvent('jshell-useractivity-onidle', null);
};

export const handleOnHide = (events) => {
  events.triggerEvent('jshell-useractivity-onhide', null);
};

export const handleOnShow = (events) => {
  events.triggerEvent('jshell-useractivity-onshow', null);
};
