import AuthContextEnum from '../services/authTokenService/AuthContextEnum';
import { TokenType } from '../interface/types';
import { getAuthTokenSingleton } from '../interface/v1/AuthToken';
import { IAuthTokenService } from '../services/authTokenService';

let _authTokenService: IAuthTokenService;

export function getStratusAccessToken(tokenTypeName: TokenType): string {
  const authTokenService = getAuthTokenSingleton();
  let authContext;
  if (
    [TokenType.deprecatedOrglessToken, TokenType.orglessToken].includes(
      tokenTypeName
    )
  ) {
    authContext = AuthContextEnum.tenantless;
  } else if (
    [TokenType.orgedToken, TokenType.deprecatedOrgedToken].includes(
      tokenTypeName
    )
  ) {
    authContext = AuthContextEnum.tenant;
  } else {
    authContext = _authTokenService.getCurrentContext();
  }
  return authTokenService.getToken(authContext)?.token || '';
}

export function setAuthTokenService(authTokenService: IAuthTokenService): void {
  _authTokenService = authTokenService;
}
