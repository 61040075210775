import * as T from './types';

import { AuthProviderV2Type } from '../types';
import TenantStrategy from './TenantStrategy';

import { AuthContextEnum } from '../../authTokenService';
import { ISessionService } from '../../session';
import { TenantStrategyEnum } from './strategy';
import { OneCloudAPIDefaultRequestParams } from '../../../clients/oneCloud/types';
import {
  DEFAULT_PAGINATION_PARAM_LIMIT,
  DEFAULT_PAGINATION_PARAM_OFFSET
} from '../../../clients/oneCloud/relationshipMgt/constants';
import OnecloudRegistryRelationshipClient from '../../../clients/oneCloud/registryRelationship/OnecloudRegistryRelationshipClient';

type OrganizationProperties = {
  fallback?: string;
  filter?: {
    role: 'admin' | 'user' | 'all';
    tenantType: string[];
  };
};

// FIXME: This is a WORKAROUND meanwhile the AggregationAPI isn't available.
// TODO: When the AggregationAPI is available change this to the implementation of AggregationAPICoptorOrganizationTenantHandler
export default class OnecloudOrganizationTenantHandler
  implements TenantStrategy
{
  private _strategy = TenantStrategyEnum.onecloudOrganization;
  private _options: OrganizationProperties;
  private _assetReference: string;
  private _oneCloudRegistryRelationshipSvcClient: OnecloudRegistryRelationshipClient;
  private _sessionService: ISessionService;
  private _cachedPaginatedTenantList: T.PaginatedTenantVisualizationCacheType[] =
    [];
  private _authProvider: AuthProviderV2Type;

  _stack: T.StackType;

  constructor({
    stack,
    options,
    assetReference,
    sessionService,
    authProvider
  }: T.TenantHandlerStrategyType) {
    this._stack = stack;
    this._authProvider = authProvider;
    this._options = options;
    this._assetReference = assetReference;
    this._sessionService = sessionService;

    this._oneCloudRegistryRelationshipSvcClient =
      new OnecloudRegistryRelationshipClient({
        stack: this._stack,
        authProvider: this._authProvider
      });
  }

  public getTenantById = async (
    tenantId: string
  ): Promise<T.TenantVisualizationType> => {
    const tenantsPaginatedLists = this._cachedPaginatedTenantList.map(
      (paginations) => paginations.tenants
    );
    let tenant = tenantsPaginatedLists
      ?.flat()
      ?.find((tenant) => tenant.id === tenantId);

    if (!tenant) {
      const tenantNode =
        await this._oneCloudRegistryRelationshipSvcClient.getNode({
          nodeId: tenantId
        });

      tenant = {
        id: tenantNode.nodeId,
        name: tenantNode.nodeId,
        type: tenantNode?.roleId
      };
    }

    return tenant;
  };

  public async getTenantList(
    refresh?: boolean
  ): Promise<T.TenantVisualizationType[]> {
    const tenantsCachedLists = refresh
      ? []
      : this._cachedPaginatedTenantList.map(
          (paginations) => paginations.tenants
        );
    let tenants;
    if (!tenantsCachedLists.length) {
      const paginatedTenantList = await this.getPaginatedTenantList({
        page: 0
      });
      tenants = paginatedTenantList?.tenants;
    } else {
      tenants = tenantsCachedLists?.flat();
    }
    //TODO console.warn to indicate that this list have only the nodes that were found by the pagination one
    return tenants;
  }

  public async getPaginatedTenantList(
    options: T.GetPaginatedTenantListOptionsType
  ): Promise<T.PaginatedTenantVisualizationType> {
    let result: T.PaginatedTenantVisualizationType;
    result = this._cachedPaginatedTenantList.find(
      //TODO: analyze semantic of currentPage at cached tenant list
      (tenantPage) => tenantPage.currentPage === options.page
    );
    if (!result) {
      result = await this._getPaginatedTenantListData(options);
      this._cachedPaginatedTenantList.push({
        parentTenantId: options.tenantId,
        ...result
      });
    }
    return result;
  }

  public getAssetReference(): string {
    return this._assetReference;
  }

  public getStrategy = (): TenantStrategyEnum => {
    return this._strategy;
  };

  public setTenant = async (
    id: string,
    authContext: AuthContextEnum
  ): Promise<void> => {
    await this._sessionService.exchangeTenantToken(
      id,
      authContext,
      TenantStrategyEnum.onecloudOrganization
    );
  };

  public async _getPaginatedTenantListData(
    options: T.GetTenantListOptionsType
  ): Promise<T.PaginatedTenantVisualizationType> {
    const { page, paginationSize } = options;
    const _options: OneCloudAPIDefaultRequestParams = {};
    if (page || paginationSize) {
      const limit = paginationSize || DEFAULT_PAGINATION_PARAM_LIMIT;
      const offset = limit * page || DEFAULT_PAGINATION_PARAM_OFFSET;
      _options.params = {
        limit,
        offset
      };
    }
    const personOrgs =
      await this._oneCloudRegistryRelationshipSvcClient.getPersonDomains(
        _options
      );

    const tenants: T.TenantVisualizationType[] = personOrgs?.content?.map(
      (nodeData) => {
        return {
          id: nodeData.nodeId,
          // TODO: In this temporary approach we're sending tenantId as name
          name: nodeData.nodeId,
          // TODO: And roleType as tenantType
          type: nodeData?.roleType
        };
      }
    );

    return {
      totalPages: personOrgs?.totalPages,
      currentPage: personOrgs?.pageable?.pageNumber,
      tenants
    };
  }

  public getAuthProvider(): AuthProviderV2Type {
    return this._authProvider;
  }

  public isRequiredToNavigate = (): boolean => {
    return true;
  };

  public getFallback = (): string => {
    return this?._options?.fallback;
  };
}
