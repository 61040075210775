import {
  customerIdName,
  enableAST,
  organizationIdName,
  tenantIdName
} from '../config/constants';
import { TokenType } from '../interface/types';
import { deleteCookie } from './cookies';
import { removeItemLocalStorage } from './localStorage';

export const clearUserData = (): void => {
  deleteCookie('shellOnboardingFinished');
  deleteCookie(tenantIdName);
  removeItemLocalStorage(organizationIdName);
  removeItemLocalStorage(customerIdName);
  removeItemLocalStorage(TokenType.orglessToken);
  removeItemLocalStorage(TokenType.orgedToken);
  removeItemLocalStorage(enableAST);

  // Removing deprecrated cookies
  deleteCookie(TokenType.deprecatedOrglessToken, '/', false);
  deleteCookie(TokenType.deprecatedOrgedToken, '/', false);
};
