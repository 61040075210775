import { HandledTenant } from './types';

export enum TenantEvents {
  SET_TENANT_HANDLER_KEY = 'setTenantHandlerKey',
  SET_TENANT = 'setTenant'
}

export default class TenantObserver {
  private static _listeners: Record<string, Function[]> = {};

  public static subscribe = (event: TenantEvents, cb: Function): void => {
    if (!this._listeners?.[`${event}`]) this._listeners[`${event}`] = [];
    !this._listeners?.[`${event}`]?.includes(cb) &&
      this._listeners?.[`${event}`]?.push(cb);
  };

  public static unsubscribe = (event: TenantEvents, cb: Function): void => {
    this._listeners[`${event}`] = this._listeners?.[`${event}`]?.filter(
      (c) => c != cb
    );
  };

  public static notify = async (
    event: TenantEvents,
    tenants: HandledTenant[]
  ): Promise<void> => {
    const notifyEvent = this._listeners?.[`${event}`];
    if (!notifyEvent) return;
    for (const callback of notifyEvent) {
      await callback(tenants);
    }
  };
}
