export class PromiseHelper {
  private pendingPromises: Map<string, Promise<any>> = new Map();

  public getPendingPromise(key: string) {
    return this.pendingPromises.get(key);
  }

  public setPendingPromise(key: string, value: Promise<any>) {
    //Autodelete the promise after resolve
    if (this.getPendingPromise(key) !== value) {
      value.finally(() => {
        if (this.getPendingPromise(key) === value) {
          this.deletePendingPromise(key);
        }
      });
      this.pendingPromises.set(key, value);
    }
  }

  public deletePendingPromise(key: string) {
    return this.pendingPromises.delete(key);
  }
}
