import { StratusClient } from '@jarvis/web-stratus-client/dist/src/clients/stratus-client';
import { Stack } from '../../../types/stratus';
import { getStratusBaseUrl } from '../../stackUtils';
import * as T from './types';

export default class AccountsClient extends StratusClient {
  apiName = 'ws-hp.com/ucde/ucde/v2/ecosystem/accountmgtsvc';
  apiVersion = '2';
  accountsEndpoint = '/accounts';

  public static getBaseUrl(stack: Stack): string {
    return `${getStratusBaseUrl(stack)}/ucde/ucde/v2/ecosystem/accountmgtsvc`;
  }

  public async createAccount(
    options: T.CreateAccountOptionsType
  ): Promise<T.CreateAccountResponseType> {
    const response = await this.jarvisWebHttpInstance.post({
      url: this.accountsEndpoint,
      data: options
    });
    return response.data as T.CreateAccountResponseType;
  }
}
