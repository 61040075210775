import { ManifestServiceFeatureFlagsType } from '../../../types/manifest';
import {
  IFeatureFlagService,
  LaunchDarklyFeatureFlagService,
  LaunchDarklyProjectType
} from '../../../services/featureFlagService';
import { IUserService } from '../../../services/userService';
import eventService from '../../../services/eventService';
import { webCryptoApiEncryptService } from '../../../services/encryptService';

type CreateFeatureFlagServiceSingletonPropsType = {
  manifestFeatureFlag: ManifestServiceFeatureFlagsType;
  userService: IUserService;
  appName: string;
};

let featureFlagServiceSingleton: IFeatureFlagService;

async function _createFeatureFlagService(
  options: CreateFeatureFlagServiceSingletonPropsType
): Promise<IFeatureFlagService> {
  const { manifestFeatureFlag, userService, appName } = options;

  const projectSettingsList =
    convertManifestFeatureFlagIntoFeatureFlagParams(manifestFeatureFlag);

  const featureFlagService = new LaunchDarklyFeatureFlagService({
    eventService,
    userService,
    projectSettingsList,
    encryptService: webCryptoApiEncryptService,
    appName
  });

  return featureFlagService;
}

export function getFeatureFlagServiceSingleton(): IFeatureFlagService {
  return featureFlagServiceSingleton;
}

export default async function initializeFeatureFlagServiceSingleton(
  options: CreateFeatureFlagServiceSingletonPropsType
): Promise<IFeatureFlagService> {
  if (featureFlagServiceSingleton) return featureFlagServiceSingleton;

  featureFlagServiceSingleton = await _createFeatureFlagService(options);

  await featureFlagServiceSingleton.init({});

  return featureFlagServiceSingleton;
}

function convertManifestFeatureFlagIntoFeatureFlagParams(
  manifestFeatureFlag: ManifestServiceFeatureFlagsType
): LaunchDarklyProjectType[] {
  const ldParamsList = manifestFeatureFlag?.clients?.map((c) => {
    const { key, clientId } = c;
    return { key, clientId };
  });
  return ldParamsList;
}
