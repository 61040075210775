import { internalLogger } from './../logger';

import {
  getItemSessionStorage,
  setItemSessionStorage,
  removeItemSessionStorage
} from '../../../utils/sessionStorage';

import { setItemLocalStorage } from '../../../utils/localStorage';

import {
  organizationIdName,
  organizationIdNameViaParam,
  tempOrganizationIdName
} from '../../../config/constants';

/**
 *  Checking that the Organization Tenant Id was passed as a query parameter
 *  on URL. If true, store it on session storage and remove it from the URL.
 */
export function checkingOrgIdOnQueryParams(navigation) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (urlSearchParams.has(organizationIdNameViaParam)) {
    const tenantOrgId = urlSearchParams.get(organizationIdNameViaParam);
    urlSearchParams.delete(organizationIdNameViaParam);

    internalLogger?.log('_tenantOrgId', tenantOrgId);
    setItemSessionStorage(tempOrganizationIdName, tenantOrgId);

    navigation.replace({
      ...navigation.location,
      search: urlSearchParams.toString()
    });
  }
}

/**
 * In case of the user just logged in and we have stored a organization tenant
 * Id candidate. We retrieve it from session Storage and call the exchange
 * token.
 * Premises:
 * 1. the user must be logged in;
 * 2. the org selector should be activated;
 * 3. the org tenant id should belongs to the user valid org list.
 */
export async function checkingOrgUsingTempStorage({
  isLoggedIn,
  _isEnabledAtManifest,
  init,
  getTenantList,
  setOrgAsDefault
}) {
  if (!isLoggedIn) return;

  if (!_isEnabledAtManifest) return;

  const tempOrganizationId = getItemSessionStorage(tempOrganizationIdName);
  if (!tempOrganizationId) return;

  internalLogger?.log('trying to change to org id:', tempOrganizationId);
  // As having a tenant Id candidate, we should request the domain list
  // to check if is a valid tenant org (inside the org list);
  removeItemSessionStorage(tempOrganizationIdName);
  await init(false);

  const orgList = getTenantList();
  if (!orgList.length) return;

  const filtered = orgList.find((org) => org.nodeId === tempOrganizationId);

  if (filtered?.nodeId) {
    internalLogger?.log('tenant org id was found. ');
    setItemLocalStorage(organizationIdName, tempOrganizationId);
    await setOrgAsDefault(false);
  }
}
