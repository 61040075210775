import MicrofrontendRouter from '../interface/v1/MicrofrontendRouter';

type StartMicrofrontendRouterProps = {
  microfrontendRouter: MicrofrontendRouter;
};

export default class StartMicrofrontendRouter {
  private _microfrontendRouter: MicrofrontendRouter;

  constructor(dependecies: StartMicrofrontendRouterProps) {
    this._microfrontendRouter = dependecies.microfrontendRouter;
  }

  public async start(): Promise<void> {
    await this._microfrontendRouter?.start?.();
  }
}
