import { InterfacesInitializerStatePropsType } from '../../types';
import * as T from './types';

export default ({
  manifest
}: InterfacesInitializerStatePropsType): T.UserSessionType => {
  // TODO check if needs to verify this in somewhere.
  // const { enable } = manifest?.services?.login || {};
  const loginPathname = '/login';

  function redirectToLogin(params?: T.RedirectToLoginParams) {
    const loginPath = getLoginPath(params);
    window.location.href = loginPath;
  }

  function getURLSearchParams(
    params?: string | Record<string, any>
  ): URLSearchParams {
    // URLSearchParams already encode all query params, if provide encoded value it will encode twice
    const decodedParams = {};
    if (typeof params === 'string') {
      const stringParams = params;
      if (stringParams?.startsWith?.('?')) {
        stringParams?.substring(1);
      }
      const paramsList = stringParams.split('&');

      paramsList.forEach((param) => {
        // Split only the first '='
        const [key, value] = param.split(/=(.*)/s);
        if (key && value) {
          decodedParams[key] = decodeURIComponent(value);
        }
      });
    } else if (typeof params === 'object') {
      Object.keys(params).forEach((key) => {
        const value = params[key];
        if (key && value) {
          decodedParams[key] = decodeURIComponent(value);
        }
      });
    }

    return new URLSearchParams(decodedParams);
  }

  function getLoginPath(params?: T.RedirectToLoginParams) {
    const urlSearchParams = getURLSearchParams(window.location.search);

    getURLSearchParams(params).forEach((value, key) => {
      let thisKey = key;
      let thisValue = value;

      if (
        key === 'loginType' &&
        (value as T.RedirectToLoginParamsObjectType['loginType']) ===
          'createAccount'
      ) {
        thisKey = 'target';
        thisValue = 'create';
      }

      if (value && key) {
        urlSearchParams.set(thisKey, thisValue);
      }
    });

    if (!urlSearchParams.get('postLoginRedirect')) {
      const defaultPostLoginRedirect = window.location.pathname.endsWith(
        loginPathname
      )
        ? '/'
        : window.location.pathname + window.location.search;

      urlSearchParams.set('postLoginRedirect', defaultPostLoginRedirect);
    }

    urlSearchParams.forEach((value, key) => {
      if (!value) {
        urlSearchParams.delete(key);
      }
    });

    const searchParams = urlSearchParams?.toString?.() || '';

    let result = loginPathname;

    if (searchParams?.length > 0) {
      result += `?${searchParams}`;
    }

    return result;
  }

  return { redirectToLogin, getLoginPath };
};
