/* eslint-disable security/detect-object-injection */
import { JarvisWebHttpClient } from '@jarvis/web-http';
import { TenantStrategyEnum } from '../../../services/tenantHandler/strategy/strategy';
import {
  convertTenantStrategyToSessionAPITenantStrategy,
  converAuthContextToSessionAPITenantStrategy
} from '../SessionAPIUtils';
import IRefreshClient, {
  ShellSessionRefreshRequestType,
  ShellSessionRefreshResponseType
} from './IRefreshClient';

export default class RefreshV3Client implements IRefreshClient {
  private jarvisWebHttpInstance: JarvisWebHttpClient;
  private apiName = '/api/session';
  private apiVersion = '/v3';
  private tokenEndpoint = '/token';

  private _baseUrl: string;

  constructor(baseUrl: string) {
    this.jarvisWebHttpInstance = new JarvisWebHttpClient();
    this._baseUrl = `${baseUrl}${this.apiName}${this.apiVersion}`;
  }

  public async refresh(
    shellSessionRefreshResquestType: ShellSessionRefreshRequestType
  ): Promise<ShellSessionRefreshResponseType> {
    const shellTenantsData = {};
    for (const key in shellSessionRefreshResquestType?.tenantsIdMap) {
      const _key = convertTenantStrategyToSessionAPITenantStrategy(
        TenantStrategyEnum[key]
      );
      shellTenantsData[_key] =
        shellSessionRefreshResquestType.tenantsIdMap[key];
    }

    const tenantType = converAuthContextToSessionAPITenantStrategy(
      shellSessionRefreshResquestType.authContext
    );

    const dataRequest = {
      ...shellSessionRefreshResquestType,
      tenantType,
      shellTenantsData
    };
    const { data } = await this.jarvisWebHttpInstance.post({
      url: `${this._baseUrl}${this.tokenEndpoint}`,
      data: dataRequest
    });
    return data as ShellSessionRefreshResponseType;
  }
}
