import { pathToRegexp } from 'path-to-regexp';
import { NavigationRoutesType } from '../types/manifest';
import { NavigationInterfaceType } from '../interface/v1/navigation/type';

type GetCurrentRoutePropsType = {
  routes: NavigationRoutesType[];
  navigation: NavigationInterfaceType;
};

export default function getCurrentRoute({
  navigation,
  routes
}: GetCurrentRoutePropsType): NavigationRoutesType | undefined {
  return routes?.find?.((route) => {
    const path = (() => {
      const arrayManifestPath = Array.isArray(route?.path)
        ? route?.path
        : [route?.path];

      return arrayManifestPath.map((pathname) => {
        const pathWithSearch = navigation.createHref({ pathname });

        return pathWithSearch?.split('?')?.[0];
      });
    })();

    if (
      path.some((value) => pathToRegexp(path).test(window.location.pathname))
    ) {
      return true;
    } else if (route?.subRoutes?.length > 0) {
      return getCurrentRoute({ routes: route?.subRoutes, navigation });
    } else {
      return false;
    }
  });
}
