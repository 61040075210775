import { PromiseReturnType } from '../types/typeHandlers';
import * as T from './types';
import initializeShellInterfaceV1 from './v1';
import v1Auth from './v1/auth';
import initializeShellInterfaceV2 from './v2';
import v2Auth from './v2/auth';
import webServiceRouting from '../services/webServiceRouting';
import { WebServiceType } from '../services/webServiceRouting/types';

export type InterfacesType = {
  v1: PromiseReturnType<typeof initializeShellInterfaceV1>;
  v2: PromiseReturnType<typeof initializeShellInterfaceV2>;
  _shell: {
    init: (
      initializerState: T.InterfacesInitializerStatePropsType
    ) => Promise<void>;
    initialized: boolean;
    loading: boolean;
    error: boolean;
    initializers: {
      authProvider: {
        v1: typeof v1Auth;
        v2: typeof v2Auth;
      };
    };
  };
};

const interfaces = {
  /** This property is only used internally, do not use this */
  _shell: {
    initialized: false,
    loading: false,
    error: false,
    initializers: {
      authProvider: {
        v1: v1Auth,
        v2: v2Auth
      }
    },
    init: async (initializerState: T.InterfacesInitializerStatePropsType) => {
      if (!interfaces._shell?.initialized || !interfaces._shell?.loading) {
        // TODO: do a better solution to inject this dependencies
        webServiceRouting.addServices(
          initializerState?.manifest?.services?.onboarding?.stages?.map?.<WebServiceType>(
            ({ serviceId, ...stage }) => ({
              ...stage,
              id: serviceId,
              serviceType: 'web'
            })
          )
        );

        interfaces._shell.initialized = true;
        interfaces._shell.loading = true;
        interfaces._shell.error = false;
        const v1 = await initializeShellInterfaceV1(initializerState);
        const v2 = await initializeShellInterfaceV2(initializerState);

        interfaces.v1 = v1;
        interfaces.v2 = v2;

        interfaces._shell.loading = false;
      }
    }
  }
} as InterfacesType;

export default interfaces;
