import { JarvisWebHttpClient } from '@jarvis/web-http';
import { Stack } from '../../../../types/stratus';
import * as T from './types';

export default async function createNode(options: T.CreateNodeOptionsType) {
  const { authProvider, stack, data } = options || {};
  const urlEnv = (() => {
    switch (stack) {
      case Stack.prod:
        return '';
      case Stack.stage:
        return 'stage-';
      default:
        return 'pie-';
    }
  })();

  const baseUrl = `https://${urlEnv}us1.api.ws-hp.com/ucde/ucde/v2/ecosystem/accountmgtsvc/accounts`;

  const httpClient = new JarvisWebHttpClient({
    defaultAuthProvider: authProvider,
    defaultBaseURL: baseUrl
  });

  const tenant = await httpClient.post({ data }).then(
    (r) =>
      ({ nodeId: r.data.resourceId } as {
        nodeId: T.TenantNodeType['nodeId'];
      })
  );

  return tenant;
}
