import { StratusClient } from '@jarvis/web-stratus-client/dist/src/clients/stratus-client';
import { Stack } from '../../../types/stratus';
import { getStratusBaseUrl } from '../../stackUtils';
import {
  DEFAULT_INITIAL_CURRENT_PAGE as DEFAULT_INITIAL_PAGE,
  DEFAULT_PAGINATION_SIZE_GET_NODE_PERSON_DOMAIN
} from './constants';
import * as T from './types';

export default class NodeClient extends StratusClient {
  apiName = 'ws-hp.com/tenancy-registry';
  apiVersion = '1';
  nodeEndpoint = '/node';

  public static getBaseUrl(stack: Stack): string {
    return `${getStratusBaseUrl(stack)}/tenancy-registry/v1`;
  }

  public async getNodeAdministersCrossUses(
    options: T.GetNodeAdministersCrossUsesOptionsType
  ): Promise<T.GetNodeResponseType> {
    const response = await this.jarvisWebHttpInstance.get({
      url: `${this.nodeEndpoint}/${options.tenantId}/administers:crossuses`,
      params: {
        page: options.page || DEFAULT_INITIAL_PAGE,
        size:
          options.paginationSize ||
          DEFAULT_PAGINATION_SIZE_GET_NODE_PERSON_DOMAIN
      }
    });
    return response.data as T.GetNodeResponseType;
  }

  public async getNodeByTentantId(
    options: T.GetNodeByIdOptions
  ): Promise<T.TenantNodeType> {
    const response = await this.jarvisWebHttpInstance.get({
      url: `${this.nodeEndpoint}/${options.tenantId}`
    });
    return response.data as T.TenantNodeType;
  }
}
