import IAuthzClient from '../../../clients/stratus/authz/IAuthzClient';
import bindAllMethods from '../../../utils/bindAllMethods';
import { ILogoutClient } from '../../../clients/shell/logout';
import { Stack } from '../../../types/stratus';
import ILogoutService from './ILogoutService';
import { ISessionService } from '..';

export type StratusLogoutServiceParameters = {
  stack: Stack;
  logoutClient: ILogoutClient;
  authzClient: IAuthzClient;
  sessionService: ISessionService;
};

export default class StratusLogoutService implements ILogoutService {
  private _stack: Stack;
  private _logoutClient: ILogoutClient;
  private _authzClient: IAuthzClient;
  private _sessionService: ISessionService;

  constructor({
    stack,
    logoutClient,
    authzClient,
    sessionService
  }: StratusLogoutServiceParameters) {
    this._stack = stack;
    this._logoutClient = logoutClient;
    this._authzClient = authzClient;
    this._sessionService = sessionService;
    bindAllMethods(this);
  }

  public async logout(): Promise<void> {
    const postLogoutRedirectUrl = this._logoutClient.configureLogoutUrl(
      location.origin
    );
    const redirectTo = this._authzClient.configureLogoutUrl(
      this._stack,
      this._sessionService.getIdToken(),
      postLogoutRedirectUrl,
      'shell'
    );

    return new Promise(() => {
      window.location.href = redirectTo;
    });
  }
}
