import bindAllMethods from '../../utils/bindAllMethods';
import UserMgtClient from '../../clients/stratus/usersMgt/UserMgtClient';
import { decodeJWTPayload } from '../../utils/jwt';
import { ISessionService } from '../session';
import IUserService from './IUserService';
import { UserDataType } from './types';

export type StratusUserServiceParams = {
  sessionService: ISessionService;
  userMgtClient: UserMgtClient;
};

export default class StratusUserService implements IUserService {
  private _sessionService: ISessionService;
  private _userMgtClient: UserMgtClient;
  private _userData: UserDataType;

  constructor({ sessionService, userMgtClient }: StratusUserServiceParams) {
    this._sessionService = sessionService;
    this._userMgtClient = userMgtClient;
    bindAllMethods(this);
  }

  public async getEmail(): Promise<string> {
    return decodeJWTPayload(this._sessionService?.getIdToken?.())?.email;
  }

  public async getUserData(): Promise<UserDataType> {
    if (!this._userData) {
      await this.refresh();
    }
    return this._userData;
  }

  public async refresh(): Promise<void> {
    if (!this._sessionService.isLoggedIn()) return;
    const userApiData = await this._userMgtClient.getUsersMe();
    const {
      idpType,
      email,
      phoneNumber,
      familyName,
      givenName,
      userName,
      locale
    } = userApiData;

    this._userData = {
      idpType,
      email,
      phoneNumber,
      familyName,
      givenName,
      userName,
      locale
    };
  }
}
