import splunkRum from '../../../services/splunkRum';
import bindAllMethods from '../../../utils/bindAllMethods';
import Layout from '../Layout';
import { MicrofrontendRouterOperationStateType } from './types';

type OperationStateBaseOptionsType = {
  overwrite?: boolean;
};

export default class MicrofrontendRouterOperationStateBuilder {
  private state: MicrofrontendRouterOperationStateType = {};
  private layoutInterface: Layout;

  constructor(options: {
    state?: MicrofrontendRouterOperationStateType;
    layoutInterface: Layout;
  }) {
    bindAllMethods(this);
    this.layoutInterface = options.layoutInterface;

    if (options?.state) {
      this.state = options?.state;
    }
  }

  preFetchLayoutByKey(layoutKey: string): void {
    if (layoutKey) {
      const thisLayout = this.layoutInterface.getLayoutByKey(layoutKey);

      this.preFetchAssetReference(thisLayout?.assetReference);
    }
  }

  private _prefetchedList: Map<string, boolean> = new Map();
  preFetchAssetReference(assetReference: string): void {
    if (assetReference && !this._prefetchedList.get(assetReference)) {
      this._prefetchedList.set(assetReference, true);
      const span = splunkRum?.startSpan?.('jshell-router', {
        'workflow.name': `downloading-mfe-${assetReference}`,
        'microfrontend.assetReference': assetReference
      });

      System.import(assetReference).finally(() => {
        span?.end?.();
      });
    }
  }

  getState(): MicrofrontendRouterOperationStateType {
    return this.state;
  }

  setContent(
    content: MicrofrontendRouterOperationStateType['content'],
    options?: OperationStateBaseOptionsType
  ): void {
    const state = this.getState();
    const isDataValid = typeof content?.enable === 'boolean';
    const isCurrentDataValid = typeof state?.content?.enable === 'boolean';

    if ((isDataValid && !isCurrentDataValid) || options?.overwrite) {
      this.preFetchAssetReference(content?.assetReference);
      this.state.content = content;
    }
  }

  setLayout(
    layout: MicrofrontendRouterOperationStateType['layout'],
    options?: OperationStateBaseOptionsType
  ): void {
    const state = this.getState();
    const isDataValid = typeof layout?.enable === 'boolean';
    const isCurrentDataValid = typeof state?.layout?.enable === 'boolean';

    if ((isDataValid && !isCurrentDataValid) || options?.overwrite) {
      this.preFetchAssetReference(layout?.assetReference);
      this.state.layout = layout;
    }
  }

  setModalContent(
    modalContent: MicrofrontendRouterOperationStateType['modalContent'],
    options?: OperationStateBaseOptionsType
  ): void {
    const state = this.getState();
    const isDataValid = typeof modalContent?.enable === 'boolean';
    const isCurrentDataValid = typeof state?.modalContent?.enable === 'boolean';

    if ((isDataValid && !isCurrentDataValid) || options?.overwrite) {
      this.preFetchAssetReference(modalContent?.assetReference);
      this.state.modalContent = modalContent;
    }
  }

  setEndProcessChain(
    endProcessChain: MicrofrontendRouterOperationStateType['endProcessChain'],
    options?: OperationStateBaseOptionsType
  ): void {
    const state = this.getState();
    const isDataValid = endProcessChain === true;
    const isCurrentDataValid = state?.endProcessChain === true;

    if ((isDataValid && !isCurrentDataValid) || options?.overwrite) {
      this.state.endProcessChain = endProcessChain;
    }
  }

  setLayoutByKey(
    layoutKey: string | false,
    options?: OperationStateBaseOptionsType
  ): void {
    let layout: MicrofrontendRouterOperationStateType['layout'];

    if (layoutKey && typeof layoutKey === 'string') {
      const thisLayout = this.layoutInterface.getLayoutByKey(layoutKey);

      layout = {
        ...thisLayout,
        enable: !!thisLayout
      };
    } else if (layoutKey === false) {
      layout = {
        enable: true
      };
    }

    this.setLayout(layout, options);
  }

  setRedirectTo(
    redirectTo: MicrofrontendRouterOperationStateType['redirectTo'],
    options?: OperationStateBaseOptionsType
  ): void {
    const state = this.getState();
    if ((redirectTo && !state?.redirectTo) || options?.overwrite) {
      this.state.redirectTo = redirectTo;
    }
  }
}
