import { JarvisWebHttpClient } from '@jarvis/web-http';
import { AuthProviderV2Type } from '../../../interface/v1/orgSelector/types';
import { IAuthTokenService } from '../../../services/authTokenService';
import { decodeJWTPayload } from '../../../utils/jwt';
import { AccessPoliciesEndPointReturnType } from './types';

export async function getAccessPolicies(
  authProvider: AuthProviderV2Type,
  authTokenService?: IAuthTokenService
) {
  const httpClient = new JarvisWebHttpClient({
    defaultAuthProvider: authProvider
  });

  const { token: stratusToken } = authTokenService.getToken();
  const stratusJson = decodeJWTPayload(stratusToken);
  const policies = Object.values(stratusJson.access_policies);
  //TODO: v1/scopes doesn't use the list of access policies as a parameter. It is extracted from the token
  const bodyRequest = {
    policies: policies
  };

  const payload: { data: AccessPoliciesEndPointReturnType } =
    await httpClient.get({
      url: `/api/session/v1/scopes`,
      data: bodyRequest
    });

  return payload?.data;
}
