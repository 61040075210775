import { SESSION_API_BASE_URL, SESSION_API_PROVIDER_URL } from '../constants';
import { JarvisWebHttpClient } from '@jarvis/web-http';
import {
  GetProviderListParam,
  GetProviderListResponseType,
  ProviderClientParam
} from './types';

export default class ProviderClient {
  private jarvisWebHttpInstance: JarvisWebHttpClient;
  private apiName = SESSION_API_BASE_URL;
  private providersEndpoint = SESSION_API_PROVIDER_URL;

  private _baseUrl: string;
  private _clientId: string;

  constructor({ clientId, baseUrl }: ProviderClientParam) {
    this._clientId = clientId;
    this._baseUrl = baseUrl;
    this.jarvisWebHttpInstance = new JarvisWebHttpClient();
  }

  public async getProviderList(
    options?: GetProviderListParam
  ): Promise<GetProviderListResponseType> {
    const { email, provisionUser } = options || {};

    const params = {
      client_id: this._clientId,
      email: email || ''
    };

    if (provisionUser) params['provision_user'] = provisionUser;

    const { data } = await this.jarvisWebHttpInstance.get({
      url: `${this._baseUrl}${this.apiName}${this.providersEndpoint}`,
      params
    });
    return data as GetProviderListResponseType;
  }
}
