import { localePreferenceCookieName } from '../config/constants';
import { ManifestServiceLocalizationType } from '../types/manifest';
import { getCookie } from './cookies';
import getCaseInsensitiveArrayValue from './getCaseInsensitiveArrayValue';
import getNavigatorLocale from './getNavigatorLocale';
import getObjectValueByInsensitiveKeyName from './getObjectValueByInsensitiveKeyName';

export type GetRelativePathOptionsType = {
  manifestBasePath?: string;
  localization: ManifestServiceLocalizationType;
};

export default function urlPathHandler(options: GetRelativePathOptionsType) {
  const { manifestBasePath = '/', localization } = options || {};
  const isLocalizationEnabled = !!localization?.enable;
  const removeLocaleUrl = !!localization?.removeLocaleUrl;
  const languageList = isLocalizationEnabled
    ? Object.keys(localization?.languages)
    : [];
  const currentPathInformation = getCurrentPathInformation(options);
  const navigatorLocale = getNavigatorLocale();
  const languageFromLocalePreferenceCookie = getCookie(
    localePreferenceCookieName,
    false
  )?.split('-')[0];
  const countryFromLocalePreferenceCookie = getCookie(
    localePreferenceCookieName,
    false
  )?.split('-')[1];
  const pathFallback = getObjectValueByInsensitiveKeyName(
    localization?.fallbacks,
    currentPathInformation?.language
  );
  const navigatorFallback = getObjectValueByInsensitiveKeyName(
    localization?.fallbacks,
    navigatorLocale.language
  );

  const language = (() => {
    if (!isLocalizationEnabled) {
      return undefined;
    }
    let result: string;

    // Get from Path
    if (
      getCaseInsensitiveArrayValue(
        languageList,
        currentPathInformation?.language
      )
    ) {
      result = currentPathInformation?.language;
    } else if (
      getCaseInsensitiveArrayValue(languageList, pathFallback?.language)
    ) {
      result = pathFallback?.language;
    }

    // Get from Localization Preferences Cookie
    else if (
      getCaseInsensitiveArrayValue(
        languageList,
        languageFromLocalePreferenceCookie
      )
    ) {
      result = languageFromLocalePreferenceCookie;
    }

    // Get from navigator
    else if (
      getCaseInsensitiveArrayValue(languageList, navigatorLocale?.language)
    ) {
      result = navigatorLocale?.language;
    } else if (
      getCaseInsensitiveArrayValue(languageList, navigatorFallback?.language)
    ) {
      result = navigatorFallback?.language;
    }

    // Get from default value
    else {
      result = languageList?.[0];
    }

    return result?.toLocaleLowerCase?.();
  })();

  const country = (() => {
    if (!isLocalizationEnabled) {
      return undefined;
    }
    let result: string;
    const countries = getObjectValueByInsensitiveKeyName(
      localization?.languages,
      language
    );

    // Get from path
    if (
      getCaseInsensitiveArrayValue(countries, currentPathInformation.country)
    ) {
      result = currentPathInformation.country;
    } else if (getCaseInsensitiveArrayValue(countries, pathFallback?.country)) {
      result = pathFallback?.country;
    }

    // Get from Localization Preferences Cookie
    else if (
      getCaseInsensitiveArrayValue(countries, countryFromLocalePreferenceCookie)
    ) {
      result = countryFromLocalePreferenceCookie;
    }

    // get from navigator
    else if (
      getCaseInsensitiveArrayValue(countries, navigatorLocale?.country)
    ) {
      result = navigatorLocale?.country;
    } else if (
      getCaseInsensitiveArrayValue(countries, navigatorFallback?.country)
    ) {
      result = navigatorFallback?.country;
    }
    // Get from default value
    else {
      result = countries?.[0];
    }
    return result?.toLocaleLowerCase?.();
  })();

  function createPath(options: {
    language?: string;
    country?: string;
    servicePath?: string;
  }) {
    let result =
      '/' +
      (options?.country || '') +
      '/' +
      (options?.language || '') +
      '/' +
      (manifestBasePath || '') +
      '/' +
      (options?.servicePath || '');

    result = result.replace(new RegExp('/+', 'g'), '/');
    if (result?.endsWith('/') && result.length > 1) {
      result = result.substring(0, result.length - 1);
    }

    return result;
  }

  const relativePathProps = removeLocaleUrl ? {} : { language, country };

  return {
    language,
    country,
    basePath: manifestBasePath,
    relativePath: createPath(relativePathProps),
    getServicePath: () => getCurrentPathInformation(options)?.servicePath,
    createPath
  };
}

function getCurrentPathInformation({
  manifestBasePath
}: GetRelativePathOptionsType) {
  const splitedManifestBasePath = manifestBasePath
    ?.split('/')
    ?.filter((v) => typeof v === 'string' && v.length > 0);
  const currentPath = window.location.pathname;
  const splitedCurrentPath = currentPath
    .split('/')
    ?.filter((v) => typeof v === 'string' && v.length > 0);

  const languagePath = (() => {
    const pathValue = splitedCurrentPath?.[1];
    const validLanguageLength = 2;
    const isValidLanguage =
      pathValue && pathValue.length == validLanguageLength;

    return isValidLanguage ? pathValue : undefined;
  })();

  const countryPath = languagePath ? splitedCurrentPath?.[0] : undefined;
  const localePathServicesQuantity = languagePath ? 2 : 0;

  const haveBasePath = splitedManifestBasePath?.every((v, i) => {
    const index = i + localePathServicesQuantity;
    const currentPathService = splitedCurrentPath?.[index];

    return v && currentPathService && v === currentPathService;
  });

  const manifestPathServicesQuantity = haveBasePath
    ? splitedManifestBasePath?.length || 0
    : 0;

  const servicePath = (() => {
    let result = '';
    let index = localePathServicesQuantity + manifestPathServicesQuantity;

    for (index; index < (splitedCurrentPath?.length || 0); index++) {
      const value = splitedCurrentPath?.[index];
      if (value) {
        result += `/${value}`;
      } else {
        break;
      }
    }

    result += window.location.search + window.location.hash;
    if (result.length > 1) {
      return result;
    }
  })();

  return {
    basePath: haveBasePath ? manifestBasePath : undefined,
    country: countryPath,
    language: languagePath,
    servicePath
  };
}
