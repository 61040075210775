const encodeProperty = (property) =>
  encodeURIComponent(property).replace(/[-.+*]/g, '\\$&');

export const getCookie = (cookieName, encoded = true) => {
  const { cookie = '' } = document;
  // From https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
  // the craft routing stuff references the "gnb_locale" cookie, that will be our cookie-source of language truth
  const handledCookieName = handleCookieName(cookieName, encoded);
  const contentLanguage =
    decodeURIComponent(
      cookie.replace(
        new RegExp(
          `(?:(?:^|.*;)\\s*${handledCookieName}\\s*\\=\\s*([^;]*).*$)|^.*$`
        ),
        '$1'
      )
    ) || '';

  const cookieContent =
    Array.isArray(contentLanguage) && contentLanguage.includes(',')
      ? contentLanguage.split(',')[0]
      : contentLanguage;

  return cookieContent;
};

export const deleteCookie = (cookieName, path = '/', encoded = true) => {
  document.cookie = `${handleCookieName(
    cookieName,
    encoded
  )}=""; path=${path}; max-age=-1;`;
};

export const setCookie = (
  cookieName,
  cookieValue,
  age = 31536000 as number | string,
  encoded = true
) => {
  if (getCookie(cookieName, encoded)) {
    deleteCookie(cookieName, undefined, encoded);
  }
  document.cookie = `${handleCookieName(
    cookieName,
    encoded
  )}=${handleCookieValue(cookieValue, encoded)}; max-age=${age}; path=/;`;
};

function handleCookieValue(cookieValue: string, encoded: boolean): string {
  return encoded ? encodeProperty(cookieValue) : cookieValue;
}

function handleCookieName(cookieName: string, encoded: boolean): string {
  return encoded ? encodeProperty(cookieName) : cookieName;
}
