import { JarvisWebHttpClient } from '@jarvis/web-http';
import { AuthProviderType } from '../../../../interface/v1/orgSelector/types';
import { Stack } from '../../../../types/stratus';
import { UserWithTenantDetailsType } from '../types';

export default async function getUserWithTenantDetails(options: {
  authProvider: AuthProviderType;
  stack: Stack | string;
}) {
  const { authProvider, stack } = options || {};
  const urlEnv = (() => {
    const stackToCheck = Stack?.[stack] || stack;
    switch (stackToCheck) {
      case Stack.prod:
        return '';
      case Stack.stage:
        return 'stage-';
      default:
        return 'pie-';
    }
  })();

  const baseUrl = `https://${urlEnv}us1.api.ws-hp.com`;

  const httpClient = new JarvisWebHttpClient({
    defaultAuthProvider: authProvider,
    defaultBaseURL: baseUrl
  });

  const userAndTenantDetails: { data: UserWithTenantDetailsType } =
    await httpClient.get({
      url: `/v3/usermgtsvc/userwithtenantdetails/me`
    });

  return userAndTenantDetails;
}
