import { isNative as isNativeFromJWeb } from '../services/JWeb';
import { InterfacesType } from '../interface';
import appContextSingleton from '../services/appContext';
import InitializeContextServices from './initializeContextsServices';
import InitializeDeprecatedServices from './initializeDeprecatedServices';
import {
  KeepLatestTenantOnNewTabs,
  lastTenantIdsRepository
} from './KeepLatestTenantOnNewTabs';
import SetFirstApplicationToken from './setFirstApplicationToken';
import StartMicrofrontendRouter from './startMicrofrontendRouter';
import UseTenantIdsFromQueryParams from './useTenantIdsFromQueryParams';
import WriteCookieDataToValueStore from './writeCookieDataToValueStore';
import UseLoggedInRules from './useLoggedInRules';

export default class Behavior {
  public static init = async function (
    interfaces: InterfacesType
  ): Promise<void> {
    const appContext = appContextSingleton;
    const {
      tenantHandler,
      navigation,
      sessionService,
      authToken: authTokenService,
      microfrontendRouter,
      orgSelector
    } = interfaces.v1;
    const isNative = await isNativeFromJWeb();

    const keepLatestTenantOnNewTabs =
      !isNative &&
      new KeepLatestTenantOnNewTabs({
        repository: lastTenantIdsRepository,
        sessionService,
        tenantHandler
      });

    const useTenantIdsFromQueryParams =
      !isNative &&
      new UseTenantIdsFromQueryParams({
        tenantHandler,
        navigation,
        sessionService
      });

    const useLoggedInRules = new UseLoggedInRules({
      navigation,
      sessionService,
      tenantHandlerService: tenantHandler
    });

    const setFirstApplicationToken =
      !isNative &&
      new SetFirstApplicationToken({
        sessionService,
        authTokenService
      });

    // TODO: Check how we can remove any required behavior to run commons
    const initializeContextServices = new InitializeContextServices({
      appContext,
      tenantHandler
    });

    const writeCookieDataToValueStore =
      !isNative && new WriteCookieDataToValueStore();

    const startMicrofrontendRouter = new StartMicrofrontendRouter({
      microfrontendRouter
    });

    await keepLatestTenantOnNewTabs?.init?.();
    await useTenantIdsFromQueryParams?.init?.();
    await setFirstApplicationToken?.init?.();
    await initializeContextServices?.init?.();
    await InitializeDeprecatedServices?.init?.({ sessionService, orgSelector });
    await writeCookieDataToValueStore?.init?.();
    await startMicrofrontendRouter?.start?.();
    await useLoggedInRules?.init?.();
  };
}
