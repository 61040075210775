import i18next, { TFunction, Resource, InitOptions } from 'i18next';
import urlPathHandler from '../../../utils/urlPathHandler';
import { InterfacesInitializerStatePropsType } from '../../../interface/types';
import * as T from './types';
import { ManifestServiceLocalizationLanguageType } from '../../../types/manifest';
import { internalLogger } from '../logger';
import { setCookie, getCookie } from '../../../utils/cookies';
import {
  localePreferenceCookieName,
  errorInvalidLocaleFormat
} from '../../../config/constants';

type InitializerStateType = InterfacesInitializerStatePropsType & {
  globalResources?: T.TranslatorFunctionResourcePropType;
};

export default async (initializerState: InitializerStateType) => {
  const { portal, services } = initializerState?.manifest || {};
  const manifestLanguages = services?.localization?.languages;
  const isEnabled = services?.localization?.enable;

  const { country, language, getServicePath, createPath } = urlPathHandler({
    manifestBasePath: portal?.basePath,
    localization: services?.localization
  });

  const locale = (() => {
    const lcLanguage = language?.toLowerCase();
    const ucCountry = country?.toUpperCase();
    if (lcLanguage && ucCountry) {
      return lcLanguage + '-' + ucCountry;
    } else if (lcLanguage) {
      return lcLanguage;
    } else {
      return '';
    }
  })();

  async function setLocale(options: { language: string; country: string }) {
    if (isEnabled) {
      const newPath = createPath({ ...options, servicePath: getServicePath() });

      await new Promise(() => {
        window.location.href = newPath;
      });
    }
  }

  async function createTranslatorFunction(
    resources: T.TranslatorFunctionResourcePropType,
    options?: T.TranslatorFunctionOptionsPropType
  ) {
    const resourcesWithTranslationKey: Resource = {};
    const lng = (() => {
      let result = `${language?.toLowerCase()}_${country?.toUpperCase()}`;

      if (options?.lng && options?.lng !== locale) {
        result = options?.lng;
      }

      return result;
    })();

    if (typeof resources[lng] === 'string') {
      let languageTranslationResource = {};
      try {
        languageTranslationResource = await (
          await fetch(resources[lng] as string)
        ).json();
      } catch (error) {
        internalLogger?.error?.(error);
        languageTranslationResource = {};
      }
      resourcesWithTranslationKey[lng] = {
        translation: languageTranslationResource
      };
    } else {
      for (const l in resources) {
        resourcesWithTranslationKey[l] = { translation: resources[l] };
      }
    }

    const i18nInstanceOptions: InitOptions = {
      resources: resourcesWithTranslationKey,
      lng
    };

    return new Promise<TFunction>((resolve) => {
      i18next.createInstance(i18nInstanceOptions, (error, tParam) => {
        if (error) {
          throw error;
        }

        function t(key: string, options?: any) {
          const defaultValue: string = (() => {
            if (typeof options === 'string') {
              return options;
            } else if (typeof options?.defaultValue === 'string') {
              return options?.defaultValue;
            } else if (key) {
              return key;
            } else {
              return '';
            }
          })();
          if (tParam && isEnabled) {
            const value = tParam(key, options) as any;
            if ((value?.length || 0) > 0) {
              return value;
            } else {
              return defaultValue;
            }
          } else {
            return defaultValue;
          }
        }
        resolve(t);
      });
    });
  }

  async function getLanguages(): Promise<ManifestServiceLocalizationLanguageType> {
    const defaultValue: ManifestServiceLocalizationLanguageType = {};
    try {
      const stringfiedLanguage = JSON.stringify(
        manifestLanguages || defaultValue
      );

      return JSON.parse(stringfiedLanguage);
    } catch {
      return defaultValue;
    }
  }

  let translatorContext: any;
  let CachedReact: any;

  function getReactTranslatorProvider(React: any) {
    if (!CachedReact) {
      CachedReact = React;
    }
    if (!translatorContext) {
      translatorContext = CachedReact.createContext({});
    }

    return function TranslatorProvider(props: {
      children: unknown;
      resources: T.TranslatorFunctionResourcePropType;
      options?: T.TranslatorFunctionOptionsPropType;
    }) {
      const { children, resources, options } = props;
      const { lng } = options || {};
      const [value, setValue] = CachedReact.useState();

      CachedReact.useEffect(() => {
        if (resources) {
          createTranslatorFunction(resources, options).then((t) =>
            setValue({ t })
          );
        }
      }, [resources, lng]);

      if (!value) {
        return null;
      }

      return CachedReact.createElement(
        translatorContext.Provider,
        { value },
        children
      );
    };
  }

  function useReactTranslatorHook() {
    return CachedReact?.useContext?.(translatorContext);
  }

  const globalResourcesPromise: Promise<T.TranslatorFunctionResourcePropType> =
    (async () => {
      if (initializerState?.globalResources) {
        return initializerState.globalResources;
      } else {
        const locales = await getLanguages();

        const result = {};
        if (typeof locales === 'object') {
          for (const thisLanguage in locales) {
            const countryList = locales?.[thisLanguage];
            if (Array.isArray(countryList)) {
              countryList.forEach((thisCountry) => {
                if (typeof thisCountry === 'string') {
                  const thisLocale = `${thisLanguage.toLocaleLowerCase()}_${thisCountry.toLocaleUpperCase()}`;
                  result[thisLocale] = `/assets/locale/${thisLocale}.json`;
                }
              });
            }
          }
        }

        return result;
      }
    })();

  const globalResourceTranslatorPromise = globalResourcesPromise.then(
    (resources) => createTranslatorFunction(resources)
  );

  async function getGlobalTranslatorFunction() {
    return globalResourceTranslatorPromise;
  }

  function setLocalePreference(locale: string) {
    const localeRegex = /^[a-z]{2}-[A-Z]{2}$/gim;
    if (localeRegex.test(locale)) {
      setCookie(localePreferenceCookieName, locale, undefined, false);
    } else {
      throw new Error(errorInvalidLocaleFormat);
    }
  }

  function getLocalePreference() {
    return getCookie(localePreferenceCookieName, false);
  }

  return {
    enabled: isEnabled,
    locale,
    language: language,
    country: country,
    getGlobalTranslatorFunction,
    getLanguages,
    setLocale,
    createTranslatorFunction,
    getReactTranslatorProvider,
    useReactTranslatorHook,
    setLocalePreference,
    getLocalePreference
  };
};
