import interfaces from './interfaces';
import behaviors from './behaviors';
import services from './services';
import utils from './utils';

export default {
  behaviors,
  interfaces,
  services,
  utils
};
