import bindAllMethods from '../../../utils/bindAllMethods';
import { IFeatureFlagService } from '../../../services/featureFlagService';
import FeatureFlagClientInterfaceV1, {
  FeatureFlagClientInterfaceV1Type
} from './FeatureFlagClientInterfaceV1';

export type FeatureFlagInterfaceV1Type = {
  getClient(key: string): Promise<FeatureFlagClientInterfaceV1Type>;
};

type FeatureFlagInterfaceV1Param = {
  featureFlagService: IFeatureFlagService;
};

class FeatureFlagInterfaceV1 {
  private _featureFlagService: IFeatureFlagService;
  private _featureFlagClients: {
    key: string;
    client: FeatureFlagClientInterfaceV1;
  }[];

  constructor({ featureFlagService }: FeatureFlagInterfaceV1Param) {
    this._featureFlagService = featureFlagService;
    this._featureFlagClients = [];
    bindAllMethods(this);
  }

  public getInterface(): FeatureFlagInterfaceV1Type {
    return {
      getClient: (key) => this._getClient(key)
    };
  }

  private async _getClient(
    key: string
  ): Promise<FeatureFlagClientInterfaceV1Type> {
    let client = this._featureFlagClients.find((c) => c.key === key);
    if (!client) {
      client = { client: undefined, key: undefined };
      const featureFlagClient = await this._featureFlagService?.getClient(key);
      const interfaceClient = new FeatureFlagClientInterfaceV1({
        featureFlagClient
      });
      client.client = interfaceClient;
      client.key = key;
      this._featureFlagClients.push({ key, client: interfaceClient });
    }
    return client.client.getInterface();
  }
}

export default FeatureFlagInterfaceV1;
