import { IShellDAO } from '../../dao/IShellDAO';
import { AbstractRepository } from './../AbstractRepository';
import { AuthTokenStorageType } from './types';

export class AuthTokenRepository extends AbstractRepository<AuthTokenStorageType> {
  public static _prefixKey = 'jshellAuthToken';

  constructor({ shellDAO }: { shellDAO: IShellDAO }) {
    super({ shellDAO: shellDAO, prefixKey: AuthTokenRepository._prefixKey });
  }

  saveAll(): void {
    console.debug('Not supported in AuthTokenRepository.');
    throw new Error('Method not implemented.');
  }

  findAll(): AuthTokenStorageType[] {
    console.debug('Not supported in AuthTokenRepository.');
    throw new Error('Method not implemented.');
  }
}
