import { Stack } from '../../types/stratus';
import {
  SESSION_API_BASE_URL,
  SESSION_API_LOGOUT_URL
} from '../shell/constants';
import { ILogoutClient } from '../shell/logout';

export type HPIDClientParams = {
  stack: Stack;
};

export default class HPIDClient implements ILogoutClient {
  private _baseUrl: string;

  constructor({ stack }: HPIDClientParams) {
    this._baseUrl = this._generateHPIDBaseUrl(stack);
  }

  configureLogoutUrl(shellBaseUrl: string, state: string): string {
    const postLoginRedirectUri = `${shellBaseUrl}${SESSION_API_BASE_URL}${SESSION_API_LOGOUT_URL}`;

    const searchParams = new URLSearchParams();
    searchParams.set('state', state);
    searchParams.set('post_logout_redirect_uri', postLoginRedirectUri);

    return `${this._baseUrl}?${searchParams.toString()}`;
  }

  private _generateHPIDBaseUrl(stack: Stack): string {
    if ([Stack.dev, Stack.pie, Stack.stage].includes(stack)) {
      return 'https://directory.stg.cd.id.hp.com/directory/v1/oauth/logout';
    }
    return 'https://directory.id.hp.com/directory/v1/oauth/logout';
  }
}
