import {
  AuthTokenRepository,
  AuthTokenService,
  IAuthTokenService
} from '../../../services/authTokenService';
import TenantHandlerService from '../../../services/tenantHandler/TenantHandlerService';
import { InterfacesInitializerStatePropsType } from '../../types';

type CreateAuthTokenSingletonPropsType = InterfacesInitializerStatePropsType & {
  repository: AuthTokenRepository;
  tenantHandler: TenantHandlerService;
};

let authTokenSingleton: ReturnType<typeof _createAuthToken>;

function _createAuthToken(options: CreateAuthTokenSingletonPropsType) {
  const { tenantHandler, repository: authTokenRepository } = options;

  const result = new AuthTokenService({
    authTokenRepository,
    tenantHandler
  });

  return result;
}

export function getAuthTokenSingleton(): IAuthTokenService {
  return authTokenSingleton;
}

export default async function initializeAuthTokenSingleton(
  options: CreateAuthTokenSingletonPropsType
): Promise<IAuthTokenService> {
  if (authTokenSingleton) return authTokenSingleton;

  authTokenSingleton = _createAuthToken(options);

  return authTokenSingleton;
}
