import webServiceRouting from '../../../services/webServiceRouting';
import * as T from './types';

export default (
  initializerState: T.ServiceRoutingInitializerStatePropsType
) => {
  const { manifest } = initializerState;

  function isEnabled() {
    return !!manifest?.services?.onboarding?.enable;
  }

  async function getServiceInstanceLaunchOptions(): Promise<
    T.LaunchServiceOptions | T.ServiceRoutingError
  > {
    // const state = _getState();

    // const launchOptions = {
    //   serviceId: state?.context?.nextService?.serviceId,
    //   serviceOptions: {
    //     onboardingContext: state?.context,
    //     appSessionId: state?.appSessionId
    //   }
    // };

    const serviceInstanceLaunchOptions =
      await webServiceRouting.getServiceInstanceLaunchOptions();

    return webServiceRouting.separateErrorObject(serviceInstanceLaunchOptions)
      ?.data;
  }

  async function launchService(launchOptions: {
    /**
     * The identifier of the service that should be launched
     */
    serviceId: string;
    /**
     * Options specific to the service being launched, if any are required
     */
    serviceOptions?: Record<string, any>;
  }) {
    if (isEnabled()) {
      // payload.resultData.
      await webServiceRouting.launchService({
        serviceId: launchOptions?.serviceId,
        serviceOptions: launchOptions?.serviceOptions
      });
      // const state = _getState();

      // await state?.nextStage?.(payload?.resultData, {
      //   authentication: payload?.authentication
      // });
    }
  }

  async function closeServiceInstance(
    payload: T.ServiceRoutingCloseServiceInstancePropType
  ): Promise<void> {
    if (isEnabled()) {
      // payload.resultData.
      webServiceRouting.closeServiceInstance(payload as any);
      // const state = _getState();

      // await state?.nextStage?.(payload?.resultData, {
      //   authentication: payload?.authentication
      // });
    }
  }

  async function getServices(): Promise<{
    services: T.InternalServiceObjectType[];
  }> {
    const response = webServiceRouting.separateErrorObject(
      await webServiceRouting.getServices()
    )?.data;

    return response;
  }

  return {
    launchService,
    getServiceInstanceLaunchOptions,
    closeServiceInstance,
    getServices
  };
};
