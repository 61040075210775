import { DataValveCDMEvent, PublishCdmEventsOptions } from './types';
import { InterfacesInitializerStatePropsType } from '../../types';
import { EventsType } from '../events/types';
import { AuthProviderType } from '../orgSelector/types';
import Store from '../store/index';

export default async (
  initializerState: InterfacesInitializerStatePropsType,
  authProvider: AuthProviderType,
  store: Store,
  events: EventsType,
  isNative: boolean
) => {
  const createMockedInterface = (logCallback: () => void) => ({
    publishCdmEvents: (
      events: DataValveCDMEvent[],
      options?: PublishCdmEventsOptions
    ) => {
      logCallback?.();
    }
  });

  const { enable } = initializerState?.manifest?.services?.analytics || {};
  if (!enable) {
    return createMockedInterface(() => console.warn('Analytics is Disabled'));
  }
  try {
    if (isNative) {
      const { default: AnalyticsServiceNative } = await import(
        '../../../services/AnalyticsService/AnalyticsServiceNative'
      );
      return await AnalyticsServiceNative(
        initializerState,
        authProvider,
        store,
        events
      );
    } else {
      const { default: AnalyticsServiceWeb } = await import(
        '../../../services/AnalyticsService/AnalyticsServiceWeb'
      );
      return await AnalyticsServiceWeb(
        initializerState,
        authProvider,
        store,
        events
      );
    }
  } catch (error) {
    return createMockedInterface(() =>
      console.error('Analytics Failed to Initialize', error)
    );
  }
};
