import { JarvisAuthProvider } from './jarvis-auth-provider'

export class JarvisTokenExchangeAuthProvider implements JarvisAuthProvider {
    accessToken: string = ''
    tokenToExchange: string
    tokenEndpoint: string
    scopes: string[]

    constructor(tokenToExchange:string, tokenEndpoint: string, scopes: string[]) {
        this.tokenToExchange = tokenToExchange
        this.tokenEndpoint = tokenEndpoint
        this.scopes = scopes
    }
    async getAccessToken(forceRefresh?: boolean) {
        // this.accessToken = this.accessToken || await // Make token exchange grant type call to endpoint to get access token for scopes here
        return this.accessToken
    }
}
