import AppContext from './AppContext';

let appContextSingleton: AppContext;

export function getAppContextSingleton(): AppContext {
  return appContextSingleton;
}

export default function createAppContextSingleton(): AppContext {
  if (appContextSingleton) return appContextSingleton;
  appContextSingleton = new AppContext();
  return appContextSingleton;
}
