import * as T from './types';
import storeDefaultState from './storeDefaultState';

/**
 * The Store allows the creation of a source of truth for JSHELL.
 * At the moment, we only use one instance, aiming to aggregate data used in
 * portals in one place. The developer could receive an event when the data is
 * updated using the observers available in the instance.

 * Internally, the state contains a map with a string as a key and accepts any
 * value. The data storaged only could be changed using setState.
 */
export default class Store {
  state: T.StoreStateType;

  // this is intended to be private but should be protected in the future
  _observers: T.StoreObserverType;
  _internalObservers: T.StoreObserverType;
  _lastGeneratedId: number;

  constructor() {
    this.state = storeDefaultState;
    this._observers = new Map();
    this._internalObservers = new Map();
    this._lastGeneratedId = 0;

    this.setState = this.setState.bind(this);
    this.addObserver = this.addObserver.bind(this);
    this.removeObserver = this.removeObserver.bind(this);
    this.useReactStore = this.useReactStore.bind(this);
  }

  /**
   * Used to safely change state values. The setState doesn't changing
   * the values that have not been informed to it, thus providing
   * greater security in the manipulation of states.
   *
   * @param {Object | Function} data - the data to be updated
   * @param {Object} options - Options to change the setState behavior
   * @param {boolean} options.deepSafeSetValue - Is true by default. If true,
   * it provides greater security in the manipulation of states, goes through
   * all the new value, maintaining old properties that have not been
   * informed. If you want to use this option and want to delete an existing
   * property, enter it as undefined to be removed from the state.
   * @returns {void}
   */
  setState(data: T.StoreSetStateType, options?: T.StoreSetStateOptionsType) {
    const { deepSafeSetValue } = options || {};
    const newValue = typeof data === 'function' ? data(this.state) : data;
    const previousState = this.state;
    const isIterableObject = (data) =>
      Object.prototype.toString.call(data) === '[object Object]';

    if (isIterableObject(newValue)) {
      if (deepSafeSetValue) {
        const safeSetValue = (oldData: any, newData: any) => {
          for (const key in newData) {
            const newKeyValue = newData[key];

            if (newKeyValue === undefined) {
              delete oldData[key];
            } else if (isIterableObject(newKeyValue)) {
              if (!isIterableObject(oldData[key])) {
                oldData[key] = newKeyValue;
              } else {
                safeSetValue(oldData[key], newKeyValue);
              }
            } else {
              oldData[key] = newKeyValue;
            }
          }
        };
        safeSetValue(this.state, newValue);
      } else {
        const newState = { ...this.state, ...newValue };
        for (const key in newState) {
          if (newState[key] === undefined) {
            delete newState[key];
          }
        }
        this.state = newState;
      }
    }
    this._observers.forEach((signature) =>
      signature?.(this.state, previousState)
    );
    this._internalObservers.forEach((signature) =>
      signature?.(this.state, previousState)
    );
  }

  /**
   * dangerousSetState should be used only if you are sure of what you are
   * doing. The value entered will be the new global state. If you know React,
   * this method works identical to the setState of the useState hook.
   *
   * @param  {Object | Function} data - the data to be updated
   * @returns {void}
   */
  dangerousSetState(data: T.StoreSetStateType) {
    const newValue = typeof data === 'function' ? data(this.state) : data;
    const previousState = this.state;
    this.state = newValue;
    this._observers.forEach((signature) =>
      signature?.(this.state, previousState)
    );
    this._internalObservers.forEach((signature) =>
      signature?.(this.state, previousState)
    );
  }

  /***
   * This function adds an observer to the store. It is necessary to inform
   *  a string as identification (id), so that you can remove it when you are
   * no longer using it. The observer will be notified through its signature
   * whenever the state is changed, passing the new state value as an argument.
   *
   * @param {string} id - The identifier for the observer.
   * @param  {Function} signature - Method to be called when the state changed
   * @returns {void}
   */
  addObserver(id: string, signature: T.StoreObserverSignatureType) {
    this._observers.set(id, signature);
  }

  /**
   * This removes the signature of the observer by the given id.
   *
   * @param {string} id - An observer identifier.
   * @returns {void}
   */
  removeObserver(id: string) {
    this._observers.delete(id);
  }

  /**
   * The store has an internal counter, that whenever an id is generated
   * through this function, is added 1 to that counter, helping to generate a
   * unique id;
   *
   * @returns {string}
   */
  generateId() {
    this._lastGeneratedId += 1;

    return String(this._lastGeneratedId);
  }

  /**
   * Used in React components only. It is a customized hook that must receive
   * the React object itself as an argument. It returns the store itself and
   * consequently update the component whenever the state is changed;
   *
   * @param {Object} React - the React object
   */
  useReactStore(React: any) {
    const methods = {
      useEffect: React?.useEffect,
      useState: React?.useState
    };

    if (Object.values(methods).some((method) => typeof method !== 'function')) {
      // throw new Error("The useReactStore hook needs React as an argument.");
      return undefined;
    }
    const [updateCount, setUpdateCount] = methods.useState(0);

    methods.useEffect(() => {
      const thisId = this.generateId();
      this._internalObservers.set(thisId, () =>
        setUpdateCount((prev) => prev + 1)
      );

      return () => {
        this._internalObservers.delete(thisId);
      };
    }, []);

    return {
      updateCount,
      ...this
    };
  }
}
