import { match } from 'path-to-regexp';
import getPathnameFromUrl from './getPathnameFromUrl';

export default function matchPath(
  path: string | string[],
  options?: { exact?: boolean; pathToCompare?: string | string[] }
): boolean {
  const pathArray = Array.isArray(path) ? path : [path];

  const pathToCompare = options?.pathToCompare || window.location.pathname;
  const unsanitizedPathsToCompare = Array.isArray(pathToCompare)
    ? pathToCompare
    : [pathToCompare];

  const arrayOfPathsToCompare = unsanitizedPathsToCompare.map((thisPath) =>
    getPathnameFromUrl(thisPath)
  );

  (window as any).checkPath = (path1, path2) =>
    match(path1, {
      start: true,
      end: !!options?.exact
    })(path2);

  return !!pathArray?.some((thisPath) => {
    const pathname = getPathnameFromUrl(thisPath);

    const checkPath = match(pathname, {
      start: true,
      end:
        typeof options?.exact === 'boolean' ? options?.exact : pathname === '/'
    });

    return arrayOfPathsToCompare.some(
      (pathToCompare) => !!checkPath(pathToCompare)
    );
  });
}
