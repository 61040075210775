import eventService from '../../../services/eventService';
import * as T from './types';

export default class EventInterface implements T.EventsType {
  private _removeListenersMapType: T.RemoveListenersMapType = new Map();
  shellEventNames = eventService.eventNames;

  addEventListener(name: string, action: T.EventCallBackType): void {
    const listenerHandler = eventService.subscribe(name, action);

    const actionMap = this._getActionMap(name);

    actionMap.set(action, listenerHandler);
  }

  private _getActionMap(name: string) {
    let actionMap = this._removeListenersMapType.get(name);
    if (!actionMap) {
      actionMap = new Map();
      this._removeListenersMapType.set(name, actionMap);
    }

    return actionMap;
  }

  removeEventListener(name: string, action: T.EventCallBackType): void {
    const actionMap = this._getActionMap(name);
    const listenerHandlerPromise = actionMap.get(action);
    actionMap.delete(action);

    listenerHandlerPromise.then((listenerHandler) =>
      listenerHandler.unsubscribe()
    );
  }

  triggerEvent(name: string, event: T.EventValueType): void {
    eventService.publish(name, event);
  }
}
