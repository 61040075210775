import { Stack } from '../types/stratus';

export default function getStratusApiDomain(stack: Stack): string {
  const urlEnv = (() => {
    switch (stack) {
      case Stack.prod:
        return '';
      case Stack.stage:
        return 'stage-';
      default:
        return 'pie-';
    }
  })();

  return `https://${urlEnv}us1.api.ws-hp.com`;
}
