import {
  ServiceRoutingErrorType,
  ServiceInstanceState
} from '@jarvis/jweb-core';
import bindAllMethods from '../../utils/bindAllMethods';
import eventService from '../eventService';
import { isNative } from '../JWeb';
import {
  getAvailableServices,
  getNativeServiceLaunchOptions,
  launchNativeService,
  closeNativeOnboardingInstance
} from '../JWeb/JWebServiceRouting';
import eventNames from '../../config/eventNames';
import * as T from './types';
import { internalLogger } from '../../interface/v1/logger';

enum WebServiceRoutingEvents {
  ServiceInstanceLaunching = 'service-instance-launching',
  ServiceInstanceClosed = 'service-instance-closed'
}

class WebServiceRouting implements T.WebServiceRoutingType {
  private _serviceList: T.ServiceList = { services: [] };
  private _lastId = 0;
  private _serviceInstance?: T.ServiceInstance;
  private _serviceLaunchOptions?: T.LaunchServiceOptions;
  private _loadNativeServicesPromise: Promise<void>;
  private _launchServiceBlockingPromise: Promise<
    T.ServiceRoutingErrorType | T.ServiceInstance
  >;
  serviceRoutingErrorType = ServiceRoutingErrorType;
  ServiceInstanceState = ServiceInstanceState;
  Events = WebServiceRoutingEvents;

  constructor(dependencies?: T.WebServiceRoutingDependenciesType) {
    bindAllMethods(this);
    this.addServices(dependencies?.services);
    this._startListenServiceInstanceCancelled();

    this._loadNativeServicesPromise = (async () => {
      if (await isNative()) {
        const nativeServices = (await getAvailableServices()) || [];
        const services = nativeServices.map<T.NativeServiceType>((service) => ({
          ...service,
          serviceType: 'native'
        }));

        this.addServices(services);
      }
    })();
  }

  private _startedListenServiceInstanceCancelled = false;
  private _startListenServiceInstanceCancelled() {
    if (this._startedListenServiceInstanceCancelled) return;
    this._startedListenServiceInstanceCancelled = true;
    eventService.subscribe('ServiceInstanceCancelled', () => {
      this.closeServiceInstance({
        resultData: {
          result: 'cancelled'
        }
      });
    });
  }

  separateErrorObject<Union>(data: Union | T.ServiceRoutingErrorType) {
    const serviceRoutingError: T.ServiceRoutingErrorType = (
      data as T.ServiceRoutingErrorType
    )?.errorType
      ? (data as T.ServiceRoutingErrorType)
      : undefined;

    const nonErrorValue = serviceRoutingError?.errorType
      ? undefined
      : (data as Exclude<Union, T.ServiceRoutingErrorType>);

    return {
      serviceRoutingError,
      data: nonErrorValue
    };
  }

  private _createId() {
    return String(this._lastId++);
  }

  async getNativeServiceLaunchOptions() {
    if (await isNative()) {
      return await getNativeServiceLaunchOptions();
    }
  }

  addServices(services: T.Service[]): void {
    internalLogger?.log?.('onboarding-web-addServices: ', { services });

    if (Array.isArray(services)) {
      this._serviceList.services.push(...services);
    }
  }

  async getServices(): Promise<T.ServiceList | T.ServiceRoutingErrorType> {
    await this._loadNativeServicesPromise;
    const services = this._serviceList;

    internalLogger?.log?.('onboarding-web-getServices: ', { services });

    return services;
  }

  async getServiceAvailability(
    options: T.GetServiceAvailabilityOptions
  ): Promise<T.ServiceRoutingErrorType | T.Service> {
    const services = await this.getServices();
    const { data, serviceRoutingError } = this.separateErrorObject(services);

    if (serviceRoutingError) {
      internalLogger?.log?.('onboarding-web-getServiceAvailability-error: ', {
        serviceRoutingError
      });
      return serviceRoutingError;
    }

    const service = data?.services?.find(
      (service) => service?.id === options?.serviceId
    );

    internalLogger?.log?.('onboarding-web-getServiceAvailability: ', {
      service
    });

    if (service) {
      return service;
    } else {
      const errorResult = {
        errorType: this.serviceRoutingErrorType.serviceNotFound
      };

      internalLogger?.log?.('onboarding-web-getServiceAvailability-error: ', {
        errorResult
      });

      return errorResult;
    }
  }

  async launchService(
    serviceLaunchOptions: T.LaunchServiceOptions
  ): Promise<T.ServiceRoutingErrorType | T.ServiceInstance> {
    if (this._launchServiceBlockingPromise) {
      internalLogger?.log?.('onboarding-web-launchService-peding-promise');

      await this._launchServiceBlockingPromise;
    }
    let resolveBlockingPromise: () => void;
    this._launchServiceBlockingPromise = new Promise((resolve) => {
      resolveBlockingPromise = () => resolve(undefined);
    });

    try {
      const { data: service, serviceRoutingError } = this.separateErrorObject(
        await this.getServiceAvailability({
          serviceId: serviceLaunchOptions?.serviceId
        })
      );

      if (serviceRoutingError) {
        internalLogger?.log?.('onboarding-web-launchService-error: ', {
          serviceRoutingError
        });
        return serviceRoutingError;
      } else if (!service?.id) {
        const errorResult = {
          errorType: this.serviceRoutingErrorType.serviceNotSupported,
          reason: 'Service id is missing'
        };

        internalLogger?.log?.('onboarding-web-launchService-error: ', {
          errorResult
        });

        return errorResult;
      } else if (service?.serviceType === 'web' && !service?.assetReference) {
        const errorResult = {
          errorType: this.serviceRoutingErrorType.serviceNotSupported,
          reason: 'Web Service require assetReference'
        };

        internalLogger?.log?.('onboarding-web-launchService-error: ', {
          errorResult
        });

        return errorResult;
      }

      const newServiceInstance = {
        eventPublisherId: null,
        instanceId: this._createId(),
        serviceId: service?.id,
        // TODO: How we should start from launching and move it to running only when it is loaded?
        state: this.ServiceInstanceState.running
      };

      if (!newServiceInstance?.serviceId || !newServiceInstance?.instanceId) {
        const errorResult = {
          errorType: this.serviceRoutingErrorType.serviceNotSupported
        };

        internalLogger?.log?.('onboarding-web-launchService-error: ', {
          errorResult
        });

        return errorResult;
      } else {
        internalLogger?.log?.('onboarding-web-launchService-service: ', {
          service
        });

        if (service?.serviceType === 'native') {
          await launchNativeService(serviceLaunchOptions);
        }

        this._serviceInstance = newServiceInstance;
        this._serviceLaunchOptions = serviceLaunchOptions;

        eventService.publish(this.Events.ServiceInstanceLaunching, {
          serviceInstance: this._serviceInstance,
          serviceLaunchOptions: this._serviceLaunchOptions
        });

        if (service?.serviceType === 'web') {
          const servicePath = Array.isArray(service?.path)
            ? service?.path?.[0]
            : service?.path;

          if (servicePath?.startsWith?.('/')) {
            eventService.publish(eventNames?.shellCallInterfaceNavigationPush, {
              path: service?.path
            });
          }
        }

        internalLogger?.log?.(
          `service-router-launch-service-${this._serviceInstance?.serviceId}`
        );

        return this._serviceInstance;
      }
    } catch (error) {
      console.error(error);
      const errorResult = {
        errorType: this.serviceRoutingErrorType.unknownError
      };

      internalLogger?.log?.('onboarding-web-launchService-error: ', {
        errorResult
      });

      return errorResult;
    } finally {
      resolveBlockingPromise?.();
    }
  }

  async closeNativeServiceInstance(options: {
    result: 'success' | 'failed' | 'cancelled';
    errorCode?: string;
  }) {
    const serviceInstanceLaunchOptions = this.separateErrorObject(
      await this.getServiceInstanceLaunchOptions()
    )?.data;

    await closeNativeOnboardingInstance({
      resultData: {
        appSessionId:
          serviceInstanceLaunchOptions?.serviceOptions?.appSessionId,
        serviceId: serviceInstanceLaunchOptions?.serviceId,
        result: {
          result: options?.result,
          xCorrelationId:
            serviceInstanceLaunchOptions?.serviceOptions?.onboardingContext
              ?.sessionContext?.xCorrelationId
        },
        errorInfo: {
          errorCode: options?.errorCode
        }
      }
    });
  }

  closeServiceInstance(
    closeServiceInstanceOptions?: T.CloseServiceInstanceOptions
  ): void {
    if (this._serviceInstance) {
      this._serviceInstance.state = this.ServiceInstanceState.closed;

      eventService.publish(
        this.Events.ServiceInstanceClosed,
        closeServiceInstanceOptions
      );

      internalLogger?.log?.(
        `service-router-close-service-${this._serviceInstance?.serviceId}`
      );
    }
  }

  async getServiceInstance(): Promise<
    T.ServiceRoutingErrorType | T.ServiceInstance
  > {
    const serviceInstance = this._serviceInstance;

    if (serviceInstance) {
      return serviceInstance;
    } else {
      return {
        errorType: this.serviceRoutingErrorType.serviceInstanceNotFound
      };
    }
  }

  async getServiceInstanceLaunchOptions(): Promise<
    T.ServiceRoutingErrorType | T.LaunchServiceOptions
  > {
    if (this._serviceLaunchOptions) {
      return this._serviceLaunchOptions;
    } else {
      return {
        errorType: this.serviceRoutingErrorType.serviceInstanceNotFound
      };
    }
  }
}

const webServiceRouting = new WebServiceRouting();

export default webServiceRouting;
