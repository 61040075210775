import { ISessionService } from '../../../services/session';
import {
  IUserService,
  StratusUserService
} from '../../../services/userService';
import UserMgtClient from '../../../clients/stratus/usersMgt/UserMgtClient';

type CreateUserServiceSingletonPropsType = {
  sessionService: ISessionService;
  userMgtClient: UserMgtClient;
};

let userServiceSingleton: IUserService;

async function _createUserService(
  options: CreateUserServiceSingletonPropsType
): Promise<IUserService> {
  const { sessionService, userMgtClient } = options;

  const userService = new StratusUserService({ sessionService, userMgtClient });

  return userService;
}

export function getUserServiceSingleton(): IUserService {
  return userServiceSingleton;
}

export default async function initializeUserServiceSingleton(
  options: CreateUserServiceSingletonPropsType
): Promise<IUserService> {
  if (userServiceSingleton) return userServiceSingleton;

  userServiceSingleton = await _createUserService(options);

  return userServiceSingleton;
}
