import * as T from './types';

import authProvider from '../../../interface/v2/auth';
import { AuthProviderV2Type } from '../types';
import TenantStrategy from './TenantStrategy';

import { isValidRegex } from '../../../utils/regex';

import { PersonClient } from '../../../clients/stratus/tntCommonResourceRegistry';

import { AuthContextEnum } from '../../../services/authTokenService';
import AccountsClient from '../../../clients/stratus/accountmgtsvc/AccountsClient';
import { ISessionService } from '../../../services/session';
import TenantUtils from '../TenantUtils';
import BaseStratusTenantHandler from './BaseTenantHandler';
import { TenantStrategyEnum } from './strategy';

type OrganizationProperties = {
  fallback?: string;
  filter?: {
    role: 'admin' | 'user' | 'all';
    tenantType: string[];
  };
};

export default class OrganizationTenantHandler
  extends BaseStratusTenantHandler
  implements TenantStrategy
{
  private _strategy = TenantStrategyEnum.stratusOrganization;
  private _options: OrganizationProperties;
  private _assetReference: string;
  private _personClient: PersonClient;
  private _accountsClient: AccountsClient;
  private _sessionService: ISessionService;

  constructor({
    stack,
    options,
    parentAuthProvider,
    authProvider,
    assetReference,
    sessionService
  }: T.TenantHandlerStrategyType) {
    super({ stack, authProvider, parentAuthProvider });
    this._options = options;
    this._assetReference = assetReference;
    this._sessionService = sessionService;
    //TODO: do the inversion of controll of these clients
    this._personClient = new PersonClient(
      PersonClient.getBaseUrl(this._stack),
      this._authProvider
    );
    this._accountsClient = new AccountsClient(
      AccountsClient.getBaseUrl(this._stack),
      this._parentAuthProvider
    );
  }

  public getAssetReference(): string {
    return this._assetReference;
  }

  public getStrategy = (): TenantStrategyEnum => {
    return this._strategy;
  };

  public setTenant = async (
    id: string,
    authContext: AuthContextEnum
  ): Promise<void> => {
    await this._sessionService.exchangeTenantToken(
      id,
      authContext,
      TenantStrategyEnum.stratusOrganization
    );
  };

  public async createOrganization(
    data: T.CreateNodeRequestBodyType
  ): Promise<T.TenantVisualizationType> {
    const language = data?.language?.toLowerCase?.() || 'en';
    const country = 'US';
    const idToken = await this._authProvider.getIDToken();

    const newTenantId = await this._accountsClient.createAccount({
      accountId: null,
      parentAccountResourceId: null,
      language,
      countrySet: [country],
      idToken: idToken,
      ...data
    });

    return await this.getTenantById(newTenantId.resourceId);
  }

  private _filterByTenantType(filter, tenant: T.TenantNodeType) {
    const _domainSelectorTenantTypeFilters: RegExp[] = filter?.tenantType?.map(
      (tenantType) => {
        const regexTenantType = isValidRegex(tenantType)
          ? tenantType
          : `^${tenantType}$`;
        return new RegExp(regexTenantType);
      }
    );

    return (
      !_domainSelectorTenantTypeFilters?.length ||
      _domainSelectorTenantTypeFilters?.find((regex) => regex.test(tenant.type))
    );
  }

  public async _getPaginatedTenantListData(
    options: T.GetTenantListOptionsType
  ): Promise<T.PaginatedTenantVisualizationType> {
    const { filter } = this._options;
    const _options = {
      filter: filter?.role || 'all',
      ...options
    };

    const personDomains = await this._personClient.getPersonDomain(_options);

    const tenantList = await Promise.all<T.TenantNodeType>(
      personDomains.content.map((tenantId) =>
        this._nodeClient.getNodeByTentantId({ tenantId })
      )
    );

    const filteredTenantList = tenantList.filter((tenant) => {
      return this._filterByTenantType(filter, tenant);
    });

    const tenants = filteredTenantList.map((filteredTenant) =>
      TenantUtils._convertStratusTenantNodeToTenantVisualizationType(
        filteredTenant
      )
    );

    return {
      totalPages: personDomains.totalPages,
      currentPage: personDomains.pageable.pageNumber,
      tenants
    };
  }

  public getAuthProvider(): AuthProviderV2Type {
    return authProvider().createOrgedAuthProvider();
  }

  public isRequiredToNavigate = (): boolean => {
    return true;
  };

  public getFallback = (): string => {
    return this?._options?.fallback;
  };
}
