import { UAParser } from 'ua-parser-js';

const uaParser = new UAParser();

export default function getOSDetailsFromBrowser(): {
  name: string;
  version: string;
} {
  let name = '';
  let version = '';

  try {
    const os = uaParser.getOS();
    name = os?.name;
    version = os?.version;
  } catch (error) {
    console.error(error);
  }

  return {
    name: name?.toLocaleLowerCase?.(),
    version
  };
}
