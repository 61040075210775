import bindAllMethods from '../../../utils/bindAllMethods';
import {
  GetProviderListParam,
  GetProviderListResponseType,
  IProviderClient
} from '../../../clients/shell/provider';
import ILoginService from './ILoginService';
import { GenerateAuthenticationUrlParams } from './types';

export type LoginServiceNativeParam = {
  providerClient: IProviderClient;
};

class LoginServiceNative implements ILoginService {
  private _providerClient: IProviderClient;

  constructor({ providerClient }: LoginServiceNativeParam) {
    this._providerClient = providerClient;
    bindAllMethods(this);
  }

  public async getProviderList(
    options?: GetProviderListParam
  ): Promise<GetProviderListResponseType> {
    return this._providerClient.getProviderList(options);
  }

  // TODO: We don't currently don't have an authentication flow for Native
  generateAuthenticationUrl(
    options: GenerateAuthenticationUrlParams
  ): Promise<string> {
    throw new Error('Method not implemented.');
  }
}

export default LoginServiceNative;
