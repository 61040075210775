import { TenantStorageType } from './types';
import { IShellDAO } from '../../dao/IShellDAO';
import { AbstractRepository } from './../AbstractRepository';

type TenantDAOItemType = {
  key: string;
  value: TenantStorageType;
};

export class TenantRepository extends AbstractRepository<TenantStorageType> {
  constructor({
    shellDAO,
    enableSanitizer = false
  }: {
    shellDAO: IShellDAO;
    enableSanitizer?: boolean;
  }) {
    super({
      shellDAO: shellDAO,
      prefixKey: 'jshellTenant',
      enableSanitizer
    });
  }

  saveAll(): void {
    console.debug('Not supported in TenantRepository.');
    throw new Error('Method not implemented.');
  }

  findAll(): TenantStorageType[] {
    console.debug('Not supported in TenantRepository.');
    throw new Error('Method not implemented.');
  }

  clear(level = 0): void {
    const items = this.shellDAO.findAll({
      prefixKey: this.prefixKey
    }) as TenantDAOItemType[];
    const filteredItemKeys = [];
    items.forEach((t) => {
      if (
        t.key.startsWith(this.prefixKey) &&
        (!t.value?.level || t.value.level >= level)
      )
        filteredItemKeys.push(t.key);
    });
    this.shellDAO.deleteAll(filteredItemKeys);
  }
}
