import { NavigationType } from '../interface/v1/orgSelector/types';
import TenantObserver, {
  TenantEvents
} from '../services/tenantHandler/TenantObserver';
import {
  HandledTenant,
  InitialTenantType
} from '../services/tenantHandler/types';
import { parsePath } from 'history';
import TenantHandlerService from '../services/tenantHandler/TenantHandlerService';
import { ISessionService } from '../services/session';

export default class UseTenantIdsFromQueryParams {
  private _tenantHandler: TenantHandlerService;
  private _navigation: NavigationType;
  private _sessionService: ISessionService;

  constructor({ tenantHandler, navigation, sessionService }) {
    this._tenantHandler = tenantHandler;
    this._navigation = navigation;
    this._sessionService = sessionService;
  }

  public init = async (): Promise<void> => {
    const isLoggedIn = this._sessionService.isLoggedIn();
    if (!isLoggedIn || !this._tenantHandler?.isEnabled()) {
      return;
    }
    // Navigation decorators
    this._overrideNavigationPush();
    this._overrideNavigationReplace();

    // TenantHandler behaviors
    this._hasTenantOnQueryParams() &&
      this._tenantHandler.setInitialTenants(
        this._createTenantListFromQueryParams()
      );

    TenantObserver.subscribe(
      TenantEvents.SET_TENANT,
      this.setTenantOnNavigation
    );

    TenantObserver.subscribe(
      TenantEvents.SET_TENANT_HANDLER_KEY,
      this._setTenantsFromQueryParams
    );
  };

  private _hasTenantOnQueryParams(): boolean {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.has(`t${this._tenantHandler.START_TENANT_LEVEL}`);
  }

  private _rehydrateQueryParams(): void {
    if (this._hasTenantOnQueryParams()) return;
    else if (this._tenantHandler?.getInitialTenants?.()?.length) {
      this._navigation.replace(this._navigation.location);
    }
  }

  public _setTenantsFromQueryParams = async (
    tenants: HandledTenant[]
  ): Promise<void> => {
    const tenantList = this._hasTenantOnQueryParams()
      ? this._createTenantListFromQueryParams()
      : this._tenantHandler?.getInitialTenants();
    if (!tenantList?.length) return;
    const options = { reload: false };

    const tenantIdList = [] as string[];

    for (const tenant of tenantList) {
      tenantIdList.push(tenant.id);
      const tenantSuffix = tenantIdList.join(
        this._tenantHandler.TENANT_SUFFIX_CONCAT_STRING
      );
      if (!this._tenantHandler?.checkIfTenantIsStoredBySuffix(tenantSuffix)) {
        await this._tenantHandler?.setTenant(
          tenant.id,
          tenant.level,
          options,
          undefined
        );
      }
    }
    this._rehydrateQueryParams();
  };

  private setTenantOnNavigation = (tenants: HandledTenant[]): void => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    tenants.forEach((t) => {
      const tenantQueryParam = `t${t.level}`;
      if (t.proccessed) urlSearchParams.set(tenantQueryParam, t.id);
      else urlSearchParams.delete(tenantQueryParam);
    });
    this._navigation.replace({
      ...this._navigation.location,
      search: urlSearchParams.toString()
    });
  };

  private _overrideNavigationPush = () => {
    const oldNavigationPush = this._navigation.push;

    this._navigation.push = (location, state) => {
      let locationObject = location;
      if (typeof location === typeof '') {
        locationObject = parsePath(location as string);
      }

      const queryParams = new URLSearchParams(locationObject?.search as string);
      this?._tenantHandler?.getHandledTenantList().forEach((t) => {
        t.proccessed && queryParams.set(`t${t.level}`, t.id);
      });

      locationObject.search = queryParams.toString()
        ? `?${queryParams.toString()}`
        : '';

      oldNavigationPush(locationObject, state);
    };
  };

  private _overrideNavigationReplace = () => {
    const oldNavigationReplace = this._navigation.replace;

    this._navigation.replace = (location, state) => {
      let locationObject = location;
      if (typeof location === typeof '') {
        locationObject = parsePath(location as string);
      }

      const queryParams = new URLSearchParams(locationObject?.search as string);
      this?._tenantHandler?.getHandledTenantList().forEach((t) => {
        t.proccessed && queryParams.set(`t${t.level}`, t.id);
      });

      locationObject.search = queryParams.toString()
        ? `?${queryParams.toString()}`
        : '';

      oldNavigationReplace(locationObject, state);
    };
  };

  private _createTenantListFromQueryParams(): InitialTenantType[] {
    const queryParams = new URLSearchParams(window.location.search);
    let tenantLevel = this._tenantHandler?.START_TENANT_LEVEL;
    const tenantIdList = [] as InitialTenantType[];

    while (queryParams.has(`t${tenantLevel}`)) {
      const tenantQuery = `t${tenantLevel}`;
      const tenantId = queryParams.get(tenantQuery);
      tenantIdList.push({ level: tenantLevel, id: tenantId || '' });
      tenantLevel++;
    }
    return tenantIdList;
  }
}
