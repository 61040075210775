import bindAllMethods from '../../utils/bindAllMethods';
import {
  ExchangeTenantTokenDTOType,
  ExchangeTenantTokenResponseType,
  IExchangeClient
} from '../../clients/shell/exchange';
import IRefreshClient, {
  ShellSessionRefreshResponseType
} from '../../clients/shell/refresh/IRefreshClient';
import { TokenType, UserContextEnum } from '../../interface/types';
import { clearUserData } from '../../utils/clearUserData';
import { getCookie } from '../../utils/cookies';
import { IAuthTokenService } from '../authTokenService';
import AuthContextEnum from '../authTokenService/AuthContextEnum';
import getParentContext from '../authTokenService/utils/getParentContext';
import ISupportSessionService from '../supportSession/ISupportSessionService';
import { TenantStrategyEnum } from '../tenantHandler/strategy/strategy';
import ISessionService, {
  RefreshParameterType,
  SessionServiceDependenciesType
} from './ISessionService';
import SessionObserver, { SessionEvents } from './SessionObserver';
import { ILogoutService } from './logoutService';
import { GenerateAuthenticationUrlParams, ILoginService } from './loginService';
import {
  GetProviderListParam,
  GetProviderListResponseType
} from '../../clients/shell/provider';
import { IdTokenRepository } from './IdTokenRepository';

export type SessionServiceParameters = {
  refreshClient: IRefreshClient;
  exchangeClient: IExchangeClient;
  supportSessionService: ISupportSessionService;
  authTokenService: IAuthTokenService;
  idTokenRepository: IdTokenRepository;
};

export default class SessionServiceWeb implements ISessionService {
  private _refreshClient: IRefreshClient;
  private _exchangeClient: IExchangeClient;
  private _supportSessionService: ISupportSessionService;
  private _refreshPromise: Promise<void>;
  private _authTokenService: IAuthTokenService;
  private _loginService: ILoginService;
  private _logoutService: ILogoutService;
  private _idTokenRepository: IdTokenRepository;

  constructor({
    refreshClient,
    exchangeClient,
    supportSessionService,
    authTokenService,
    idTokenRepository
  }: SessionServiceParameters) {
    this._refreshClient = refreshClient;
    this._exchangeClient = exchangeClient;
    this._supportSessionService = supportSessionService;
    this._authTokenService = authTokenService;
    this._idTokenRepository = idTokenRepository;
    bindAllMethods(this);
  }
  public setDependencies({
    loginService,
    logoutService
  }: SessionServiceDependenciesType): void {
    this._loginService = loginService;
    this._logoutService = logoutService;
  }

  public async init(): Promise<void> {
    await this._validateSupportSession();
  }

  // TODO: Refactor this to not clearUserData if it's not loggedIn and create a mechanism to deal with that
  // TODO: Refactor to deal with the cookies
  public isLoggedIn(): boolean {
    //TODO: move cookie name to a constant
    const maxAge0OnLoginCookie = getCookie(
      'jarvisreactshelllogin__setMaxAge0OnLogin'
    );
    //TODO: move cookie name to a constant
    const onboardingFinishedCookie = getCookie('shellOnboardingFinished');

    const isSessionActive = !!this._getSessionId();

    const isLoggedIn = maxAge0OnLoginCookie !== 'true' && isSessionActive;

    const userOnboardingFinished = onboardingFinishedCookie === 'true';

    if (!isLoggedIn) clearUserData();
    return isLoggedIn && userOnboardingFinished;
  }

  public async refresh(
    refreshParameterType?: RefreshParameterType
  ): Promise<void> {
    if (this._refreshPromise) return this._refreshPromise;

    const refreshAction = async () => {
      if (this._supportSessionService.isSupportSession()) {
        return;
      }

      if (
        this._supportSessionService.isSupportSessionStartedBySessionAPIAndInitialStatusNotEnabled()
      ) {
        clearUserData();
        //TODO: move this kind of behavior to a callback that will be injected by a class at the constructor
        window.location.href = '/';
      }

      const sessionId = this._getSessionId();
      const authContext = this._authTokenService.getCurrentContext();

      try {
        const shellSessionRefreshResponse: ShellSessionRefreshResponseType =
          await this._refreshClient.refresh({
            shellSessionId: sessionId,
            authContext,
            tenantsIdMap: refreshParameterType.tenantsIdMap
          });

        this._saveRefreshData(shellSessionRefreshResponse);
      } catch (error) {
        // Check if it's an HTTP error to prevent request aborts
        if (error?.response?.data) {
          throw error;
        }
      }
    };

    this._refreshPromise = refreshAction().finally(() => {
      this._refreshPromise = undefined;
    });

    return this._refreshPromise;
  }

  private _getSessionId(): string {
    return getCookie(TokenType.shellSessionId, false);
  }

  public getIdToken(): string {
    return (
      this._idTokenRepository?.findOne()?.token ||
      getCookie(TokenType.stratusIdToken) ||
      getCookie(TokenType.deprecatedstratusIdToken)
    );
  }

  private _saveRefreshData(
    shellSessionRefreshResponse: ShellSessionRefreshResponseType
  ): void {
    if (shellSessionRefreshResponse?.shellTenantlessData?.token) {
      this._authTokenService.setToken(
        shellSessionRefreshResponse.shellTenantlessData.token,
        AuthContextEnum.tenantless
      );
    }
    if (shellSessionRefreshResponse?.shellTenantData?.token) {
      this._authTokenService.setToken(
        shellSessionRefreshResponse.shellTenantData.token,
        AuthContextEnum.tenant
      );
    }
    if (shellSessionRefreshResponse?.shellSubtenantData?.token) {
      this._authTokenService.setToken(
        shellSessionRefreshResponse.shellSubtenantData.token,
        AuthContextEnum.subtenant
      );
    }
    if (shellSessionRefreshResponse?.shellStratusIdToken) {
      this._idTokenRepository.save({
        token: shellSessionRefreshResponse?.shellStratusIdToken
      });
    }
  }

  public async exchangeTenantToken(
    tenantId: string,
    authContext: AuthContextEnum,
    tenantStrategy: TenantStrategyEnum
  ): Promise<void> {
    let exchangeTenantTokenResponse: ExchangeTenantTokenResponseType;
    try {
      const parentContext = getParentContext(authContext);

      const exchangeDTO: ExchangeTenantTokenDTOType = {
        shellSessionId: this._getSessionId(),
        accessToken: this._getAccessToken(parentContext),
        tenantId,
        tenantStrategy
      };
      exchangeTenantTokenResponse = await this._exchangeClient.exchangeToken(
        exchangeDTO
      );
    } catch (err) {
      console.error(err);
    }
    await this._saveExchangeData(exchangeTenantTokenResponse);
    await SessionObserver.notify(
      SessionEvents.EXCHANGE_TENANT_TOKEN,
      this._authTokenService.getToken()?.token
    );
  }

  public async clearSession(): Promise<void> {
    clearUserData();
    this._logoutService.logout();
  }

  public async logout(): Promise<void> {
    return this._logoutService.logout();
  }
  public async getProviderList(
    options?: GetProviderListParam
  ): Promise<GetProviderListResponseType> {
    return this._loginService.getProviderList(options);
  }
  public async generateAuthenticationUrl(
    options: GenerateAuthenticationUrlParams
  ): Promise<string> {
    return this._loginService.generateAuthenticationUrl(options);
  }

  private _getAccessToken(authContext: AuthContextEnum): string {
    const { token: accessToken } = this._authTokenService.getToken(authContext);
    return accessToken;
  }

  private async _saveExchangeData(
    exchangeTenantTokenResponse: ExchangeTenantTokenResponseType
  ): Promise<void> {
    let authContext: AuthContextEnum;
    if (exchangeTenantTokenResponse.tokenType === UserContextEnum.customer) {
      authContext = AuthContextEnum.subtenant;
    } else {
      authContext = AuthContextEnum.tenant;
    }
    this._authTokenService.setToken(
      exchangeTenantTokenResponse.accessToken,
      authContext
    );
  }

  private async _validateSupportSession(): Promise<void> {
    if (
      this._supportSessionService.isSupportSessionStartedBySessionAPIAndInitialStatusNotEnabled()
    ) {
      await this.clearSession();
    }
  }
}
