import bindAllMethods from '../../utils/bindAllMethods';
import { ISessionService } from '../session';
import IUserOnboardingService from './IUserOnboardingService';
import OnecloudOauthClient from '../../clients/oneCloud/oauth/OnecloudOauthClient';
import OnecloudRegistryRelationshipClient from '../../clients/oneCloud/registryRelationship/OnecloudRegistryRelationshipClient';
import { UserOnboardingJoinInvitedOrganizationParams } from './types';

export type OnecloudUserOnboardingServiceParams = {
  sessionService: ISessionService;
  onecloudOauthClient: OnecloudOauthClient;
  onecloudRegistryRelationshipClient: OnecloudRegistryRelationshipClient;
};

export default class OnecloudUserOnboardingService
  implements IUserOnboardingService
{
  private _DEFAULT_ONBOARDING_ORG_TYPE = 'Personal';
  private _sessionService: ISessionService;
  private _onecloudOauthClient: OnecloudOauthClient;
  private _onecloudRegistryRelationshipClient: OnecloudRegistryRelationshipClient;

  constructor({
    sessionService,
    onecloudOauthClient,
    onecloudRegistryRelationshipClient
  }: OnecloudUserOnboardingServiceParams) {
    this._sessionService = sessionService;
    this._onecloudOauthClient = onecloudOauthClient;
    this._onecloudRegistryRelationshipClient =
      onecloudRegistryRelationshipClient;
    bindAllMethods(this);
  }

  public async shouldRenderOnboardingScreen(): Promise<boolean> {
    return false;
  }

  public async isUserOnboarded(): Promise<boolean> {
    const personDomains =
      await this._onecloudRegistryRelationshipClient.getPersonDomains();

    return !!(personDomains?.content?.length > 0);
  }

  public async onboardUser(): Promise<void> {
    const idToken = this._sessionService.getIdToken();
    await this._onecloudOauthClient.createStratusAccount({
      accountId: null,
      accountName: null,
      language: 'en',
      countrySet: ['US'],
      idToken,
      type: this._DEFAULT_ONBOARDING_ORG_TYPE
    });
  }

  public async joinInvitedOrganization(
    options?: UserOnboardingJoinInvitedOrganizationParams
  ): Promise<void> {
    const idToken = this._sessionService.getIdToken();
    await this._onecloudOauthClient.createStratusAccount({
      language: 'en',
      countrySet: ['US'],
      idToken,
      accountId: options?.tenantId
    });
  }
}
